import avatar from '../images/avatar.png';
const rangeDropdown = [
  {
    id: 1,
    value: 'ALL',
    displayValue: 'All',
  },
  {
    id: 2,
    value: 'THIS_WEEK',
    displayValue: 'This Week',
  },
  {
    id: 3,
    value: 'LAST_WEEK',
    displayValue: 'Last Week',
  },
  {
    id: 4,
    value: 'THIS_MONTH',
    displayValue: 'This Month',
  },
  {
    id: 5,
    value: 'LAST_MONTH',
    displayValue: 'Last Month',
  },
  {
    id: 6,
    value: 'THIS_YEAR',
    displayValue: 'This Year',
  },
  {
    id: 7,
    value: 'LAST_YEAR',
    displayValue: 'Last Year',
  },
];
const statusDropdown = [
  {
    id: 1,
    value: 'ALL',
    displayValue: 'All',
  },
  {
    id: 2,
    value: 'WAITING',
    displayValue: 'Waiting',
  },
  {
    id: 3,
    value: 'PICKUP_COMPLETE',
    displayValue: 'Pickup Complete',
  },
  {
    id: 4,
    value: 'DELIVERED',
    displayValue: 'Delivered',
  },
  {
    id: 5,
    value: 'CANCELLED',
    displayValue: 'Cancelled',
  },
];

const currentOrders = [
  {
    id: 0,
    avatar: avatar,
    orderid: '123456',
    status: 'Waiting',
    name: 'Danielle',
    service: 'Wash and Fold',
    pickupDate: 'April 21, Tuesday 10-11 am',
    deliveryDate: 'April 22, Wednesday 10-11 am',
    estimatedTotal: '20.00',
    tip: '5.00',
    tenantRating: 4,
    clientRating: 5,
  },
  {
    id: 2,
    avatar: avatar,
    orderid: '23423',
    status: 'Waiting',
    name: 'Danielle',
    service: 'Wash and Fold',
    pickupDate: 'April 21, Tuesday 10-11 am',
    deliveryDate: 'April 22, Wednesday 10-11 am',
    estimatedTotal: '20.00',
    tip: null,
    tenantRating: 4,
    clientRating: 4,
  },
  {
    id: 3,
    avatar: avatar,
    orderid: '123456',
    status: 'Pickup Complete',
    name: 'Jerry',
    service: 'Blanket Dry Clean',
    pickupDate: 'April 21, Tuesday 10-11 am',
    deliveryDate: 'April 22, Wednesday 10-11 am',
    estimatedTotal: '20.00',
    tip: null,
    tenantRating: 3,
    clientRating: 2,
  },
  {
    id: 4,
    avatar: avatar,
    orderid: '423433',
    status: 'Delivered',
    name: 'Shane',
    service: 'Ironing',
    pickupDate: 'April 21, Tuesday 10-11 am',
    deliveryDate: 'April 22, Wednesday 10-11 am',
    estimatedTotal: '20.00',
    tip: null,
    tenantRating: 4,
    clientRating: 5,
  },
  {
    id: 5,
    avatar: avatar,
    orderid: '234233',
    status: 'Delivered',
    name: 'Shane',
    service: 'Ironing',
    pickupDate: 'April 21, Tuesday 10-11 am',
    deliveryDate: 'April 22, Wednesday 10-11 am',
    estimatedTotal: '20.00',
    tip: '5.00',
    tenantRating: null,
    clientRating: null,
  },
  {
    id: 6,
    avatar: avatar,
    orderid: '234233',
    status: 'Cancelled',
    name: 'Shane',
    service: 'Ironing',
    pickupDate: 'April 21, Tuesday 10-11 am',
    deliveryDate: 'April 22, Wednesday 10-11 am',
    estimatedTotal: '20.00',
    tip: null,
    tenantRating: null,
    clientRating: null,
  },
];

const tipAmount = [
  {
    id: 1,
    percent: '10%',
    value: '2.50',
  },
  {
    id: 2,
    percent: '15%',
    value: '3.05',
  },
  {
    id: 3,
    percent: '20%',
    value: '3.45',
  },
  {
    id: 4,
    percent: null,
    value: null,
  },
];
const ratingData = {
  provider: 'Jerry',
  tenant: 'Laundry Care',
  providerRating: 2,
  tenantRating: 4,
};

export { rangeDropdown, statusDropdown, currentOrders, tipAmount, ratingData };
