import React from 'react';

export default function DeleteMinus({ onClick }) {
  return (
    <div onClick={onClick}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='1.5'
        stroke='currentColor'
        className='h-6 w-6 cursor-pointer text-red-500'
      >
        <path strokeLinecap='round' strokeLinejoin='round' d='M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z' />
      </svg>
    </div>
  );
}
