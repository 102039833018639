import React from 'react';

export default function AddIcon({ onClick }) {
  return (
    <div className='' onClick={onClick}>
      <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <g fill='#001C5C' fillRule='nonzero'>
          <path d='M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16z' />
          <path d='M15 11h-2V9a1 1 0 0 0-2 0v2H9a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z' />
        </g>
      </svg>
    </div>
  );
}
