import axios from 'axios';
import {
  GET_ALL_SERVICE_ADDRESSES,
  GET_ALL_SERVICE_ADDRESSES_LOADING,
  SELECTED_SERVICE_ADDRESS,
  ADDRESS_PREFERENCES,
} from '../ActionsTypes';
import { getRequest, postRequest, putRequest } from '../../utils/axios';
import {
  setAllServiceAddressesAction,
  setAllServiceAddressesLoadingAction,
  setSelectedServiceAddressAction,
} from '../Actions/ServiceAddressesActions';
import toast from 'react-hot-toast';
import { setOrderDataAction } from '../Actions/CreateOrderActions';
const initialState = {
  allServiceAddresses: [],
  allServiceAddressesLoading: false,
  selectedAddress: null,
};
export const ServiceAddressesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_SERVICE_ADDRESSES:
      return { ...state, allServiceAddresses: payload };
    case GET_ALL_SERVICE_ADDRESSES_LOADING:
      return { ...state, allServiceAddressesLoading: payload };
    case SELECTED_SERVICE_ADDRESS:
      return { ...state, selectedAddress: payload };
    case ADDRESS_PREFERENCES:
      return { ...state, preferences: payload };
    default:
      return state;
  }
};

export const getAllServiceAddressesApi = () => async (dispatch) => {
  try {
    dispatch(setAllServiceAddressesLoadingAction(true));
    const list = await getRequest('/accounts/client/service_addresses');
    dispatch(setAllServiceAddressesAction(list?.data));
    dispatch(setSelectedServiceAddressAction(list?.data[0]));
    // dispatch(setOrderDataAction({ orderServiceAddress: null }));
    dispatch(setAllServiceAddressesLoadingAction(false));
    return list;
  } catch (error) {
    dispatch(setAllServiceAddressesLoadingAction(false));
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const addNewServiceAddressesApi = (data) => async (dispatch) => {
  try {
    const item = await postRequest('/accounts/client/service_addresses', data);
    return item;
  } catch (error) {
    return error?.response;
  }
};
export const updateServiceAddressesApi = (data, id) => async (dispatch) => {
  try {
    const item = await putRequest(`/accounts/client/service_addresses/${id}`, data);
    return item;
  } catch (error) {
    return error?.response?.data;
  }
};
