import React from 'react';
import OrgNotifications from './OrgNotifications';
import { useSelector } from 'react-redux';
import IndividualNotifications from './IndividualNotifications';

export default function Notifications() {
  const { profileData } = useSelector((state) => state.profile);
  return (
    <div>
      {profileData?.account_type == 'ORGANIZATION' || profileData?.account_type == 'ORGANIZATION_MEMBER' ? (
        <OrgNotifications accountType={profileData?.account_type} />
      ) : (
        <IndividualNotifications accountType={profileData?.account_type} />
      )}
    </div>
  );
}
