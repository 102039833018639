import React, { useCallback, useEffect, useState } from 'react';
import ModalSlide from '../../components/ModalSlide';
import PaymentDetails from './PaymentDetails';
import DefaultPayment from './DefaultPayment';
import ClientButton from '../../components/ClientButton';
import PaymentSummary from './PaymentSummary';
import { payInvoiceApi } from '../../redux/Reducers/commonApis';
import Loader from '../../components/Loader';
import toast from 'react-hot-toast';
import { getInvoiceDetailsbyID } from '../../redux/Reducers/PaymentsReducer';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

export default function PayInvoice({ open, setOpen, selectedInvoice, invoiceDetails }) {
  const navigate = useNavigate();
  const [paymentSelected, setPaymentSelected] = useState(false);
  const [paymentSelectedType, setPaymentSelectedType] = useState('existing_card');
  const [paymentSummary, setPaymentSummary] = useState(false);
  const [finalAmountCharged, setFinalAmountCharged] = useState(0);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentSummaryData, setPaymentSummaryData] = useState(null);
  const closeModalFn = useCallback((e, val) => {
    setOpen(val);
  }, []);
  const onPaymentClick = (e, val) => {
    e.stopPropagation();
    setPaymentSelected(val);
  };
  const onPaymentTypeChangeFn = (val) => {
    setPaymentSelectedType(val);
    // if (val === 'existing_card') {
    //   setPaymentSelected(true);
    // } else {
    //   setPaymentSelected(false);
    // }
  };
  const payInvoiceFn = () => {
    setLoading(true);
    const payload = {
      amount: finalAmountCharged,
    };
    const response = payInvoiceApi(invoice?.transaction_id, payload);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setLoading(false);
        setPaymentSummary(true);
        setPaymentSummaryData(res && res?.data);
        toast.success(' Invoice paid successfully');
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const getInvoiceDetails = (id) => {
    setInvoiceLoading(true);
    const response = getInvoiceDetailsbyID(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setInvoice(res?.data);
        setInvoiceLoading(false);
      } else {
        setInvoiceLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  useEffect(() => {
    isEmpty(invoice) && open && getInvoiceDetails(selectedInvoice);
  }, [open]);
  useEffect(() => {
    setInvoice(invoiceDetails);
  }, [invoiceDetails]);
  return (
    <div>
      <ModalSlide
        id='basic-modal'
        modalOpen={open}
        setModalOpen={(e) => closeModalFn(e, false)}
        title=''
        showTitle={true}
      >
        {invoiceLoading ? (
          <Loader classes='h-96' text='loading invoice...' />
        ) : (
          <div className='px-6'>
            {!paymentSummary && (
              <div>
                <div className='flex'>
                  <h3 className='go-textblackbold-24  mr-3 '>Pay Invoice</h3>
                </div>
                <div className='flex'>
                  <p className='go-textblack-16 py-1'>Invoice {invoice?.transaction_id}</p>
                </div>
              </div>
            )}
            {loading ? (
              <Loader classes='h-96' text='processing you payment...' />
            ) : !paymentSelected ? (
              <DefaultPayment invoice={invoice} onPaymentTypeChangeFn={(val) => onPaymentTypeChangeFn(val)} />
            ) : paymentSummary ? (
              <PaymentSummary
                invoice={invoice}
                paymentSummaryData={paymentSummaryData}
                finalAmountCharged={finalAmountCharged}
              />
            ) : (
              <PaymentDetails invoice={invoice} setFinalAmountCharged={setFinalAmountCharged} />
            )}
          </div>
        )}

        <div className='bg-client-statuschipbg flex h-20'>
          <div className='flex w-full items-center justify-between px-6'>
            {!paymentSelected && !paymentSummary && (
              <ClientButton
                variant='secondary'
                className='go-default-border'
                text='Go back'
                onClick={(e) => {
                  setOpen(false);
                }}
              />
            )}
            {!paymentSelected && !paymentSummary && (
              <ClientButton
                variant='primary'
                className='go-default-border'
                text='Continue'
                disabled={paymentSelectedType != 'existing_card' || invoice?.billing_address?.billing_details == null}
                onClick={(e) => onPaymentClick(e, true)}
              />
            )}
            {paymentSelected && !paymentSummary && (
              <ClientButton
                variant='secondary'
                className='go-default-border'
                text='Go back'
                onClick={(e) => onPaymentClick(e, false)}
              />
            )}
            {paymentSelected && !paymentSummary && (
              <ClientButton
                variant='primary'
                className='go-default-border'
                text='Charge Card'
                disabled={finalAmountCharged === 0}
                onClick={() => payInvoiceFn()}
              />
            )}
            {paymentSummary && (
              <ClientButton
                variant='secondary'
                className='go-default-border'
                text='Done'
                onClick={() => {
                  setOpen(false);
                  window.location.reload();
                }}
              />
            )}
            {paymentSummary && (
              <ClientButton
                variant='primary'
                className='go-default-border'
                text='View Invoice'
                onClick={() => navigate(`/invoice/${invoice?.transaction_id}`)}
              />
            )}
          </div>
        </div>
      </ModalSlide>
    </div>
  );
}
