import React from 'react';

export default function CheckmarkIcon({ onClick, classes }) {
  return (
    <div className={classes} onClick={onClick}>
      <svg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
        <g fill='#28C2B3' fillRule='nonzero'>
          <path d='M33.333 19.717c-.92 0-1.666.746-1.666 1.666v9.284a1 1 0 0 1-1 1H9.333a1 1 0 0 1-1-1V9.333a1 1 0 0 1 1-1h15.95a1.667 1.667 0 1 0 0-3.333H9.333A4.35 4.35 0 0 0 5 9.333v21.334A4.35 4.35 0 0 0 9.333 35h21.334A4.35 4.35 0 0 0 35 30.667v-9.284c0-.92-.746-1.666-1.667-1.666z' />
          <path d='M17.867 18.333a1.667 1.667 0 0 0-2.4 2.3l3.7 3.884a1.667 1.667 0 0 0 2.4.016l11.3-11.666a1.674 1.674 0 0 0-2.4-2.334L20.383 20.967l-2.516-2.634z' />
        </g>
      </svg>
    </div>
  );
}
