import React from 'react';
import ClientButton from '../../../../components/ClientButton';
import subscription from '../../../../images/subscription.png';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import StatusChip from '../../../../components/StatusChip';
import { addDaysAndGetWeekday, dateFn, utcTimeToLocalTime } from '../../../../utils/dateTimeFormatter';
import no_picture from '../../../../images/no-profile-picture.png';
import { isEmpty, isNumber } from 'lodash';
import { amountCheckFn, textCheckFn } from '../../../../utils/commonUtil';
import moment from 'moment';
import { capitalizeFirstLetter, generateInitials, ordinal_suffix_of } from '../../../../components/Utils';
export default function SubscriptionsCollapse({ list, deactivateModalFn }) {
  const onModalOpen = (e, id) => {
    e.stopPropagation();
    deactivateModalFn(true, id);
  };
  const textChangeFn = (unit, obj, type) => {
    if (unit === 'WEEK') {
      if (type === 'pickup') {
        return `${capitalizeFirstLetter(obj?.pickup_week_day)}s, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time
        )}`;
      } else {
        return `${capitalizeFirstLetter(obj?.dropoff_week_day)}s, ${genarateTime(
          moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
          obj?.dropoff_start_time,
          obj?.dropoff_end_time
        )}`;
      }
    }
    if (unit === 'BIWEEKLY') {
      if (type === 'pickup') {
        return `Every other ${capitalizeFirstLetter(obj?.pickup_week_day)}, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time
        )}`;
      } else {
        return `Every other ${capitalizeFirstLetter(obj?.dropoff_week_day)}, ${genarateTime(
          moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
          obj?.dropoff_start_time,
          obj?.dropoff_end_time
        )}`;
      }
    }
    if (unit === 'MONTH') {
      if (type === 'pickup') {
        return `${ordinal_suffix_of(moment(obj?.first_pickup_date).date())} of each month, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time
        )}`;
      } else {
        return obj?.days_after === 1
          ? `1 day later, ${genarateTime(
              moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
              obj?.dropoff_start_time,
              obj?.dropoff_end_time
            )}`
          : `${obj?.days_after} days later, ${genarateTime(
              moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
              obj?.dropoff_start_time,
              obj?.dropoff_end_time
            )}`;
      }
    }
  };

  const subscriptionType = (type) => {
    if (type === 'WEEK') {
      return 'Weekly';
    } else if (type === 'MONTH') {
      return 'Monthly';
    } else if (type === 'BIWEEKLY') {
      return 'Bi-Weekly';
    } else {
      return '-';
    }
  };

  const genarateTime = (date, time1, time2) => {
    date = date ? date : moment().format('YYYY-MM-DD');
    return `${utcTimeToLocalTime(`${date.split('T')[0]}T${time1}`)} - ${utcTimeToLocalTime(
      `${date.split('T')[0]}T${time2}`
    )}`;
  };
  return (
    <div>
      <header className='flex items-center border-b border-gray-100 p-6'>
        <img className='mx-1' src={subscription} width='40' height='40' />
        <h5 className='go-text-semibold-18 my-1 flex-1'>Subscription</h5>
      </header>
      <div className='flex-row '>
        {list?.map((item, index) => {
          return (
            <Disclosure key={index} defaultOpen={index === 0 ? true : false}>
              {({ open }) => (
                <>
                  <Disclosure.Button className='flex w-full justify-between  py-4 px-6 text-left text-sm font-medium '>
                    <div className='go-text-semibold-16 flex w-full items-center justify-between'>
                      <div className='flex align-middle'>
                        <div className='mr-3 rounded-sm bg-gray-100 py-1 px-1 '>
                          <ChevronDownIcon
                            className={`${open ? 'rotate-180 transform' : ''} go-heading-16 m-0.5 h-5 w-5`}
                          />
                        </div>
                        <span className='py-1'>Subscription #{item?.id}</span>
                      </div>
                      <div className=''>
                        <StatusChip status={item?.status} size='sm' />
                      </div>
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel className='border-b border-gray-100 px-6  pb-5'>
                    <div className='flex flex-row space-x-8 '>
                      <div className='w-3/5'>
                        {item?.subscription_entries?.length > 1 ? (
                          <div>
                            <div className='rounded-sm border border-slate-200 '>
                              <header className='border-b border-slate-100 px-5 py-4'>
                                <h6 className='font-semibold text-slate-800'>Schedules</h6>
                              </header>
                              <div className='p-3'>
                                <div className='overflow-x-auto'>
                                  <table className='w-full table-auto'>
                                    <thead className='rounded-sm bg-slate-50 text-xs uppercase text-slate-400'>
                                      <tr>
                                        <th className='p-2'>
                                          <div className='text-left font-semibold'>Frequency</div>
                                        </th>
                                        <th className='p-2'>
                                          <div className='text-center font-semibold'>First Pickup</div>
                                        </th>
                                        <th className='p-2'>
                                          <div className='text-center font-semibold'>Pickup</div>
                                        </th>
                                        <th className='p-2'>
                                          <div className='text-center font-semibold'>Delivery</div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className='divide-y divide-slate-100 text-sm font-medium'>
                                      {item?.subscription_entries?.map((entry, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className='p-2'>
                                              {subscriptionType(item?.subscription_repeat_frequency_unit)}
                                            </td>
                                            <td className='p-2'>
                                              {item?.first_pickup_date
                                                ? `${dateFn('type2', entry?.first_pickup_date)}`
                                                : '-'}
                                            </td>
                                            <td className='p-2'>
                                              <div className=' text-center'>
                                                {textChangeFn(
                                                  item?.subscription_repeat_frequency_unit,
                                                  entry,
                                                  'pickup'
                                                )}
                                              </div>
                                            </td>
                                            <td className='p-2'>
                                              <div className=' text-center'>
                                                {textChangeFn(
                                                  item?.subscription_repeat_frequency_unit,
                                                  entry,
                                                  'dropoff'
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className=''>
                            <div className='mb-4 flex-row'>
                              <div className='go-label-16 text-client-link'>First Pickup</div>
                              <div className='go-textblacknormal-16 font-medium'>
                                {item?.subscription_entries[0]?.first_pickup_date
                                  ? `${dateFn('type2', item?.subscription_entries[0]?.first_pickup_date)}`
                                  : '-'}
                              </div>
                            </div>
                            <div className='mb-4 flex-row'>
                              <div className='go-label-16'>Frequency</div>
                              <div className='go-textblacknormal-16 font-medium'>
                                {subscriptionType(item?.subscription_repeat_frequency_unit)}
                              </div>
                            </div>
                            <div className='mb-4 flex-row'>
                              <div className='go-label-16'>Pickup</div>
                              <div className='go-textblacknormal-16 font-medium'>
                                {textChangeFn(
                                  item?.subscription_repeat_frequency_unit,
                                  item?.subscription_entries[0],
                                  'pickup'
                                )}
                              </div>
                            </div>
                            <div className='mb-4 flex-row'>
                              <div className='go-label-16'>Delivery</div>
                              <div className='go-textblacknormal-16 font-medium'>
                                {textChangeFn(
                                  item?.subscription_repeat_frequency_unit,
                                  item?.subscription_entries[0],
                                  'dropoff'
                                )}
                              </div>
                            </div>
                            <div className='mb-4 flex-row'>
                              <div className='go-label-16'>Assigned Provider</div>
                              <div className='go-textblacknormal-16 font-medium'>
                                {item?.worker?.id == null ? (
                                  <img
                                    className={' mb-4 h-[80px] w-[80px] rounded-full bg-[#f3f8ff]'}
                                    src={no_picture}
                                    alt={'Any Provider'}
                                  />
                                ) : item?.worker?.profile_image ? (
                                  <img
                                    className={' h-[80px] w-[80px] rounded-full '}
                                    src={
                                      item?.worker?.profile_image
                                        ? item?.worker?.profile_image?.absolute_url
                                        : no_picture
                                    }
                                    alt={item?.worker?.first_name}
                                  />
                                ) : (
                                  <div className='mb-4 h-[80px] w-[80px] rounded-full bg-[#FD56AD] p-2'>
                                    <span className='flex h-full w-full items-center justify-center text-3xl font-bold text-white'>
                                      {generateInitials(item?.worker?.first_name + ' ' + item?.worker?.last_name)}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='w-2/5'>
                        <div className='mb-4 flex-row'>
                          <div className='go-label-16'>Services</div>
                          {!isEmpty(item?.items) ? (
                            item?.items?.map((service, index) => {
                              return (
                                <div key={index} className='go-textblackmedium-16 my-1'>
                                  {`${service?.name} (${service?.quantity})`}
                                </div>
                              );
                            })
                          ) : (
                            <div className='go-textblackmedium-16 my-1'>No items</div>
                          )}
                        </div>
                        <div className='mb-4 flex-row'>
                          <section className=' pt-3'>
                            <ul>
                              <li className=' flex items-center justify-between py-1'>
                                <div className='go-label-16'>Subtotal:</div>
                                <div className='go-text-16'>
                                  <span>{amountCheckFn(item?.subtotal)}</span>
                                </div>
                              </li>
                              <li className=' flex items-center justify-between py-1'>
                                <div className='go-label-16'>Estimated Sales Tax:</div>
                                <div className='go-text-16'>
                                  <span>{amountCheckFn(item?.estimated_tax_cost)}</span>
                                </div>
                              </li>
                              <li className=' flex items-center justify-between py-1'>
                                <div className='go-label-16'>Discount:</div>
                                <div className='go-text-16'>
                                  <span>-{amountCheckFn(item?.estimated_discount_amount)}</span>
                                </div>
                              </li>

                              <li className=' flex items-center justify-between py-1'>
                                <div className='go-label-16'>Service Fee:</div>
                                <div className='go-text-16'>
                                  <span>{amountCheckFn(item?.service_fee)}</span>
                                </div>
                              </li>
                              {isNumber(item?.total_priority_increase) && item?.total_priority_increase > 0 && (
                                <li className=' flex items-center justify-between py-1'>
                                  <div className='go-text-16 text-client-link'>Priority:</div>
                                  <div className='go-text-16 text-client-link'>
                                    <span>{amountCheckFn(item?.total_priority_increase)}</span>
                                  </div>
                                </li>
                              )}

                              <li className=' flex items-center justify-between py-1'>
                                <div className='go-label-16'>Estimated Total Cost:</div>
                                <div className='go-text-16'>
                                  <span>{amountCheckFn(item?.final_total)}</span>
                                </div>
                              </li>
                              {item?.order_minimum_charge && item?.order_minimum_charge > 0 && (
                                <li className=' flex py-1'>
                                  <p className='go-text-14 text-client-link'>
                                    We have a ${item?.order_minimum_charge} minimum charge policy. If your subtotal is
                                    less than ${item?.order_minimum_charge} we will increase the subtotal to this
                                    amount.
                                  </p>
                                </li>
                              )}
                            </ul>
                          </section>
                        </div>
                      </div>
                      {/* <div className='w-1/3'>
                        <div className='px-10 text-right'>
                          <span className='text-textblacknormal-16 text-[#ea6278]'>Cancel Order</span>
                        </div>
                      </div> */}
                    </div>
                    <div className='flex justify-end'>
                      <span
                        className='go-textnormal-16 my-2 ml-5 cursor-pointer text-[#ea6278]'
                        onClick={(e) => onModalOpen(e, item?.id)}
                      >
                        Deactivate
                      </span>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          );
        })}
      </div>
    </div>
  );
}
