//convert string to lowercase and replace space with dash
const toLowerCaseAndReplaceDash = (str) => {
  return str.toLowerCase().replace(/\s/g, '-');
};

const ordinal_suffix_of = (i) => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
const roundedRatingValue = (ratingValue) => {
  if (ratingValue == 0) {
    return 0;
  } else if (ratingValue > 0 && ratingValue < 1) {
    return 0.5;
  } else if (ratingValue > 1 && ratingValue < 2) {
    return 1.5;
  } else if (ratingValue > 2 && ratingValue < 3) {
    return 2.5;
  } else if (ratingValue > 3 && ratingValue < 4) {
    return 3.5;
  } else if (ratingValue > 4 && ratingValue < 5) {
    return 4.5;
  } else {
    return ratingValue;
  }
};
const generateInitials = (name) => {
  const names = name?.split(' ');
  if (names?.length === 1) {
    return names[0]?.slice(0, 2)?.toUpperCase();
  } else {
    return `${names[0][0]}${names[1][0]}`.toUpperCase();
  }
};
export { toLowerCaseAndReplaceDash, ordinal_suffix_of, capitalizeFirstLetter, roundedRatingValue, generateInitials };
