import { Fragment, useCallback, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { titleCaseFormatter } from '../utils/stringFormatters';

export default function DropdownSingle({ dropdownValues, icon, onchange, prefix = '', defaultValue }) {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(defaultValue ? defaultValue : dropdownValues[0]);

  const onDropdownChange = useCallback(
    (option) => {
      setSelected(option);
      onchange(option?.value);
    },
    [selected]
  );

  useEffect(() => {
    setOptions(dropdownValues);
  }, [dropdownValues]);

  useEffect(() => {
    defaultValue && setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <div className=''>
      <Listbox value={selected} onChange={(option) => onDropdownChange(option)}>
        <div className=' relative mt-1'>
          <Listbox.Button className='go-textblacknormal-16 relative w-full cursor-default rounded border border-gray-200 bg-white py-2 pl-3 pr-12 text-left'>
            <span className='block truncate'>
              {prefix}
              {titleCaseFormatter(selected?.value)}
            </span>
            <span className='bg-client-iconbg pointer-events-none absolute inset-y-0 right-0 m-0.5 flex items-center rounded p-2'>
              <span className='text-client-link h-5 w-5' aria-hidden='true'>
                {icon ? icon : <ChevronUpDownIcon />}
              </span>
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className='z-60 text-black-16 go-text-14 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {options?.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) =>
                    ` relative cursor-default select-none border-b border-gray-200 py-2 pl-10 pr-4 last:border-0 ${
                      active ? 'bg-blue-50 text-blue-600' : 'text-gray-900'
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {item?.displayValue ? item?.displayValue : item?.value}
                      </span>
                      {selected ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600'>
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
