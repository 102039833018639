import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderSummary } from '../../../redux/Reducers/CreateOrderReducer';
import { isEmpty } from 'lodash';
import { toast } from 'react-hot-toast';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { amountCheckFn } from '../../../utils/commonUtil';
import fastforward from '../../../../src/icons/fastforward.png';
export default function ServicesEstimate({ showServices = true, lastRender = false }) {
  const dispatch = useDispatch();
  const { selectedServices, orderPromoCode, final_tip, orderServiceAddress, orderIsRush, orderProvider } = useSelector(
    (state) => state.createNewOrder
  );
  const [calculationLoading, setCalculationLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [summaryMsg, setSummaryMsg] = useState('');
  useEffect(() => {
    setCalculationLoading(true);
    const payload = {
      items: selectedServices?.map((service) => {
        return { service_id: service?.service_id, quantity: service?.quantity };
      }),
      ...(orderPromoCode !== null && { promo_code: orderPromoCode }),
      final_tip: final_tip === null ? undefined : final_tip,
      address_id: orderServiceAddress?.id,
      is_rush: orderIsRush?.is_rush,
      ...(orderProvider?.id && { worker_id: orderProvider?.id }),
    };
    if (!isEmpty(payload?.items)) {
      setSummaryMsg('loading summary...');
      const response = getOrderSummary(payload);
      response.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setCalculationLoading(false);
          setSummary(res?.data || {});
          dispatch(
            setOrderDataAction({
              orderSummaryError: null,
            })
          );
          {
            lastRender && dispatch(setOrderDataAction({ orderSummary: res?.data || {} }));
          }
        } else {
          dispatch(
            setOrderDataAction({
              orderSummaryError: res?.message || res?.messages[0] || 'Something went wrong',
              orderPromoCode: null,
            })
          );
          setCalculationLoading(false);
          setSummaryMsg(res?.message || 'Something went wrong');
          toast.error(res?.message || res?.messages[0] || 'Something went wrong');
        }
      });
    } else {
      setCalculationLoading(false);
    }
  }, [selectedServices, orderPromoCode, final_tip, orderIsRush]);
  return (
    <div className=' col-span-full flex flex-col xl:col-span-4 '>
      <div className='border-client-link -z-10 rounded-lg border bg-white p-5 shadow-lg'>
        {calculationLoading ? (
          <Loader text={summaryMsg} classes='h-96' />
        ) : summary?.items?.length == 0 ? (
          <NoData text='No service selected' classes='h-96' />
        ) : (
          <React.Fragment>
            {showServices && (
              <table className='go-hr-dashed w-full table-auto'>
                <thead className='go-hr-dashed  text-xs text-gray-400'>
                  <tr className='flex-inline'>
                    <th>
                      <div className=' go-label-16 py-3 text-left'>Services</div>
                    </th>
                    <th>
                      <div className=' go-label-16 py-3 text-right'>Qty</div>
                    </th>
                    <th>
                      <div className=' go-label-16 py-3 text-right'>Pricing</div>
                    </th>
                  </tr>
                </thead>
                <tbody className=' text-sm'>
                  {summary?.items?.map((service, i) => (
                    <tr
                      className={
                        'flex-inline  py-2  md:py-0 ' +
                        (i + 1 === summary?.length ? 'go-hr-dashed' : 'border-b border-slate-200')
                      }
                      key={i}
                    >
                      <td className=' w-1/3 py-3 md:table-cell md:w-auto'>
                        <div className='go-text-16 text-left'>{service?.name}</div>
                        {/* <div className='text-left text-xs font-normal text-gray-500'>{service?.description}</div> */}
                      </td>
                      <td className=' w-1/3 py-3 md:table-cell md:w-2/12'>
                        <div className='go-text-16 text-right'>{service?.quantity}</div>
                      </td>
                      <td className=' w-1/3 py-3 md:table-cell md:w-3/12'>
                        <div className='go-text-16 text-right'>{amountCheckFn(service?.client_fee)}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <section className=' pt-3'>
              <ul>
                <li className=' flex items-center justify-between py-1'>
                  <div className='go-label-16'>Subtotal:</div>
                  <div className='go-text-16'>
                    <span>{amountCheckFn(summary?.subtotal)}</span>
                  </div>
                </li>
                <li className=' flex items-center justify-between py-1'>
                  <div className='go-label-16'>Promo code: {orderPromoCode}</div>
                  <div className='go-text-16'>
                    <span>-{amountCheckFn(summary?.promo_code_discount)}</span>
                  </div>
                </li>

                {summary?.priority_fee && (
                  <li className=' relative flex items-center justify-between py-1'>
                    {/* <img src={fastforward} alt='tick' className='absolute -left-4 mt-0.5 h-4 w-4' /> */}
                    <div className=' go-label-16 '>Priority:</div>
                    <div className='go-text-16'>
                      <span>+{amountCheckFn(summary?.priority_fee)}</span>
                    </div>
                  </li>
                )}
                {summary?.tip && (
                  <li className=' flex items-center justify-between py-1'>
                    <div className='go-label-16'>Tip:</div>
                    <div className='go-text-16'>
                      <div className='go-text-16'>
                        <span>{amountCheckFn(summary?.tip)}</span>
                      </div>
                    </div>
                  </li>
                )}

                {/* <li className=' flex items-center justify-between py-1'>
                  <div className='go-label-16'>Order Minimum:</div>
                  <div className='go-text-16'>
                    <span>
                      <span>{summary?.order_minimum ? `$${summary?.order_minimum)}</span>
                    </span>
                  </div>
                </li> */}
                <li className=' flex items-center justify-between py-1'>
                  <div className='go-label-16'>Service Fee:</div>
                  <div className='go-text-16'>
                    <span>{amountCheckFn(summary?.service_fee)}</span>
                  </div>
                </li>
                <li className=' flex items-center justify-between py-3'>
                  <div className='go-text-16'>Estimated Total Cost</div>
                  <div className='go-text-16'>
                    <span>{amountCheckFn(summary?.estimated_total_cost)}</span>
                  </div>
                </li>
              </ul>
            </section>
            {summary?.order_minimum && summary?.order_minimum > 0 && (
              <p className='text-sm text-blue-400'>
                We have a ${summary?.order_minimum} minimum charge policy. <br></br> If your subtotal is less than $
                {summary?.order_minimum} we will increase the subtotal to this amount.
              </p>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
