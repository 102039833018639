import React, { useCallback, useEffect, useState } from 'react';
import { selectedServices } from '../../../mockdata/common';
import Collapse from './Collapse';
import EstimatedCost from './EstimatedCost';
import PaymentModal from './PaymentModal';
import AgreementModal from './AgreementModal';
import ServicesEstimate from './ServicesEstimate';
import ClientButton from '../../../components/ClientButton';
import ProcessOrderModal from './ProcessOrderModal';

export default function OrderSummary({ setstep }) {
  const [services, setServices] = useState(selectedServices);
  const [paymentModal, setPaymentModal] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [orderProcessModal, setOrderProcessModal] = useState(false);

  const paymentModalFn = useCallback((e, val) => {
    e.stopPropagation();
    setPaymentModal(val);
  }, []);
  const agreementModalFn = useCallback((e, val) => {
    e.stopPropagation();
    setAgreementModal(!val);
    setOrderProcessModal(true);
  }, []);
  const processModalModalFn = useCallback((e, val) => {
    e.stopPropagation();
    setAgreementModal(true);
  }, []);
  return (
    <div>
      <div className='max-w-9xl mx-auto w-full'>
        {/* <p className='go-textblacknormal-18 mb-2'>Please confirm your order</p> */}
        <div className='grid grid-cols-12 gap-6'>
          <Collapse services={services} />
          <div className='col-span-full flex flex-col xl:col-span-5'>
            <ServicesEstimate lastRender={true} />
            <ClientButton
              variant='primary'
              text='Confirm'
              classes='mt-8'
              onClick={(e) => processModalModalFn(e, true)}
            />
          </div>
        </div>
      </div>
      <PaymentModal
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
        agreementModalOpen={agreementModalFn}
      />
      <ProcessOrderModal
        orderProcessModal={orderProcessModal}
        setOrderProcessModal={setOrderProcessModal}
        setstep={setstep}
      />
      <AgreementModal
        agreementModal={agreementModal}
        setAgreementModal={setAgreementModal}
        onacceptclick={agreementModalFn}
      />
    </div>
  );
}
