import React, { useRef, useEffect } from 'react';
import Transition from '../utils/Transition';
function ModalWithHeadingSmall({
  children,
  icon,
  title = null,
  modalOpen,
  setModalOpen,
  classes,
  showCloseBtn = false,
  clickOutsiteToClose = true,
}) {
  const modalContent = useRef(null);
  useEffect(() => {
    if (clickOutsiteToClose) {
      const clickHandler = ({ target }) => {
        if (!modalOpen || modalContent.current.contains(target)) return;
        setModalOpen(false);
      };
      document.addEventListener('click', clickHandler);
      return () => document.removeEventListener('click', clickHandler);
    }
  }, [clickOutsiteToClose, modalOpen]);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      <Transition
        className='fixed inset-0 z-50 bg-slate-900 bg-opacity-30 transition-opacity'
        show={modalOpen}
        enter='transition ease-out duration-200'
        enterStart='opacity-0'
        enterEnd='opacity-100'
        leave='transition ease-out duration-100'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        aria-hidden='true'
      />
      <Transition
        className='fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6'
        role='dialog'
        aria-modal='true'
        show={modalOpen}
        enter='transition ease-in-out duration-200'
        enterStart='opacity-0 translate-y-4'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-in-out duration-200'
        leaveStart='opacity-100 translate-y-0'
        leaveEnd='opacity-0 translate-y-4'
      >
        <div
          ref={modalContent}
          className={'max-h-full w-full overflow-auto rounded-lg bg-white p-4 shadow-lg ' + classes}
        >
          <div className='px-5 py-3'>
            <div className='relative flex-row'>
              {title && (
                <div className='go-heading-20 flex justify-center text-center'>
                  <span className='mr-4'>{icon && icon}</span>
                  <span> {title}</span>
                </div>
              )}
              {showCloseBtn && (
                <button
                  className='absolute -right-1 -top-5 text-slate-400 hover:text-slate-500'
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalOpen(false);
                  }}
                >
                  <div className='sr-only'>Close</div>
                  <svg className='h-4 w-4 fill-current'>
                    <path d='M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z' />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {children}
        </div>
      </Transition>
    </>
  );
}

export default ModalWithHeadingSmall;
