import axios from 'axios';
import { ORDER_FILTERS, ORDERS_PAGINATION } from '../ActionsTypes';
import { getRequest, postRequest, putRequest } from '../../utils/axios';
import toast from 'react-hot-toast';
import { setMyOrdersPaginationAction } from '../Actions/MyOrdersActions';
const initialState = {
  orderFilters: {},
  pagination: null,
};
export const MyOrdersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ORDER_FILTERS:
      return { ...state, orderFilters: payload };
    case ORDERS_PAGINATION:
      return { ...state, pagination: payload };
    default:
      return state;
  }
};

// export const fetchAllOrders = async (filters = {}) => {
//   try {
//     return await postRequest(`/orders/client/orders/all`, filters);
//   } catch (error) {
//     toast.error(error?.response?.data?.message || 'Something went wrong');
//   }
// };
export const fetchAllOrders = async (filters = {}) => {
  try {
    const response_data = await postRequest(`/orders/client/orders/all`, filters);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const getOrderDetailsApi = async (orderId) => {
  try {
    const response_data = await getRequest(`/orders/client/orders/${orderId}`);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const cancelOrderApi = async (orderId, data) => {
  try {
    const response_data = await putRequest(`/orders/client/orders/${orderId}/cancel`, data);
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
