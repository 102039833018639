import React, { useEffect, useState } from 'react';
import lc_logo from '../../images/laundry-care-logo.png';
import pl_logo from '../../images/Push_Laundry_white_logo.png';
import { useDispatch } from 'react-redux';
import { resetCreateOrderFlow } from '../../redux/Reducers/CreateOrderReducer';
import SigninPage from './SigninPage';
import ForgotPasswordPage from './ForgotPasswordPage';
const appVersion = import.meta.env.VITE_APP_VERSION;
function Signin() {
  const dispatch = useDispatch();
  const [type, setType] = useState('signin');

  useEffect(() => {
    sessionStorage.removeItem('token');
    dispatch(resetCreateOrderFlow());
  }, []);

  const renderType = () => {
    switch (type) {
      case 'signin':
        return <SigninPage setType={setType} />;
      case 'forgotPassword':
        return <ForgotPasswordPage setType={setType} />;
      default:
    }
  };

  return (
    <main className='bg-login bg-inherit'>
      <div className='relative'>
        <div className='flex h-full min-h-screen flex-col after:flex-1'>
          <div className='flex-1'>
            <div className='flex h-2 flex-col after:flex-1'>
              <div className='flex justify-center py-20'>
                <div className='flex justify-center'>
                  {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                    <img src={lc_logo} className='w-56' alt='Laundry Care' />
                  ) : (
                    <img src={pl_logo} className='w-56' alt='Push Laundry' />
                  )}
                </div>
              </div>
            </div>
          </div>
          {renderType()}
        </div>
        <div className='absolute bottom-0  flex w-full items-center  justify-center p-2 text-center text-xs font-semibold text-white'>
          <span className='text-[#548ff7]'></span>
        </div>
      </div>
    </main>
  );
}
export default Signin;
