import React from 'react';
import { useNavigate } from 'react-router-dom';
import lc_bg from '../../../images/no-order-lc.png';
import pl_bg from '../../../images/no-order-pl.png';
import NoDataHtml from '../../../components/NoDataHtml';
import ClientButton from '../../../components/ClientButton';
const tenantName = import.meta.env.VITE_TENANT_NAME;
export default function NoOrders({ text = 'no results found' }) {
  const navigate = useNavigate();
  return (
    <div>
      <NoDataHtml>
        <div className='m-auto mt-16 w-full bg-white py-10'>
          <div className='px-4 text-center'>
            <div className='mb-4 inline-flex w-40  items-center justify-center '>
              {tenantName === 'laundrycare' ? <img src={lc_bg} alt='' /> : <img src={pl_bg} alt='' />}
            </div>
            <h2 className='mb-2 text-2xl font-semibold text-slate-800'>Oh no!</h2>
            <div className='mb-6'>{text}</div>
            <ClientButton
              text='Schedule a Pickup Now'
              variant='primary'
              onClick={() => navigate(`/create-new-order`)}
            />
          </div>
        </div>
      </NoDataHtml>
    </div>
  );
}
