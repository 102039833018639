import {
  GET_ALL_SERVICE_ADDRESSES,
  GET_ALL_SERVICE_ADDRESSES_LOADING,
  SELECTED_SERVICE_ADDRESS,
  ADDRESS_PREFERENCES,
} from '../ActionsTypes';

const setAllServiceAddressesAction = (data) => ({
  type: GET_ALL_SERVICE_ADDRESSES,
  payload: data,
});
const setAllServiceAddressesLoadingAction = (data) => ({
  type: GET_ALL_SERVICE_ADDRESSES_LOADING,
  payload: data,
});
const setSelectedServiceAddressAction = (data) => ({
  type: SELECTED_SERVICE_ADDRESS,
  payload: data,
});
const setAddressPreferencesAction = (data) => ({
  type: ADDRESS_PREFERENCES,
  payload: data,
});

export {
  setAllServiceAddressesAction,
  setAllServiceAddressesLoadingAction,
  setSelectedServiceAddressAction,
  setAddressPreferencesAction,
};
