import React, { useEffect, useState } from 'react';
import ArrowRight from '../../components/Icons/ArrowRight';
import ClientButton from '../../components/ClientButton';
import { amountCheckFn, textCheckFn } from '../../utils/commonUtil';
import Stripe from '../../components/Stripe';
import { useCallback } from 'react';
import { titleCaseFormatter } from '../../utils/stringFormatters';
export default function DefaultPayment({ invoice, onPaymentTypeChangeFn }) {
  const [paymentMethod, setPaymentMethod] = useState('existing_card');
  useEffect(() => {
    onPaymentTypeChangeFn(paymentMethod);
  }, [paymentMethod]);

  return (
    <div>
      <div className='my-5 flex rounded-xl border border-indigo-50 bg-[#f9fbff] '>
        <div className='h-28 w-full items-center py-5 text-center'>
          <p className='go-label-15'>Remaining Amount</p>
          <p className='go-textblackbold-32'>{amountCheckFn(invoice?.remaining_balance)}</p>
        </div>
      </div>
      {invoice?.billing_address?.billing_details == null ? (
        <div className='my-5 flex rounded-xl border border-red-300 bg-red-100 '>
          <div className=' w-full items-center py-5 text-center'>
            <p className='go-label-13'>Please setup your payment method before paying the Invoice.</p>
            <p className='go-label-13'>{`My Account > Payments > Payment Info.`}</p>
          </div>
        </div>
      ) : (
        <div className='mb-4 flex-row'>
          <p className='go-textblack-16 py-1'>Charge to Card</p>
          <div>
            <label className='my-4 ml-4 flex'>
              <input
                defaultChecked={true}
                type='radio'
                value='1'
                className='form-radio mt-1.5'
                name='chargecard'
                onChange={(e) => setPaymentMethod('existing_card')}
              />
              <span className='go-textblackmedium-14 ml-2 pt-0.5'>
                {titleCaseFormatter(invoice?.billing_address?.billing_details?.default_payment_method?.card_brand)} ****{' '}
                {invoice?.billing_address?.billing_details?.default_payment_method?.card_last4}
              </span>
            </label>
          </div>
          <div>
            <label className='my-4 ml-4 flex'>
              <input
                defaultChecked={false}
                type='radio'
                value='1'
                className='form-radio mt-1.5'
                name='chargecard'
                onChange={(e) => setPaymentMethod('new_card')}
              />
              <span className='go-textblackmedium-14 ml-2 pt-0.5'>Use a different credit card</span>
            </label>
          </div>
        </div>
      )}

      <div className='h-40'>
        {paymentMethod == 'new_card' && (
          <div className='mb-5 flex-row'>
            <div className='flex justify-start py-4 pl-10'>
              <div className='w-3/5'>
                <Stripe addressid={invoice?.billing_address?.id} cardAdded={() => window.location.reload()} />
              </div>
            </div>
            {/* <div className='mb-4 flex  items-center justify-between rounded-md p-2 px-5 shadow'>
          <p className='go-textblack-15 py-1'>Check</p>
          <ArrowRight />
        </div>
        <div className='mb-4 flex  items-center justify-between rounded-md p-2 px-5 shadow'>
          <p className='go-textblack-15 py-1'>Cash</p>
          <ArrowRight />
        </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
