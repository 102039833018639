//Create Order
const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
const SET_ONE_TIME_ORDER = 'SET_ONE_TIME_ORDER';
const SELECTED_SERVICES = 'SELECTED_SERVICES';
const SET_ORDER_DATA = 'SET_ORDER_DATA';

//Service Addresses
const GET_ALL_SERVICE_ADDRESSES = 'GET_ALL_SERVICE_ADDRESSES';
const GET_ALL_SERVICE_ADDRESSES_LOADING = 'GET_ALL_SERVICE_ADDRESSES_LOADING';
const UPDATE_SERVICE_ADDRESSES = 'UPDATE_SERVICE_ADDRESSES';
const SELECTED_SERVICE_ADDRESS = 'SELECTED_SERVICE_ADDRESS';
const ADDRESS_PREFERENCES = 'ADDRESS_PREFERENCES';

//Landing Page
const GET_LANDING_PAGE_DATA = 'GET_LANDING_PAGE_DATA';
const GET_LANDING_PAGE_DATA_LOADING = 'GET_LANDING_PAGE_DATA_LOADING';

//Providers
const GET_ALL_PROVIDERS = 'GET_ALL_PROVIDERS';
const GET_ALL_PROVIDERS_LOADING = 'GET_ALL_PROVIDERS_LOADING';
const SELECTED_PROVIDERS = 'SELECTED_PROVIDERS';

//Profile
const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const PROFILE_LOADING = 'PROFILE_LOADING';

//Orders
const ORDER_FILTERS = 'ORDER_FILTERS';
const ORDERS_LOADING = 'ORDERS_LOADING';
const ORDERS_PAGINATION = 'ORDERS_PAGINATION';

//My accounts
const GET_ORDER_PREFERENCES = 'GET_ORDER_PREFERENCES';
const PREFERENCES_LOADING = 'PREFERENCES_LOADING';

export {
  SET_ORDER_TYPE,
  SET_ONE_TIME_ORDER,
  SELECTED_SERVICES,
  SET_ORDER_DATA,
  GET_ALL_SERVICE_ADDRESSES,
  GET_ALL_SERVICE_ADDRESSES_LOADING,
  UPDATE_SERVICE_ADDRESSES,
  SELECTED_SERVICE_ADDRESS,
  ADDRESS_PREFERENCES,
  GET_LANDING_PAGE_DATA,
  GET_LANDING_PAGE_DATA_LOADING,
  GET_ALL_PROVIDERS,
  GET_ALL_PROVIDERS_LOADING,
  SELECTED_PROVIDERS,
  GET_PROFILE_DATA,
  SET_PROFILE_DATA,
  PROFILE_LOADING,
  ORDER_FILTERS,
  ORDERS_LOADING,
  ORDERS_PAGINATION,
  GET_ORDER_PREFERENCES,
  PREFERENCES_LOADING,
};
