import React, { useCallback, useState } from 'react';
import ClientButton from '../../components/ClientButton';
import { downloadInvoicebyID } from '../../redux/Reducers/PaymentsReducer';

export default function InvoiceFooter({ open, setOpen, billingDetails, invoice, invoiceId }) {
  const [downloadPDFLoading, setDownloadPDFLoading] = useState(false);
  const openPayInvoice = useCallback((e, val) => {
    e.stopPropagation();
    setOpen(val);
  }, []);
  const downloadInvoice = useCallback((e, val) => {
    setDownloadPDFLoading(true);
    e.stopPropagation();
    const response = downloadInvoicebyID(invoiceId);
    response.then((res) => {
      setDownloadPDFLoading(false);
    });
  }, []);
  return (
    <div className=' mt-10 w-full rounded-lg bg-white  '>
      {billingDetails?.addresses?.[0]?.billing_details?.default_payment_method_type == 'CHECK' ? (
        <div className='flex items-center justify-center p-3'>
          <span className=' go-textblacknormal-14'>
            Check - (<span className=' font-bold'>mail to: </span>PO Box 693 Centerburg, OH 43011){' '}
          </span>
        </div>
      ) : billingDetails?.addresses?.[0]?.billing_details?.default_payment_method_type == 'ACH' ? (
        <></>
      ) : (
        <div className='flex items-center justify-end p-3'>
          {(invoice?.status === 'PAST_DUE' || invoice?.status === 'BAD_DEBT') && (
            <ClientButton
              classes=''
              text='Pay Invoice'
              variant='primary'
              onClick={(e) => {
                openPayInvoice(e, true);
              }}
            />
          )}
          <ClientButton
            classes=''
            text='Download PDF'
            variant='secondary'
            loading={downloadPDFLoading}
            onClick={(e) => {
              downloadInvoice(e, true);
            }}
          />
        </div>
      )}
    </div>
  );
}
