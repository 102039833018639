const statusColor = (status) => {
  const statusLowerCase = status?.toLowerCase();
  const replaceUnderrscore = statusLowerCase?.replace(/_/g, ' ');
  switch (replaceUnderrscore) {
    case 'pending':
      return ['#548ff7', '#e6eefe'];
    case 'voided':
      return ['#f75454', '#fdd4d4'];
    case 'redeemed':
      return ['#ffffff', '#38be7f'];
    case 'active':
      return ['#38be7f', '#e5f4f2'];
    case 'expired':
      return ['#1d1e30', '#dfdfdf'];
    case 'complete':
      return ['#f5f5fb', '#38be7f'];
    case 'in progress':
      return ['#00347b', '#cbe1ff'];
    case 'staged':
      return ['#000000', '#BCDAFF'];
    case 'waiting':
      return ['#bfa5ff', '#f5f5fb'];
    case 'delivered':
      return ['#38be7f', '#EBF9F2'];
    case 'pickup complete':
      return ['#548ff7', '#f5f5fb'];
    case 'out for delivery':
      return ['#01672b', '#d4f4e1'];
    case 'waiting for pickup':
      return ['#000000', '#e5e6e9'];
    case 'scheduled':
      return ['#1B2D3E', '#a4d3ff'];
    case 'finalized':
      return ['#ffffff', '#5A9B41'];
    case 'cancelled':
      return ['#fb0505', '#FFDADA'];
    case 'submitted':
      return ['#f166ad', '#ffe5f2'];
    case 'offer pending':
      return ['#28c2b3', '#E1F2F4'];
    case 'in review':
      return ['#ea6278', '#F5E1E5'];
    case 'offer accepted':
      return ['#6236ff', '#e3dbff'];
    case 'closed':
      return ['#1d1e30', '#dfdfdf'];
    case 'declined':
      return ['#ffffff', '#ea6278'];
    case 'paid':
      return ['#38be7f', '#EBF9F2'];
    case 'past due':
      return ['#fb5905', '#F7E9E5'];
    case 'overdue':
      return ['#fb0505', '#F6D1D6'];
    default:
      return ['#548ff7', '#e6eefe'];
  }
};

export default statusColor;
