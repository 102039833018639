import React, { useCallback, useEffect, useRef, useState } from 'react';
import ClientButton from '../../../components/ClientButton';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addNewServiceAddressesApi } from '../../../redux/Reducers/ServiceAddressesReducer';
import toast from 'react-hot-toast';
import { AddressAutofill } from '@mapbox/search-js-react';
import { isEmpty, isNull } from 'lodash';
import Loader from '../../../components/Loader';
const MAPBOX_API_TOKEN =
  import.meta.env.VITE_TENANT_NAME === 'laundrycare'
    ? import.meta.env.VITE_LAUNDRYCARE_MAPBOX_API_TOKEN
    : import.meta.env.VITE_TENANT_NAME === 'pushlaundry'
    ? import.meta.env.VITE_PUSHLAUNDRY_MAPBOX_API_TOKEN
    : '';
export default function NewAddress({ showModal, cancel }) {
  const dispatch = useDispatch();
  const nameField = useRef(null);
  const address1Field = useRef(null);
  const address2Field = useRef(null);
  const cityField = useRef(null);
  const stateField = useRef(null);
  const zipField = useRef(null);

  const [addressData, setAddressData] = useState(null);
  const [loading, setLoading] = useState(false);
  const onCancel = (e) => {
    cancel(e);
  };
  const addressChangeFn = (data) => {
    setAddressData((prev) => ({
      ...prev,
      address_line_1: data.features[0].properties.address_line1,
      address_line_2: data.features[0].properties.address_line2,
      city: data.features[0].properties.address_level2,
      state: data.features[0].properties.address_level1,
      postal_code: data.features[0].properties.postcode,
    }));
  };

  const validateAddressFn = (address) => {
    const fields = ['name', 'address_line_1', 'city', 'state', 'postal_code'];
    for (const field of fields) {
      if (!address[field] || address[field] === '') {
        toast.error(`Please enter a ${field.replace('_', ' ')}`);
        setAddressData((prev) => ({ ...prev, [field]: null }));
        return false;
      }
    }
    return true;
  };

  const handleSubmitAddress = (e) => {
    e.preventDefault();
    const payload = {
      name: nameField.current.value,
      address_line_1: address1Field.current.value,
      address_line_2: address2Field.current.value,
      city: cityField.current.value,
      state: stateField.current.value,
      postal_code: zipField.current.value,
    };
    if (validateAddressFn(payload)) {
      setLoading(true);
      const create = dispatch(addNewServiceAddressesApi(payload));
      create.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setLoading(false);
          showModal(e, true, res?.data?.address_id, 'new');
        } else {
          setLoading(false);
          toast.error(res?.message || 'Something went wrong');
        }
      });
    }
  };

  return (
    <>
      <div className=''>
        <div className='z-0 mb-4 rounded-lg bg-white '>
          <header className='flex-row items-center px-6 py-4'>
            <h5 className='go-textblackmedium-16 my-1 flex-1'>Add New Service Address</h5>
          </header>
          {loading ? (
            <Loader text='processing...' classes='h-40' />
          ) : (
            <form onSubmit={handleSubmitAddress}>
              <div className='grid gap-5 px-6 pb-6 md:grid-cols-3'>
                <div>
                  <div>
                    <label className='go-label-16 mb-2 block' htmlFor='name'>
                      Label
                    </label>
                    <input
                      ref={nameField}
                      name='name'
                      className={`go-inputtext-16 ${isNull(addressData?.name) ? 'border-red-500' : ''}`}
                      type='text'
                      defaultValue={addressData?.name}
                      onChange={(e) => {
                        setAddressData((prev) => ({ ...prev, name: e.target.value }));
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label className='go-label-16 mb-2 block' htmlFor='address_line_1'>
                      Street Address
                    </label>
                    <AddressAutofill
                      accessToken={MAPBOX_API_TOKEN}
                      options={{
                        language: 'en',
                        country: 'US',
                      }}
                      onRetrieve={addressChangeFn}
                    >
                      <input
                        ref={address1Field}
                        name='address_line_1'
                        className={`go-inputtext-16 ${isNull(addressData?.address_line_1) ? 'border-red-500' : ''}`}
                        type='text'
                        placeholder='Address'
                        autoComplete='address-line1'
                        defaultValue={addressData?.address_line_1}
                      />
                    </AddressAutofill>
                  </div>
                </div>

                <div>
                  <div>
                    <label className='go-label-16 mb-2 block' htmlFor='address_line_2'>
                      Apartment or suite number
                    </label>
                    <input
                      ref={address2Field}
                      name='address_line_2'
                      className={`go-inputtext-16 `}
                      type='text'
                      placeholder='Apartment number'
                      autoComplete='address-line2'
                      defaultValue={addressData?.address_line_2}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label className='go-label-16 mb-2 block' htmlFor='city'>
                      City
                    </label>
                    <input
                      ref={cityField}
                      name='city'
                      className={`go-inputtext-16 ${isNull(addressData?.city) ? 'border-red-500' : ''}`}
                      type='text'
                      placeholder='City'
                      autoComplete='address-level2'
                      defaultValue={addressData?.city}
                    />
                  </div>
                </div>
                <div className='flex space-x-2'>
                  <div className='flex-1'>
                    <div>
                      <label className='go-label-16 mb-2 block' htmlFor='state'>
                        State
                      </label>
                      <input
                        ref={stateField}
                        name='state'
                        className={`go-inputtext-16 ${isNull(addressData?.state) ? 'border-red-500' : ''}`}
                        type='text'
                        placeholder='State'
                        autoComplete='address-level1'
                        defaultValue={addressData?.state}
                      />
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div>
                      <label className='go-label-16 mb-2 block' htmlFor='postal_code'>
                        Zip Code
                      </label>
                      <input
                        ref={zipField}
                        name='postal_code'
                        className={`go-inputtext-16 ${isNull(addressData?.postal_code) ? 'border-red-500' : ''}`}
                        type='number'
                        placeholder='Postcode'
                        autoComplete='postal-code'
                        defaultValue={addressData?.postal_code}
                        maxLength={5}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex pl-4 pb-4'>
                <ClientButton
                  variant='secondary'
                  text='Cancel'
                  classes='mr-2'
                  onClick={(e) => {
                    onCancel(false);
                  }}
                />
                <ClientButton variant='primary' text='Save Service Address' classes='mr-2' type='submit' />
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
