import React from 'react';
import CheckmarkIcon from '../../components/Icons/CheckmarkIcon';
import { amountCheckFn, textCheckFn } from '../../utils/commonUtil';
import { dateFn } from '../../utils/dateTimeFormatter';
export default function PaymentSummary({ invoice, paymentSummaryData, finalAmountCharged }) {
  return (
    <div>
      <div className='px-6'>
        <div className='flex justify-center'>
          <h3 className='go-textblackbold-24 flex items-center'>
            <CheckmarkIcon /> You paid the invoice!
          </h3>
        </div>
        <div className='flex justify-center'>
          <p className='go-textblack-16 py-1'>Invoice {invoice?.transaction_id}</p>
        </div>
      </div>

      <div className='my-5 mb-40 flex-row items-center rounded-xl border border-indigo-50 bg-[#f9fbff]'>
        <table className='my-10 w-full'>
          <tbody>
            <tr>
              <td className='go-label-13 w-1/2 py-1.5 text-right'>Account Name:</td>
              <td className='go-textblack-13 py-1.5 pl-5'>{textCheckFn(paymentSummaryData?.billing_contact_name)}</td>
            </tr>
            <tr>
              <td className='go-label-13 w-1/2 py-1.5 text-right'>Email:</td>
              <td className='go-textblack-13 py-1.5 pl-5'>
                {paymentSummaryData?.billing_contact_email && paymentSummaryData?.billing_contact_email}
              </td>
            </tr>
            <tr>
              <td className='go-label-13 w-1/2 py-1.5 text-right'>Paid Date :</td>
              <td className='go-textblack-13 py-1.5 pl-5'>
                {dateFn('MM/DD/YYYY', paymentSummaryData?.paid_date)} at{' '}
                {dateFn('timeLocal', paymentSummaryData?.paid_date)}
              </td>
            </tr>
          </tbody>
        </table>
        <hr className='border-b border-indigo-50' />
        <table className='my-10 w-full'>
          <tbody>
            <tr>
              <td className='go-label-13 w-1/2 py-1.5 text-right'>Amount Paid:</td>
              <td className='go-textblack-13 py-1.5 pl-5'>{amountCheckFn(finalAmountCharged)}</td>
            </tr>
            <tr>
              <td className='go-label-13 w-1/2 py-1.5 text-right'>Remaining Amount:</td>
              <td className='go-textblack-13 py-1.5 pl-5'>{amountCheckFn(paymentSummaryData?.remaining_amount)}</td>
            </tr>
            <tr>
              <td className='go-label-13 w-1/2 py-1.5 text-right'>Remaining Amount Due Date:</td>
              <td className='go-textblack-13 py-1.5 pl-5'>
                {dateFn('MM/DD/YYYY', invoice?.due_date)} at {dateFn('timeLocal', invoice?.due_date)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
