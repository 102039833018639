import React from 'react';
import PickupDelivery from './PickupDelivery';
import ServicesEstimate from '../OrderSummary/ServicesEstimate';

export default function PickupAndDelivery() {
  return (
    <div>
      <div className='max-w-9xl mx-auto mt-10 w-full'>
        <div className='grid grid-cols-12 gap-6'>
          <PickupDelivery />
          <ServicesEstimate />
        </div>
      </div>
    </div>
  );
}
