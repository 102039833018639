import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import sidebarData from '../mockdata/sidebar';
import lc_logo from '../../src/images/laundry-care-logo.png';
import lc_icon from '../../src/images/laundry_care_icon.png';
import pl_logo from '../../src/images/Push_Laundry_white_logo.png';
import pl_icon from '../../src/images/push_laundry_icon.png';
import { useDispatch, useSelector } from 'react-redux';
const appVersion = import.meta.env.VITE_APP_VERSION;
function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const { profileData } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      <div
        className={`fixed inset-0 z-40 bg-[#0A1119] bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden ${
          sidebarOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
        }`}
        aria-hidden='true'
      ></div>

      <div
        id='sidebar'
        ref={sidebar}
        className={`no-scrollbar lg:sidebar-expanded:!w-64 absolute left-0 top-0 z-40 flex h-screen w-64 shrink-0 flex-col overflow-y-scroll rounded-r-2xl bg-[#0A1119] p-4 transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:w-20 lg:translate-x-0 lg:overflow-y-auto 2xl:!w-64 ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        <div className='my-10 flex justify-between pr-3 sm:px-2'>
          <button
            ref={trigger}
            className='text-slate-500 hover:text-slate-400 lg:hidden'
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls='sidebar'
            aria-expanded={sidebarOpen}
          >
            <span className='sr-only'>Close sidebar</span>
            <svg className='h-6 w-6 fill-current' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
              <path d='M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z' />
            </svg>
          </button>
          {sidebarExpanded ? (
            <NavLink end to='/' className='mt-2 rounded-lg 2xl:mb-2 2xl:hidden'>
              {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                <img src={lc_logo} alt='laundry care icon' className='  w-auto' />
              ) : (
                <img src={pl_logo} alt='push laundry icon' className='  w-auto' />
              )}
            </NavLink>
          ) : (
            <NavLink end to='/' className='mt-2 rounded-lg 2xl:mb-2 2xl:hidden'>
              {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                <img src={lc_icon} alt='laundry care icon' className='  w-auto' />
              ) : (
                <img src={pl_icon} alt='push laundry icon' className='  w-auto' />
              )}
            </NavLink>
          )}

          <NavLink end to='/' className='mt-2 hidden rounded-lg p-4 2xl:mb-2 2xl:block'>
            {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
              <img src={lc_logo} alt='laundry care icon' className='  w-auto' />
            ) : (
              <img src={pl_logo} alt='push laundry icon' className='  w-auto' />
            )}
          </NavLink>
        </div>
        <div
          className={
            'mb-10 flex justify-center ' +
            (profileData?.status != 'ACTIVE' ||
              (!profileData?.is_allowed_to_create_order && ' pointer-events-none opacity-50'))
          }
        >
          <Link
            className='go-textwhite-15 hidden rounded-full bg-[#548ff7] px-4 py-2 text-center 2xl:block '
            to='/create-new-order'
            disabled={profileData?.status != 'ACTIVE' || !profileData?.is_allowed_to_create_order}
            onClick={() => setSidebarOpen(false)}
          >
            Create New Order
          </Link>
          {sidebarExpanded ? (
            <Link
              className='go-textwhite-15  rounded-full bg-[#548ff7] px-4 py-2 text-center 2xl:hidden '
              to='/create-new-order'
              disabled={profileData?.status != 'ACTIVE' || !profileData?.is_allowed_to_create_order}
              onClick={() => setSidebarOpen(false)}
            >
              Create New Order
            </Link>
          ) : sidebarOpen ? (
            <Link
              className='go-textwhite-15  rounded-full bg-[#548ff7] px-4 py-2 text-center 2xl:hidden '
              to='/create-new-order'
              disabled={profileData?.status != 'ACTIVE' || !profileData?.is_allowed_to_create_order}
              onClick={() => setSidebarOpen(false)}
            >
              Create New Order
            </Link>
          ) : (
            <Link
              className=' m-4 rounded bg-[#548ff7] text-center text-xs font-semibold text-white 2xl:hidden '
              alt='Create new order'
              to='/create-new-order'
              disabled={profileData?.status != 'ACTIVE' || !profileData?.is_allowed_to_create_order}
              onClick={() => setSidebarOpen(false)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='h-8 w-8'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v12m6-6H6' />
              </svg>
            </Link>
          )}
        </div>
        <div className='space-y-8'>
          <div>
            <h3 className='pl-3 text-xs font-semibold uppercase text-slate-500'>
              <span
                className='lg:sidebar-expanded:hidden hidden w-6 text-center lg:block 2xl:hidden'
                aria-hidden='true'
              >
                •••
              </span>
            </h3>
            <ul className='mt-3'>
              {sidebarData?.map((item, index) => {
                return (
                  <li key={index} className={`my-2 rounded-sm px-3 py-2 last:mb-0 `}>
                    <NavLink
                      end
                      to={item?.link}
                      className={`go-textwhite-14 block truncate transition duration-150 hover:text-white ${
                        pathname.includes(item?.link) ? 'text-white' : 'text-slate-500'
                      }`}
                      onClick={() => setSidebarOpen(false)}
                    >
                      <div className='flex items-center'>
                        <div dangerouslySetInnerHTML={{ __html: item?.icon }}></div>
                        <span className='lg:sidebar-expanded:opacity-100  ml-3 duration-200 lg:opacity-0 2xl:opacity-100'>
                          {item?.title}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className='mt-auto justify-end space-y-8'>
          <ul className=''>
            <li className={`my-1 rounded-sm px-3 py-2 last:mb-0 `}>
              <NavLink
                end
                to='/privacy'
                className={`block truncate transition duration-150 hover:text-white ${
                  pathname.includes('privacy') ? 'text-white' : 'text-slate-500'
                }`}
              >
                <div className='flex items-center'>
                  <span className='lg:sidebar-expanded:opacity-100 ml-3 text-xs font-medium duration-200 lg:opacity-0 2xl:opacity-100'>
                    Privacy
                  </span>
                </div>
              </NavLink>
            </li>

            <li className={`my-1 rounded-sm px-3 py-2 last:mb-0 `}>
              <NavLink
                end
                to='/tos'
                className={`block truncate transition duration-150 hover:text-white ${
                  pathname.includes('tos') ? 'text-white' : 'text-slate-500'
                }`}
              >
                <div className='flex items-center'>
                  <span className='lg:sidebar-expanded:opacity-100 ml-3 text-xs font-medium duration-200 lg:opacity-0 2xl:opacity-100'>
                    Terms of Service
                  </span>
                </div>
              </NavLink>
            </li>
            <li className={`my-1 rounded-sm px-3 py-2 last:mb-0 `}>
              <div className='flex items-center'>
                <span className=' ml-3 text-xs font-medium text-slate-500 duration-200'></span>
              </div>
            </li>
          </ul>
        </div>

        <div className=' hidden justify-end pt-3 lg:inline-flex 2xl:hidden'>
          <div className='px-3 py-2'>
            <button
              onClick={() => {
                setSidebarExpanded(!sidebarExpanded);
              }}
            >
              <span className='sr-only'>Expand / collapse sidebar</span>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='sidebar-expanded:rotate-180 h-6 w-6 fill-current text-slate-500'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5 8.25 12l7.5-7.5' />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
