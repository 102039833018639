/* eslint-disable react/prop-types */
import React from 'react';

export default function SmallButton({ variant, size = 'normal', text, onClick, classes, disabled = false }) {
  const classSwitch = (key) => {
    switch (key) {
      case 'primary':
        return 'bg-client-text text-white ';
      case 'secondary':
        return 'hover:bg-portal-100 text-client-text border-client-text border-1 bg-white ';
      case 'link':
        return 'text-client-link bg-white border-client-link  ';
      case 'cancel':
        return 'text-white bg-client-cancelled border-client-cancelled  ';
      default:
        break;
    }
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        'btn min-w-20 my-1 mx-2 rounded-lg disabled:cursor-not-allowed ' +
        (size === 'xs'
          ? 'py-1.5 px-4 text-[13px] sm:py-1.5 sm:px-3 sm:text-[14px]  '
          : 'py-2 px-4 text-[13px] sm:py-3 sm:px-4 sm:text-[14px]  ') +
        classSwitch(variant) +
        classes
      }
    >
      {/* {text.replace(/\b\w/g, (l) => l.toUpperCase())} */}
      {text}
    </button>
  );
}
