import React from 'react';
import statusColor from '../utils/statusColors';
import { titleCaseFormatter } from '../utils/stringFormatters';

export default function StatusChip({ status, size = 'sm' }) {
  return (
    <button
      className={
        (size === 'sm' ? 'px-4 py-1 text-[13px] font-medium ' : 'px-6 py-2 text-[15px] font-medium ') +
        `rounded-full text-center`
      }
      style={{ color: statusColor(status)[0], backgroundColor: statusColor(status)[1] }}
    >
      {titleCaseFormatter(status)}
    </button>
  );
}
