import { GET_PROFILE_DATA, PROFILE_LOADING, SET_PROFILE_DATA } from '../ActionsTypes';

const getProfileDataAction = (data) => ({
  type: GET_PROFILE_DATA,
  payload: data,
});

const setProfileDataAction = (data) => ({
  type: SET_PROFILE_DATA,
  payload: data,
});

const setProfileLoadingAction = (data) => ({
  type: PROFILE_LOADING,
  payload: data,
});

export { getProfileDataAction, setProfileLoadingAction, setProfileDataAction };
