import { SET_ONE_TIME_ORDER, SET_ORDER_TYPE, SELECTED_SERVICES, SET_ORDER_DATA } from '../ActionsTypes';

const setOrderTypeAction = (data) => {
  return {
    type: SET_ORDER_TYPE,
    payload: data,
  };
};
const setOneTimeOrderAction = (data) => {
  return {
    type: SET_ONE_TIME_ORDER,
    payload: data,
  };
};

const setOrderDataAction = (data) => {
  return {
    type: SET_ORDER_DATA,
    payload: data,
  };
};

export { setOrderTypeAction, setOneTimeOrderAction, setOrderDataAction };
