import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Addresses from './Addresses';
import NewAddress from './NewAddress';
import { getAllServiceAddressesApi } from '../../../redux/Reducers/ServiceAddressesReducer';
import ModalForOrders from './ModalForOrders';
import ModalToUpdateAddress from './ModalToUpdateAddress';

export default function ServiceAddresses({ newaddress, cancelNewAddress, setTabs }) {
  const dispatch = useDispatch();
  const { allServiceAddresses, allServiceAddressesLoading } = useSelector((state) => state.serviceAddresses);
  const [newAddressID, setNewAddressID] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [existingOrders, setExistingOrders] = useState(null);
  const [newAddressForOrders, setNewAddressForOrders] = useState(null);
  const [oldAddressForOrders, setOldAddressForOrders] = useState(null);

  const showUpdateModalFn = useCallback((e, val, res, type) => {
    e.stopPropagation();
    setShowUpdateModal(val);
    setNewAddressID({ id: res, type: type });
    // if(val === false) cancelNewAddress(res);
  }, []);
  const showOrderModalFn = useCallback((e, val, res, type, oldAddress, newAddress) => {
    e.stopPropagation();
    if (type === 'update') {
      setShowUpdateModal(val);
      setNewAddressID({ id: res, type: 'update' });
    } else {
      setShowOrderModal(val);
      setExistingOrders(res?.data);
      setNewAddressForOrders(newAddress);
      setOldAddressForOrders(oldAddress);
    }
  }, []);
  const onUpdateModalCancelFn = useCallback((val) => {
    setShowUpdateModal(false);
    cancelNewAddress(val);
  }, []);
  const onOrderModalCancelFn = useCallback((e, val, id, type) => {
    if (type === 'update') {
      setShowOrderModal(false);
      showUpdateModalFn(e, val, id, type);
    } else {
      setShowOrderModal(false);
      showUpdateModalFn(e, false, id);
    }
  }, []);

  useEffect(() => {
    dispatch(getAllServiceAddressesApi());
  }, [!showUpdateModal]);

  // useEffect(() => {
  //   setserviceAddressess(allServiceAddresses);
  // }, [allServiceAddresses]);

  return (
    <>
      {newaddress ? (
        <NewAddress showModal={showUpdateModalFn} cancel={onUpdateModalCancelFn} />
      ) : (
        <Addresses
          addresses={allServiceAddresses}
          loadingStatus={allServiceAddressesLoading}
          showModal={showOrderModalFn}
          setTabs={setTabs}
        />
      )}
      <div className='py-4'>
        <ModalToUpdateAddress
          modal={showUpdateModal}
          updateModal={setShowUpdateModal}
          newAddressID={newAddressID}
          addressess={allServiceAddresses}
          cancelModal={onUpdateModalCancelFn}
        />
        <ModalForOrders
          modal={showOrderModal}
          setShowOrderModal={setShowOrderModal}
          updateModal={onOrderModalCancelFn}
          existingOrders={existingOrders}
          newAddress={newAddressForOrders}
          oldAddress={oldAddressForOrders}
        />
      </div>
    </>
  );
}
