import React, { Suspense, useState } from 'react';
import ClientButton from '../../../components/ClientButton';
import Stripe from '../../../components/Stripe';
import { useSelector } from 'react-redux';
export default function EditPaymentInfo({ editPayment, setEditPayment }) {
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
    const [payClick, setPayClick] = useState(false);
    const onPayClick = () => {
      setPayClick(!payClick);
    };
  const onCardSaveFn = () => {
    setEditPayment(false);
  };
  return (
    <div>
      <div className='z-0 rounded-lg bg-white'>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Edit Payment Info</h5>
        </header>
        <div className='p-6'>
          <div className='flex'>
            <div className='w-full rounded-lg'>
              <div className=''>
                {editPayment && <Stripe addressid={selectedAddress?.id} cardAdded={() => onCardSaveFn()} />}
                <div className='grid gap-1'>
                  <ClientButton
                    variant='secondary'
                    text='Cancel'
                    classes='mr-2'
                    onClick={() => setEditPayment(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
