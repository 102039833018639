import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import StatusChip from '../../../components/StatusChip';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import ClientButton from '../../../components/ClientButton';
import { Link } from 'react-router-dom';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import { deactivateAcccountApi } from '../../../redux/Reducers/commonApis';
import Loader from '../../../components/Loader';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { toast } from 'react-hot-toast';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ReactivateModal from '../../../components/ReactivateModal';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { accountTypeFormatFn, textCheckFn } from '../../../utils/commonUtil';

export default function AccountInfo({ setEdit, data }) {
  // const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  // const [activateModal, setActivateModal] = useState(false);
  // const [deactivateModal, setDeactivateModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [loaderMsg, setLoaderMsg] = useState('processing...');
  // const [accountData, setAccountData] = useState({});
  // const deactivateModalFn = (e, val) => {
  //   e.stopPropagation();
  //   setDeactivateModal(val);
  // };
  // const showReactivateModalFn = useCallback((e, val) => {
  //   e.stopPropagation();
  //   setActivateModal(val);
  // }, []);
  // const deactivateFn = (payload) => {
  //   setLoading((prev) => !prev);
  //   const create = deactivateAcccountApi(payload);
  //   create?.then((res) => {
  //     if (res?.status === 400) {
  //       setLoading(false);
  //       setAccountData(res?.data);
  //     } else if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
  //       toast.success(res?.message || 'Account deactivated successfully');
  //       setLoaderMsg('loading profile data...');
  //       dispatch(getProfileDataApi(true));
  //       setTimeout(() => {
  //         setDeactivateModal(false);
  //       }, 1000);
  //     } else {
  //       setLoading(false);
  //       toast.error(res?.message || 'Something went wrong');
  //     }
  //   });
  // };
  // const onYesDeactivateFn = () => {
  //   const payload = {
  //     warning_confirmation: true,
  //   };
  //   !isEmpty(accountData) ? deactivateFn(payload) : deactivateFn({});
  // };

  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Account Info</h5>
          <div className='flex'>
            {profileData?.account_type == 'ORGANIZATION' && (
              <button className='go-btn-primary mr-2'>
                <EditIcon
                  onClick={() => {
                    setEdit(true);
                  }}
                />
              </button>
            )}
          </div>
        </header>
        <div className='p-6'>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Organization Name</div>
            <div className='go-textblacknormal-16 font-medium'>{textCheckFn(data?.business_name)}</div>
          </div>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Account Type</div>
            <div className='go-textblacknormal-16 font-medium'>{accountTypeFormatFn(data?.account_type)}</div>
          </div>
          {/* {data?.account_type != 'ORGANIZATION_MEMBER' &&
            (profileData?.status == 'ACTIVE' ? (
              <div className='flex'>
                <Link to='#' className='go-link-16 cursor-pointer' onClick={(e) => deactivateModalFn(e, true)}>
                  Deactivate Account
                </Link>
              </div>
            ) : (
              <div className='flex items-center justify-between'>
                <Link
                  to='#'
                  className='go-textwhite-14 cursor-pointer rounded-full bg-[#548ff7] py-2 px-3 text-center '
                  onClick={(e) => showReactivateModalFn(e, true)}
                >
                  Activate Your Account
                </Link>
                <span className='go-label-13'>Your account is not active</span>
              </div>
            ))} */}
        </div>
      </div>
    </div>
  );
}
