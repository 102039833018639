import React, { useEffect } from 'react';
import ArrowRight from '../../components/Icons/ArrowRight';
import DeleteIconBlack from '../../components/Icons/DeleteIconBlack';
import CreditCard from '../../components/Icons/CreditCard';
import { amountCheckFn, numberCheckFn, textCheckFn } from '../../utils/commonUtil';
export default function PaymentDetails({ invoice, setFinalAmountCharged }) {
  const [amountCharged, setAmountCharged] = React.useState(invoice?.remaining_balance);
  const amountChargedValidateFn = (val) => {
    val = val.replace(/[^0-9.]/g, '');
    if (val < 0) {
      setAmountCharged(0);
    } else if (val > invoice?.remaining_balance) {
      setAmountCharged(invoice?.remaining_balance);
    } else {
      setAmountCharged(+val);
    }
  };
  useEffect(() => {
    setFinalAmountCharged(amountCharged);
  }, [amountCharged]);

  return (
    <div>
      <div className='flex justify-center'>
        <div className='my-6 w-3/5 items-center rounded-xl bg-[#FFE6DA] py-5'>
          <p className='go-textblack-15  text-center'>Payment Details</p>
          <table className='w-full'>
            <tbody>
              <tr>
                <td className='go-label-13 w-1/2 py-1.5 text-right'>Total:</td>
                <td className='go-textblack-13 py-1.5 pl-5'>{amountCheckFn(invoice?.balance)}</td>
              </tr>
              <tr>
                <td className='go-label-13 w-1/2 py-1.5 text-right'>Gift Card Applied:</td>
                <td className='go-textblack-13 py-1.5 pl-5'>{amountCheckFn(invoice?.total_applied_gift_cards)}</td>
              </tr>
              <tr>
                <td className='go-label-13 w-1/2 py-1.5 text-right'>Remaining Amount:</td>
                <td className='go-textblack-13 py-1.5 pl-5'>{amountCheckFn(invoice?.remaining_balance)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='my-5 flex items-center rounded-xl border border-indigo-50 bg-[#f9fbff] '>
        <div className='h-28 w-full items-center py-5 text-center'>
          <p className='go-label-15'>Amount Charged</p>
          {/* <p className='go-textblackbold-32'>$50.00</p>  */}
          <div className='relative'>
            <span className='go-textblackbold-32'>$</span>
            <input
              type='text'
              className=' go-textblackbold-32 w-36 border-none border-transparent bg-transparent px-1 focus:border-0 focus:border-none focus:border-transparent focus:ring-0 focus-visible:border-none active:border-none'
              placeholder='0.00'
              value={amountCharged}
              onChange={(e) => amountChargedValidateFn(e.target.value)}
            />
            <DeleteIconBlack classes='absolute right-[32%] top-[27%]' onClick={() => setAmountCharged(0)} />
          </div>
          {/* {amountCharged > invoice?.remaining_balance && (
            <p className=' text-red-600'> Amount is greater than remianing balance.</p>
          )} */}
        </div>
      </div>
      <div className='flex justify-center'>
        <div className='my-6 w-full items-center rounded-xl py-5'>
          <p className='go-textblack-15  text-center'>Payment Method</p>
          <div className='go-textblack-15  flex justify-center py-2 text-center'>
            <CreditCard />{' '}
            <span className='pl-1'>
              Visa **** {numberCheckFn(invoice?.billing_address?.billing_details?.default_payment_method?.card_last4)}
            </span>
          </div>
          <table className='w-full'>
            <tbody>
              {/* <tr>
                <td className='go-label-13 w-1/2 py-1.5 text-right'>Name on Card:</td>
                <td className='go-textblack-13 py-1.5 pl-5'>
                  {textCheckFn(invoice?.billing_address?.billing_details?.default_payment_method?.name)}
                </td>
              </tr> */}
              <tr>
                <td className='go-label-13 w-1/2 py-1.5 text-right'>Card Ending:</td>
                <td className='go-textblack-13 py-1.5 pl-5'>
                  {numberCheckFn(invoice?.billing_address?.billing_details?.default_payment_method?.card_last4)}
                </td>
              </tr>

              <tr>
                <td className='go-label-13 w-1/2 py-1.5 text-right'>Billing Zip Code:</td>
                <td className='go-textblack-13 py-1.5 pl-5'>{numberCheckFn(invoice?.billing_address?.postal_code)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
