import React, { useState } from 'react';
import RatingStars from './RatingStars';
import no_picture from '../images/no-profile-picture.png';
import rising_star from '../../src/icons/rising-star.svg';
import { HeartIcon } from '@heroicons/react/20/solid';
import { generateInitials } from './Utils';
export default function ProviderProfile({ provider, type = 'single', readonly = false }) {
  const [ratingValue, setRatingValue] = useState(provider?.rating);
  const handleRating = () => {
    setRatingValue(rate);
  };
  return (
    <div className={'mb-2 items-center ' + (type === 'single' ? ' flex min-h-[80px]' : ' min-h-[180px] flex-row')}>
      <div className={'flex ' + (type === 'single' ? 'w-1/2 justify-end' : 'w-full justify-center')}>
        {provider?.profile_image ? (
          <img
            className={' h-[80px] w-[80px] rounded-full ' + (type === 'single' ? 'mr-4' : 'mb-4')}
            src={provider?.profile_image ? provider?.profile_image.absolute_url : no_picture}
            alt={provider?.first_name}
          />
        ) : (
          <div className='mb-4 h-[80px] w-[80px] rounded-full bg-[#FD56AD] p-2'>
            <span className='flex h-full w-full items-center justify-center text-3xl font-bold text-white'>
              {generateInitials(provider?.first_name + ' ' + provider?.last_name)}
            </span>
          </div>
        )}
      </div>
      <div className='mb-3 flex justify-center '>
        <div className='flex w-8/12'>
          <div className='ml-1 flex grow flex-col'>
            <div className={'text-client-text text-center font-medium'}>
              {provider?.first_name} {provider?.last_name}
            </div>
            <div
              className='text-client-text flex justify-center text-sm'
              style={{ visibility: provider?.isPreferred ? 'visible' : 'hidden' }}
            >
              {provider?.isPreferred && <HeartIcon className='text-client-text mr-1 h-5 w-5' />} Preferred Provider
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-center'>
        {provider?.delivered_orders == 1 ? (
          <div className='text-client-text text-center text-sm'>{provider?.delivered_orders} Order Delivered</div>
        ) : provider?.delivered_orders > 1 ? (
          <div className='text-client-text text-center text-sm'>{provider?.delivered_orders} Orders Delivered</div>
        ) : (
          <div className='text-client-text mb-1 flex justify-center text-center'>
            <img src={rising_star} alt='tick' className='mr-2 h-5 w-5' />
            <span>Rising Talent</span>
          </div>
        )}
        {provider?.average_ratings > 0 && provider?.delivered_orders > 0 ? (
          <div className='flex justify-center'>
            <RatingStars onClick={() => handleRating} ratingValue={provider?.average_ratings} readonly={readonly} />
          </div>
        ) : provider?.average_ratings == 0 && provider?.delivered_orders > 0 ? (
          <div className='text-client-text mb-1 text-center text-sm'>Be the first to Review</div>
        ) : (
          <div className='text-client-text mb-1 text-center text-sm'>Ready for Deliveries</div>
        )}
      </div>
    </div>
  );
}
