import { GET_LANDING_PAGE_DATA, GET_LANDING_PAGE_DATA_LOADING } from '../ActionsTypes';

const getLandingPageDataAction = (data) => ({
  type: GET_LANDING_PAGE_DATA,
  payload: data,
});
const setLandingPageLoadingDataAction = (data) => ({
  type: GET_LANDING_PAGE_DATA_LOADING,
  payload: data,
});
export { getLandingPageDataAction, setLandingPageLoadingDataAction };
