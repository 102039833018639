import { GET_PROFILE_DATA, PROFILE_LOADING, SET_PROFILE_DATA } from '../ActionsTypes';
import { getRequest } from '../../utils/axios';
import toast from 'react-hot-toast';
import { getProfileDataAction, setProfileLoadingAction } from '../Actions/ProfileActions';
const initialState = {
  profileData: null,
  profileLoading: false,
};
export const ProfileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROFILE_DATA:
      return { ...state, profileData: payload };
    case PROFILE_LOADING:
      return { ...state, profileLoading: payload };
    case SET_PROFILE_DATA:
      return { ...state, profileData: payload };
    default:
      return state;
  }
};

export const getProfileDataApi =
  (first = false) =>
  async (dispatch) => {
    try {
      dispatch(setProfileLoadingAction(true));
      const response_data = await getRequest(`/accounts/client/self`);
      if (response_data?.status === 200) {
        dispatch(getProfileDataAction(response_data?.data));
        dispatch(setProfileLoadingAction(false));
        if (first) {
          if (response_data?.data?.account_type == 'ORGANIZATION') {
            toast.success(`Welcome back ${response_data?.data?.org_admin_first_name}!`);
          } else {
            toast.success(`Welcome back ${response_data?.data?.first_name}!` || 'Welcome back!');
          }
        }
      }
      return response_data;
    } catch (error) {
      dispatch(setProfileLoadingAction(false));
      toast.error(error?.response?.data?.error?.message || 'Something went wrong');
      return error?.response?.data;
    }
  };
