import { GET_ALL_PROVIDERS, GET_ALL_PROVIDERS_LOADING, SELECTED_PROVIDERS } from '../ActionsTypes';

const setAllProvidersAction = (data) => ({
  type: GET_ALL_PROVIDERS,
  payload: data,
});
const setAllProvidersLoadingAction = (data) => ({
  type: GET_ALL_PROVIDERS_LOADING,
  payload: data,
});
const setSelectedProviderAction = (data) => ({
  type: SELECTED_PROVIDERS,
  payload: data,
});

export { setAllProvidersAction, setAllProvidersLoadingAction, setSelectedProviderAction };
