import React from 'react';
import sort_none_icon from '../icons/sort-none.svg';
import filter_icon from '../icons/filter.svg';
import { isEmpty } from 'lodash';
import FilterDropdown from './FilterDropdown';

export default function TableFilter({ text, align = 'text-left', filters = [], onchange }) {
  return (
    <div className={align}>
      <div className='flex items-center  whitespace-nowrap px-2.5 py-2 '>
        {!isEmpty(filters) && <FilterDropdown icon={filter_icon} filters={filters} onapply={(x) => onchange(x)} />}
        <span>{text}</span>
        <img src={sort_none_icon} alt='sort none' className='h-6 w-6' />
      </div>
    </div>
  );
}
