import React, { useEffect, useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import PhoneNumbersList from '../../../components/PhoneNumbersList';
import Loader from '../../../components/Loader';
import { useSelector } from 'react-redux';
import { amountCheckFn, numberCheckFn, textCheckFn } from '../../../utils/commonUtil';

export default function Cards({ setEditPayment, setEditBilling, billingDetails, loading }) {
  const paymentType = billingDetails?.addresses[0]?.billing_details?.default_payment_method_type;
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
  const { profileData } = useSelector((state) => state.profile);
  const [currentAddress, setCurrentAddress] = useState({});
  const checkKey = (obj, key) => {
    return obj?.hasOwnProperty(key);
  };
  const findSelectedAddressFn = () => {
    const temp = billingDetails?.addresses?.find((item) => selectedAddress?.id === item.id && item);
    setCurrentAddress(temp);
  };
  useEffect(() => {
    findSelectedAddressFn();
  }, [billingDetails, selectedAddress]);

  return (
    <>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Payment Info</h5>
          <div className='flex'>
            <button className='go-btn-primary mr-2'>
              <EditIcon
                onClick={() => {
                  setEditPayment(true);
                }}
              />
            </button>
          </div>
        </header>
        {loading ? (
          <Loader classes='h-40' />
        ) : (
          <div className='p-6'>
            {paymentType == 'CARD' || paymentType == 'STRIPE' ? (
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Credit Card</div>
                <div className='go-textblacknormal-16 font-medium'>
                  {' '}
                  {billingDetails?.addresses[0]?.billing_details?.default_payment_method?.card_last4
                    ? `**** **** **** ${billingDetails?.addresses[0]?.billing_details?.default_payment_method?.card_last4}`
                    : '-'}
                </div>
              </div>
            ) : (
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Paying Via</div>
                <div className='go-textblacknormal-16 font-medium'>
                  {paymentType == 'CHECK' ? 'Check' : paymentType == 'ACH' ? 'ACH' : ''}
                  {paymentType == 'CHECK' && (
                    <span className=' go-textblacknormal-14'>
                      {' '}
                      - (<span className=' font-bold'>mail to: </span>PO Box 693 Centerburg, OH 43011){' '}
                    </span>
                  )}
                </div>
              </div>
            )}

            <div className='mb-4 flex-row space-y-1.5'>
              <div className='go-label-16'>Gift Card Balance</div>
              <div className='go-textblacknormal-16 font-medium'>
                {amountCheckFn(billingDetails?.available_gift_card_balance)}
              </div>
            </div>
          </div>
        )}
      </div>
      {profileData?.account_type != 'INDIVIDUAL' && checkKey(currentAddress?.billing_details, 'payment_terms') && (
        <div className='z-0 rounded-lg bg-white '>
          <header className='flex items-center border-b border-gray-100 p-6'>
            <h5 className='go-textnormal-18 my-1 flex-1'>Billing Terms</h5>
          </header>
          {loading ? (
            <Loader classes='h-40' />
          ) : (
            <div>
              <div className='flex px-6 pt-6'>
                <div className='mb-4 w-1/2 flex-col space-y-1.5'>
                  <div className='go-label-16'>Billing Frequency</div>
                  <div className='go-textblacknormal-16 font-medium'>
                    {textCheckFn(currentAddress?.billing_details?.invoice_frequency)}
                  </div>
                </div>
                <div className='mb-4 w-1/2 flex-col space-y-1.5'>
                  <div className='go-label-16'>Billing Day</div>
                  <div className='go-textblacknormal-16 font-medium'>
                    {textCheckFn(currentAddress?.billing_details?.invoice_day_of_week)}
                  </div>
                </div>
                {/* <div className='mb-4 w-1/2 flex-col space-y-1.5'>
                  <div className='go-label-16'>Next Billing Date</div>
                  <div className='go-textblacknormal-16 font-medium'>
                    {textCheckFn(currentAddress?.billing_details?.invoice_day_of_month)}
                  </div>
                </div> */}
              </div>
              <div className='flex px-6 pb-6'>
                <div className='mb-4 w-1/2 flex-col space-y-1.5'>
                  <div className='go-label-16'>Payment Due</div>
                  <div className='go-textblacknormal-16 font-medium'>
                    {textCheckFn(currentAddress?.billing_details?.payment_terms?.split('_')[0])}{' '}
                    {currentAddress?.billing_details?.payment_terms?.split('_')[1]}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {profileData?.account_type != 'INDIVIDUAL' && (
        <div className='z-0 rounded-lg bg-white '>
          <header className='flex items-center border-b border-gray-100 p-6'>
            <h5 className='go-textnormal-18 my-1 flex-1'>Billing Contact</h5>
            {profileData?.account_type != 'ORGANIZATION_MEMBER' && (
              <div className='flex'>
                <button className='go-btn-primary mr-2'>
                  <EditIcon
                    onClick={() => {
                      setEditBilling(true);
                    }}
                  />
                </button>
              </div>
            )}
          </header>
          {loading ? (
            <Loader classes='h-40' />
          ) : (
            <div>
              <div className='flex px-6 pt-6'>
                <div className='mb-4 w-1/2 flex-col space-y-1.5'>
                  <div className='go-label-16'>Name</div>
                  <div className='go-textblacknormal-16 font-medium'>
                    {billingDetails?.billing_contact_first_name + ' ' + billingDetails?.billing_contact_last_name}
                  </div>
                </div>

                <div className='mb-4 w-1/2 flex-col space-y-1.5'>
                  <div className='go-label-16'>Mobile</div>
                  {billingDetails?.billing_contact_phone_number?.length > 0 ? (
                    <PhoneNumbersList phoneNumbers={billingDetails?.billing_contact_phone_number} />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className=' px-6 pb-6'>
                <div className='mb-4 flex-row space-y-1.5'>
                  <div className='go-label-16'>Email</div>
                  {billingDetails?.billing_contact_email ? `${billingDetails?.billing_contact_email}` : ''}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
