import React from 'react';

function PaginationNumeric({ data, onPaginationChange, onPaginationNext, onPaginationPrev }) {
  const range = (n) => {
    return [...new Array(n + 1).keys()].slice(1, n + 1);
  };
  const total_pages = Math.ceil(data?.total_count / data?.page_size) * 1;
  const total_range = range(total_pages);
  return (
    <div className='flex justify-center'>
      <nav className='flex' role='navigation' aria-label='Navigation'>
        <div className='mr-2'>
          <a
            href='#'
            className={
              'inline-flex items-center justify-center rounded border border-slate-200 bg-white px-2.5 py-2 leading-5 text-slate-600 shadow-sm hover:bg-indigo-500 hover:text-white' +
              (data?.current_page === 1 ? ' cursor-not-allowed opacity-50' : '')
            }
            onClick={() => onPaginationPrev()}
          >
            <span className='sr-only'>Previous</span>
            <wbr />
            <svg className='h-4 w-4 fill-current' viewBox='0 0 16 16'>
              <path d='M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z' />
            </svg>
          </a>
        </div>
        <ul className='inline-flex -space-x-px text-sm font-medium shadow-sm'>
          {total_range?.map((page) => {
            return (
              <li key={page} onClick={() => onPaginationChange(page)}>
                <a
                  href='#0'
                  className={
                    'inline-flex items-center justify-center rounded border border-slate-200 bg-white px-3.5 py-2 leading-5 hover:bg-indigo-500 hover:text-white ' +
                    (data?.current_page === page ? 'text-client-link' : 'text-slate-600')
                  }
                >
                  {page}
                </a>
              </li>
            );
          })}
        </ul>
        <div className='ml-2'>
          <a
            href='#'
            className={
              'inline-flex items-center justify-center rounded border border-slate-200 bg-white px-2.5 py-2 leading-5 text-slate-600 shadow-sm hover:bg-indigo-500 hover:text-white' +
              (data?.current_page === total_pages ? ' cursor-not-allowed opacity-50' : '')
            }
            onClick={() => onPaginationNext()}
          >
            <span className='sr-only'>Next</span>
            <wbr />
            <svg className='h-4 w-4 fill-current' viewBox='0 0 16 16'>
              <path d='M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z' />
            </svg>
          </a>
        </div>
      </nav>
    </div>
  );
}

export default PaginationNumeric;
