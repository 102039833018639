import { isEmpty } from 'lodash';

const filterGenarator = (currentState, type, data) => {
  let filter = currentState;
  if (type === 'status') {
    if (isEmpty(data)) {
      filter = currentState.filter((item) => item.columns[0] !== 'status');
    } else {
      const index = currentState.findIndex((item) => item.columns[0] === 'status');
      if (index === -1) {
        filter.push({
          columns: ['status'],
          operation: '=',
          value: data,
        });
      } else {
        filter[index].value = data;
      }
    }
  }
  if (type === 'due_on') {
    if (isEmpty(data)) {
      filter = currentState.filter((item) => item.columns[0] !== 'due_on');
    } else {
      const index = currentState.findIndex((item) => item.columns[0] === 'due_on');
      if (index === -1) {
        filter.push({
          columns: ['due_on'],
          operation: '=',
          value: data,
        });
      } else {
        filter[index].value = data;
      }
    }
  }
  return filter;
};

export default filterGenarator;
