const selectedServices = [
  {
    id: 1,
    service: 'Wash and Fold',
    quantity: 0,
    price: 86.5,
  },
  {
    id: 2,
    service: 'Dry Cleaning',
    quantity: 0,
    price: 26.5,
  },
  {
    id: 3,
    service: 'Ironing',
    quantity: 0,
    price: 36.5,
  },
  {
    id: 4,
    service: 'Wash and Fold',
    quantity: 0,
    price: 4.5,
  },
  {
    id: 5,
    service: 'Dry Cleaning',
    quantity: 0,
    price: 44.5,
  },
  {
    id: 6,
    service: 'Ironing',
    quantity: 0,
    price: 22.5,
  },
];

 const phoneTypes = [
   {
     id: 0,
     value: 'MOBILE',
     displayValue: 'Mobile',
   },
   {
     id: 1,
     value: 'LANDLINE',
     displayValue: 'Landline',
   },
 ];

 const preferencesData = {
   detergent: [
     {
       id: 1,
       value: 'Hypoallergenic',
     },
     {
       id: 2,
       value: 'Scented',
     },
     {
       id: 3,
       value: 'Unscented',
     },
   ],
   water_temperature: [
     { id: 1, value: 'Hot' },
     { id: 2, value: 'Warm' },
     { id: 3, value: 'Cold' },
   ],
   pickup_and_delivery: [
     { id: 1, value: 'Porch or Outside of Entrance' },
     { id: 2, value: 'In person Handoff' },
     { id: 3, value: 'Other Secure Location' },
     { id: 4, value: 'Front Desk or Reception Area' },
   ],
   softener: [
     {
       id: 1,
       value: 'Yes',
     },
     {
       id: 2,
       value: 'No',
     },
   ],
   dry_temperature: [
     { id: 1, value: 'Hot' },
     { id: 2, value: 'Warm' },
     { id: 3, value: 'Cold' },
   ],
   additional_preferences: 'No softener, no scented detergent',
 };

 export { selectedServices, phoneTypes, preferencesData };
