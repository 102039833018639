import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { titleCaseFormatter } from '../utils/stringFormatters';
import { toast } from 'react-hot-toast';
import DropdownPhoneType from './DropdownPhoneType';
import { phone_mask_US_format } from '../utils/commonUtil';
export default function PhoneNumbersList({ phoneNumbers }) {
  return (
    <div>
      <div className='mb-4 flex-row space-y-1.5'>
        <div className='go-label-16'>{titleCaseFormatter(phoneNumbers[0]?.type)}</div>
        <div className='go-textblacknormal-16 font-medium'>
          {phone_mask_US_format(phoneNumbers[0]?.phone_number)?.formatted}
        </div>
      </div>
    </div>
  );
}
