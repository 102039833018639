import React, { useEffect, useState } from 'react';
import pl_onetime from '../../../images/pl_onetime.png';
import pl_subscription from '../../../images/pl_subscription.png';
import lc_onetime from '../../../images/lc_onetime_new.png';
import lc_subscription from '../../../images/lc_subscription_new.png';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDataAction, setOrderTypeAction } from '../../../redux/Actions/CreateOrderActions';
import { resetCreateOrderFlow } from '../../../redux/Reducers/CreateOrderReducer';

export default function OrderType() {
  const dispatch = useDispatch();
  const { orderType, orderRepeatFrequency } = useSelector((state) => state.createNewOrder);
  const setOrderType = (type) => {
    dispatch(resetCreateOrderFlow());
    dispatch(setOrderTypeAction(type));
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div>
      <div className='mt-10 grid grid-cols-12 gap-6'>
        <div
          className={
            ' border-3 col-span-full cursor-pointer rounded-xl bg-white xl:col-span-5' +
            (orderType === 'oneTime' ? ' border-client-300 ' : ' border-white')
          }
          onClick={() => setOrderType('oneTime')}
        >
          <div className='flex h-52 flex-col p-5'>
            <header className='flex items-start'>
              <div className='flex'>
                <input
                  type='checkbox'
                  name='onetime'
                  id='onetime'
                  checked={orderType === 'oneTime'}
                  onChange={() => setOrderType('oneTime')}
                  className={classNames(
                    orderType === 'oneTime' ? 'text-client-300' : 'text-slate-200',
                    ' h-5 w-5 rounded-full ring-0'
                  )}
                />
              </div>
              {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                <img className='mx-5' src={lc_onetime} width='80' height='80' />
              ) : (
                <img className='mx-5' src={pl_onetime} width='80' height='80' />
              )}
              <div className='grow'>
                <div className='mb-0.5 items-start justify-between sm:flex'>
                  <div className='mb-2 items-center sm:mb-0 xl:flex'>
                    <h3 className='go-heading-20'>One time</h3>
                  </div>
                </div>
                <div className='go-label-16'>A one time pickup</div>
              </div>
            </header>
          </div>
        </div>
        <div
          className={
            ' border-3 col-span-full cursor-pointer rounded-xl bg-white  xl:col-span-5' +
            (orderType === 'subscription' ? ' border-client-300 ' : ' border-white')
          }
          onClick={() => setOrderType('subscription')}
        >
          <div className='flex flex-col p-5'>
            <header className='flex items-start'>
              <div className='flex'>
                <input
                  type='checkbox'
                  name='onetime'
                  id='onetime'
                  checked={orderType === 'subscription'}
                  onChange={() => setOrderType('subscription')}
                  className={classNames(
                    orderType === 'subscription' ? 'text-client-300' : 'text-slate-200',
                    ' h-5 w-5 rounded-full ring-0'
                  )}
                />
              </div>
              {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                <img className='mx-5' src={lc_subscription} width='90' height='90' />
              ) : (
                <img className='mx-5' src={pl_subscription} width='80' height='80' />
              )}
              <div className='grow'>
                <div className='mb-0.5 items-start justify-between sm:flex'>
                  <div className='mb-2 items-center sm:mb-0 xl:flex'>
                    <h3 className='go-heading-20'>Subscription</h3>
                  </div>
                </div>
                {orderType === 'subscription' ? (
                  <div className='go-label-16'>Select frequency of pickups</div>
                ) : (
                  <div className='go-label-16'>A recurring order</div>
                )}
                {orderType === 'subscription' && (
                  <div className='mt-3 flex flex-row flex-wrap'>
                    {[
                      { name: 'Weekly', value: 'WEEK' },
                      { name: 'Every-other-week', value: 'BIWEEKLY' },
                      { name: 'Monthly', value: 'MONTH' },
                    ].map((item, idx) => (
                      <div key={idx} className='mr-3 mb-2'>
                        <div
                          key={idx + 'sub'}
                          className={
                            'mr-1 w-full rounded-lg border-2 bg-[#f5f5fb] p-1 text-center text-sm font-semibold ' +
                            ' ' +
                            classNames(
                              orderRepeatFrequency?.value === item?.value && orderType === 'subscription'
                                ? ' border-client-300'
                                : ' border-[#f5f5fb]'
                            )
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!orderRepeatFrequency?.value) {
                              dispatch(setOrderDataAction({ orderRepeatFrequency: { value: 'WEEK' } }));
                            } else {
                              dispatch(setOrderDataAction({ orderRepeatFrequency: { value: item?.value } }));
                            }
                          }}
                        >
                          {item?.name}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
}
