import React from 'react';

export default function NoData({ classes, text = 'No data available', bg = 'false' }) {
  return (
    <div className={`flex items-center justify-center rounded-lg ${classes} ` + (bg && 'bg-white')}>
      <div className='m-auto '>
        <div className='px-4 text-center'>
          <div className='go-textnormal-14 mb-6'>{text}</div>
        </div>
      </div>
    </div>
  );
}
