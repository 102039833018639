import React, { useState } from 'react';

function AccordionBasic(props) {
  const [open, setOpen] = useState(props.status);

  return (
    <div className='bg-portal-100 rounded-xl px-4 py-3'>
      <button
        className={`group mb-1 flex w-full items-center justify-between ${open && 'border-b border-gray-200'}`}
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <div className='text-left text-sm font-semibold text-slate-800 '>{props.title}</div>
        <svg
          className={`text-portal-600 group-hover:text-portal-600 ml-3 h-8 w-8 shrink-0 fill-current ${
            open && 'rotate-180'
          }`}
          viewBox='0 0 32 32'
        >
          <path d='M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z' />
        </svg>
      </button>
      <div className={`text-sm text-black ${!open && 'hidden'}`}>{props.children}</div>
    </div>
  );
}

export default AccordionBasic;
