import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { titleCaseFormatter } from '../utils/stringFormatters';
import fastforward from '../../src/icons/fastforward.png';
import { isEmpty } from 'lodash';

export default function DropdownDateTime({
  dropdownValues,
  icon,
  prefix = '',
  onChange,
  type = 'text',
  defaultValue,
  placeholder = '',
}) {
  const [options, setOptions] = useState(dropdownValues || []);
  const [selected, setSelected] = useState(null);
  const onDropdownChange = (option) => {
    if (option?.id !== selected?.id) {
      setSelected(option);
      onChange(option);
    }
  };
  useEffect(() => {
    setOptions(dropdownValues);
  }, [dropdownValues]);
  // useEffect(() => {
  //   if (defaultValue) {
  //     onDropdownChange(defaultValue);
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className=' z-10'>
      <Listbox
        value={selected}
        onChange={(option) => onDropdownChange(option)}
        disabled={isEmpty(dropdownValues) ? true : false}
      >
        <div className=' relative mt-1'>
          <Listbox.Button className='go-textblacknormal-16 relative w-full cursor-default rounded border border-gray-200 bg-white py-2 pl-3 pr-12 text-left'>
            {/* <span className='block truncate'>
              {prefix}
              {type === 'text' ? titleCaseFormatter(selected?.value) : selected?.displayValue ?? selected?.value}
            </span> */}

            {selected?.displayValue?.split('for').length > 1 ? (
              <div className='flex'>
                <span className={`block truncate font-normal text-black`}>{`${
                  selected?.displayValue.split('for')[0]
                }`}</span>
                <span>
                  <img src={fastforward} alt='tick' className='mx-1.5 mt-1 h-4 w-4' />
                </span>
                <span className={`block truncate font-normal text-black`}>
                  {`Priority ${selected?.displayValue.split('for')[1]}`}
                </span>
              </div>
            ) : (
              <div className='flex'>
                <span className='block truncate'>
                  {type === 'text' ? titleCaseFormatter(selected?.value) : selected?.displayValue ?? selected?.value}
                </span>
              </div>
            )}
            <span className='bg-client-iconbg pointer-events-none absolute inset-y-0 right-0 m-0.5 flex items-center rounded p-2'>
              <span className='text-client-link h-5 w-5' aria-hidden='true'>
                {icon ? icon : <ChevronUpDownIcon />}
              </span>
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className='z-60 text-black-16 go-text-14 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {options?.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) =>
                    `${
                      item?.disabled ? 'cursor-not-allowed opacity-50 ' : 'cursor-default '
                    } relative cursor-default select-none border-b border-gray-200 py-2 pl-10 pr-4 last:border-0 ${
                      active ? 'bg-blue-50 text-blue-600' : 'text-gray-900'
                    }`
                  }
                  value={item}
                  disabled={item?.disabled}
                >
                  {({ selected }) =>
                    item?.displayValue?.split('for').length > 1 ? (
                      <>
                        <div className='flex'>
                          <span className={`block truncate text-black ${selected ? 'font-normal' : 'font-normal'}`}>
                            {`${item?.displayValue.split('for')[0]}`}
                          </span>
                          <span>
                            <img src={fastforward} alt='tick' className='mx-1.5 mt-1 h-4 w-4' />
                          </span>
                          <span className={`block truncate text-black ${selected ? 'font-normal' : 'font-normal'}`}>
                            {`Priority ${item?.displayValue.split('for')[1]}`}
                          </span>
                        </div>
                        {selected ? (
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600'>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <span className={`block truncate ${selected ? 'font-normal' : 'font-normal'}`}>
                          {item?.displayValue || item?.value}
                        </span>
                        {selected ? (
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600'>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )
                  }
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
