import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ClientButton from '../../../components/ClientButton';
import { useDispatch } from 'react-redux';
import { updateProfileApi } from '../../../redux/Reducers/MyAccountReducer';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { accountTypeFormatFn } from '../../../utils/commonUtil';

export default function EditAccountInfo({ setEdit, data }) {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm();
  const onSubmit = (newData, e) => {
    if (!isDirty) {
      toast.error('No changes made!');
      return;
    }
    setloading(true);
    const create = updateProfileApi(newData);
    create?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setloading(false);
        toast.success('Account Updated Successfully');
        dispatch(getProfileDataApi(false));
        setEdit(false);
      } else {
        setloading(false);
        toast.error(res?.error?.message || 'Something went wrong');
      }
    });
  };

  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Edit Account Info</h5>
        </header>
        {loading ? (
          <Loader text='processing...' classes='h-40' />
        ) : (
          <div className='p-6'>
            <div className='flex space-x-2'>
              <div className='relative mb-4 w-full flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  Organization Name
                </label>
                <input
                  name='business_name'
                  className={`go-inputtext-16`}
                  {...register('business_name', { required: true })}
                  type='text'
                  defaultValue={data?.business_name}
                />
                {errors.business_name?.message && (
                  <p className='go-textnormal-14 text-red-500'>{errors.business_name?.message}</p>
                )}
              </div>
              {/* <div className='relative mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  Org First Name
                </label>
                <input
                  name='org_admin_first_name'
                  className={`go-inputtext-16`}
                  {...register('org_admin_first_name', { required: true })}
                  type='text'
                  defaultValue={data?.org_admin_first_name}
                />
                {errors.org_admin_first_name?.message && (
                  <p className='go-textnormal-14 text-red-500'>{errors.org_admin_first_name?.message}</p>
                )}
              </div>
              <div className='relative mb-4 flex-row space-y-1.5'>
                <label className='go-label-16 mb-1 block' htmlFor='label'>
                  Org Last Name
                </label>
                <input
                  name='org_admin_last_name'
                  className={`go-inputtext-16`}
                  {...register('org_admin_last_name', { required: true })}
                  type='text'
                  defaultValue={data?.org_admin_last_name}
                />
                {errors.org_admin_last_name?.message && (
                  <p className='go-textnormal-14 text-red-500'>{errors.org_admin_last_name?.message}</p>
                )}
              </div> */}
            </div>
            <div className='mb-4 flex-row space-y-1.5'>
              <div className='go-label-16'>Account Type</div>
              <input
                id='label'
                className='go-inputtext-16 disabled:bg-indigo-50'
                type='text'
                defaultValue={accountTypeFormatFn(data?.account_type)}
                disabled
              />
            </div>
          </div>
        )}

        <div className='grid gap-1 border-t border-gray-100 p-5'>
          <ClientButton
            variant='primary'
            text='Save'
            classes='mx-2'
            onClick={(e) => {
              handleSubmit(onSubmit)(e);
            }}
          />
          <ClientButton variant='secondary' text='Cancel' classes='mr-2' onClick={() => setEdit(false)} />
        </div>
      </div>
    </div>
  );
}
