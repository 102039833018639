import axios from 'axios';
import { ORDER_FILTERS, ORDERS_PAGINATION, GET_ORDER_PREFERENCES, PREFERENCES_LOADING } from '../ActionsTypes';
import { getRequest, postRequest, putRequest } from '../../utils/axios';
import toast from 'react-hot-toast';
import {
  setGetOrderPreferencesAction,
  setMyOrdersPaginationAction,
  setPreferencesLoadingAction,
} from '../Actions/MyOrdersActions';
import { setSelectedProviderAction } from '../Actions/ProvidersActions';
import moment from 'moment';
const initialState = {
  orderPreferences: null,
  preferencesLoading: false,
};
export const MyAccountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDER_PREFERENCES:
      return { ...state, orderPreferences: payload };
    case PREFERENCES_LOADING:
      return { ...state, preferencesLoading: payload };
    default:
      return state;
  }
};

export const deactivateSubscription = async (subscription_id, data) => {
  try {
    const response_data = await putRequest(`/orders/client/subscription/${subscription_id}/cancel`, data);
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateOrderApi = async (order_id, data) => {
  const timeZone = moment.tz.guess();
  try {
    const response_data = await putRequest(`/orders/client/orders/${order_id}/update?time_zone=${timeZone}`, data);
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateProfileApi = async (data) => {
  try {
    const response_data = await putRequest(`/accounts/client/profile`, data);
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const resetPasswordApi = async (data) => {
  try {
    const response_data = await postRequest(`/auth/client/reset_password`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const updateOrderPreferencesApi = async (address_id, data) => {
  try {
    const response_data = await putRequest(`/accounts/client/orders/preferences/${address_id}`, data);
    return response_data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getOrderPreferencesApi = (address_id) => async (dispatch) => {
  try {
    dispatch(setPreferencesLoadingAction(true));
    const response_data = await getRequest('/accounts/client/orders/preferences/' + address_id);
    dispatch(setGetOrderPreferencesAction(response_data?.data ? response_data?.data : null));
    dispatch(
      setSelectedProviderAction(response_data?.data?.preferred_worker ? response_data?.data?.preferred_worker : null)
    );
    dispatch(setPreferencesLoadingAction(false));
    return response_data;
  } catch (error) {
    dispatch(setPreferencesLoadingAction(false));
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
