import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Landing from '../pages/Landing';

const AuthGuard = () => {
  const authUser = sessionStorage.getItem('token');

  if (authUser) {
    return <Landing />;
  } else {
    window.location = '/signin';
  }
};

export default AuthGuard;
