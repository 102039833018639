import React, { useEffect, useState } from 'react';
import DropdownDateTime from '../../../components/DropdownDateTime';
import { ClockIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { getPickupTimeApi, getDeliveryTimeApi } from '../../../redux/Reducers/CreateOrderReducer';
import one_time from '../../../images/onetime.png';
import { getDifference, utcTimeToLocalTime, dateFn } from '../../../utils/dateTimeFormatter';
import calendar from '../../../../src/icons/icons-calendar@3x.png';
import clock from '../../../../src/icons/icons-clock@3x.png';
import bolt from '../../../../src/icons/power.svg';
import { isEmpty } from 'lodash';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import Loader from '../../../components/Loader';
import { toast } from 'react-hot-toast';
import NoData from '../../../components/NoData';
import { amountCheckFn, formatAndSortStartTimes, subTypeText, timeIdGenarate } from '../../../utils/commonUtil';
import moment from 'moment';
import SelectableTiles from '../../../components/SelectableTiles';
export default function PickupDelivery() {
  const dispatch = useDispatch();
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
  const {
    orderType,
    orderProvider,
    availableSchedules,
    orderTimeSlots,
    orderRepeatFrequency,
    selectedServices,
    orderServiceAddress,
  } = useSelector((state) => state.createNewOrder);

  const [repeatfrequencyData, setRepeatfrequencyData] = useState([
    {
      id: 1,
      value: 'WEEK',
      displayValue: 'Week',
    },
    {
      id: 2,
      value: 'BIWEEKLY',
      displayValue: 'Other Week',
    },
    {
      id: 3,
      value: 'MONTH',
      displayValue: 'Month',
    },
  ]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState(orderTimeSlots || {});
  const [availableDeliverySchedules, setAvailableDeliverySchedules] = useState({});

  // const [pickupDatesForOneTime, setPickupDatesForOneTime] = useState([]);
  // const [pickupDatesForSubscription, setPickupDatesForSubscription] = useState([]);
  // const [pickupTimes, setPickupTimes] = useState([]);
  const [deliveryDatesForOneTime, setDeliveryDatesForOneTime] = useState([]);
  const [deliveryDatesForSubscription, setDeliveryDatesForSubscription] = useState([]);
  const [deliveryTimes, setDeliveryTimes] = useState([]);
  const [datesLoading, setDatesLoading] = useState(false);
  // const genaratePickupDates = (times) => {
  //   const pickupDatesOneTime = Object.keys(times).map((date, idx) => {
  //     return {
  //       id: dateFn('YYYYMMDD', date),
  //       value: dateFn('type3', date),
  //       dateYYYYMMDD: dateFn('YYYY-MM-DD', date),
  //     };
  //   });
  //   const pickupDatesSubscription = Object.keys(times).map((date, idx) => {
  //     return {
  //       id: dateFn('YYYYMMDD', date),
  //       value: dateFn('type3', date),
  //       dateYYYYMMDD: dateFn('YYYY-MM-DD', date),
  //     };
  //   });
  //   if (orderType === 'subscription') {
  //     setPickupDatesForSubscription(pickupDatesSubscription || []);
  //   } else if (orderType === 'oneTime') {
  //     setPickupDatesForOneTime(pickupDatesOneTime || []);
  //   }
  // };
  const genarateDeliveryDates = (times) => {
    const deliveryDatesOneTime = Object.keys(times)?.map((date, idx) => {
      return {
        id: dateFn('YYYYMMDD', date),
        value: dateFn('type3', date),
        dateYYYYMMDD: dateFn('YYYY-MM-DD', date),
      };
    });
    const deliveryDatesSubscription = Object.keys(times)?.map((date, idx) => {
      const diffDays = getDifference(selectedTimeSlots?.first_pickup_date, date);
      return {
        id: dateFn('YYYYMMDD', date),
        daysAfter: diffDays,
        dateYYYYMMDD: dateFn('YYYY-MM-DD', date),
        value: diffDays === 0 ? `Same-Day` : `${diffDays} days after pickup (${dateFn('type4', date)})`,
      };
    });
    if (orderType === 'subscription') {
      setDeliveryDatesForSubscription(deliveryDatesSubscription || []);
    } else if (orderType === 'oneTime') {
      setDeliveryDatesForOneTime(deliveryDatesOneTime || []);
    }
  };

  // const onPickupDateChange = (date) => {
  //   if (date) {
  //     const day = dateFn('day', date)?.toLowerCase();
  //     const times = availableSchedules?.[date]?.map((item) => {
  //       return item?.weekday?.toLowerCase() === day && item;
  //     });
  //     const YYMMDDDate = moment(date).format('YYYY-MM-DD');
  //     const pickupTimes = times?.map((time, idx) => {
  //       return {
  //         value: `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
  //           `${YYMMDDDate}T${time?.end_time}`
  //         )}`,
  //         default: {
  //           start_time: time?.start_time,
  //           end_time: time?.end_time,
  //         },
  //         id: timeIdGenarate(YYMMDDDate, time?.start_time),
  //       };
  //     });
  //     setPickupTimes(pickupTimes || []);
  //   }
  // };
  const onDeliveryDateChange = (date) => {
    if (date) {
      const day = dateFn('day', date)?.toLowerCase();
      const times = availableDeliverySchedules?.[date]?.map((item) => {
        return item?.weekday?.toLowerCase() === day && item;
      });
      const YYMMDDDate = moment(date).format('YYYY-MM-DD');
      const deliveryTimes = times?.map((time, idx) => {
        return {
          value: `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
            `${YYMMDDDate}T${time?.end_time}`
          )}`,
          default: {
            start_time: time?.start_time,
            end_time: time?.end_time,
          },
          priority_fee: !isNaN(time?.priority_fee) && time?.priority_fee >= 0 && time?.priority_fee,
          is_rush: time?.is_rush && time?.is_rush,
          displayValue: genarateDisplayValueFn(YYMMDDDate, time),
          id: timeIdGenarate(YYMMDDDate, time?.start_time),
        };
      });
      setDeliveryTimes(deliveryTimes || []);
    }
  };
  const genarateDisplayValueFn = (YYMMDDDate, time) => {
    if (!time?.is_rush) {
      return `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
        `${YYMMDDDate}T${time?.end_time}`
      )}`;
    } else if (!isNaN(time?.priority_fee)) {
      if (time?.priority_fee === 0) {
        return `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
          `${YYMMDDDate}T${time?.end_time}`
        )}`;
      } else if (time?.priority_fee > 0) {
        return `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
          `${YYMMDDDate}T${time?.end_time}`
        )} for +${amountCheckFn(time?.priority_fee)}`;
      }
    } else {
      return `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
        `${YYMMDDDate}T${time?.end_time}`
      )}`;
    }
  };
  // const getPickupTimeFn = (provider, address) => {
  //   setDatesLoading(true);
  //   const response = getPickupTimeApi(provider, address);
  //   response.then((res) => {
  //     if (res?.status === 200 || res?.status === 201) {
  //       const data = formatAndSortStartTimes(res?.data || {});
  //       setDatesLoading(false);
  //       dispatch(setOrderDataAction({ availableSchedules: data || [] }));
  //       setSelectedTimeSlots((prevState) => ({
  //         ...prevState,
  //         pickup_date: Object.keys(data)?.[0],
  //         first_pickup_date: Object.keys(data)?.[0],
  //         pickup_start_time: data?.[Object.keys(data)?.[0]]?.[0]?.start_time,
  //         pickup_end_time: data?.[Object.keys(data)?.[0]]?.[0]?.end_time,
  //         selected_pickup_time_id: timeIdGenarate(
  //           Object.keys(data)?.[0],
  //           data?.[Object.keys(data)?.[0]]?.[0]?.start_time
  //         ),
  //       }));
  //     } else {
  //       setDatesLoading(false);
  //       toast.error(res?.message || 'Something went wrong');
  //     }
  //   });
  // };
  const getDeliveryTimeFn = async (date, type) => {
    setDatesLoading(true);
    const data = {
      items: selectedServices?.map((item) => {
        return {
          service_id: item?.service_id,
          quantity: item?.quantity,
        };
      }),
      pickup_date: orderType === 'oneTime' ? selectedTimeSlots?.pickup_date : selectedTimeSlots?.first_pickup_date,
      pickup_start_time: selectedTimeSlots?.pickup_start_time,
      type: type,
    };
    try {
      const response = await getDeliveryTimeApi(
        orderProvider ? orderProvider?.id : 'null',
        selectedAddress?.id,
        data,
        orderServiceAddress?.time_zone
      );
      if (response?.status === 200 || response?.status === 201) {
        setDatesLoading(false);
        const data = formatAndSortStartTimes(response?.data?.data || {});
        setAvailableDeliverySchedules(data || {});
        const firstKey = Object.keys(data)[0];
        setSelectedTimeSlots((prevState) => ({
          ...prevState,
          selected_dropoff_time_id: timeIdGenarate(firstKey, data?.[firstKey]?.[0]?.start_time),
          dropoff_start_time: data?.[firstKey]?.[0]?.start_time,
          dropoff_end_time: data?.[firstKey]?.[0]?.end_time,
          dropoff_date: firstKey,
        }));
        onPriorityfeeChange(data?.[firstKey]?.[0]);
        if (isEmpty(data)) {
          toast.error('No delivery times available for the selected pickup date and time');
          setDeliveryDatesForOneTime([]);
          setDeliveryTimes([]);
          setSelectedTimeSlots((prevState) => ({
            ...prevState,
            dropoff_date: null,
          }));
        }
      } else {
        setDatesLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    } catch (error) {
      setDatesLoading(false);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const onPriorityfeeChange = (time) => {
    dispatch(setOrderDataAction({ orderIsRush: time }));
  };

  // useEffect(() => {
  //   const providerid = orderProvider ? orderProvider?.id : null;
  //   if (orderTimeSlots?.pickup_date == null || orderTimeSlots?.pickup_date != selectedTimeSlots?.pickup_date) {
  //     getPickupTimeFn(providerid, selectedAddress?.id);
  //   }
  // }, [orderProvider]);

  // useEffect(() => {
  //   if (!isEmpty(availableSchedules)) {
  //     genaratePickupDates(availableSchedules);
  //   }
  // }, [availableSchedules]);

  useEffect(() => {
    if (selectedTimeSlots?.pickup_date || selectedTimeSlots?.first_pickup_date)
      getDeliveryTimeFn(
        orderType === 'oneTime' ? selectedTimeSlots?.pickup_date : selectedTimeSlots?.first_pickup_date,
        orderType === 'oneTime' ? 'ORDER' : 'SUBSCRIPTION'
      );
  }, [selectedTimeSlots?.selected_pickup_time_id]);

  useEffect(() => {
    if (!isEmpty(availableDeliverySchedules)) {
      genarateDeliveryDates(availableDeliverySchedules);
    }
  }, [availableDeliverySchedules]);

  // useEffect(() => {
  //   orderType === 'oneTime'
  //     ? onPickupDateChange(selectedTimeSlots?.pickup_date)
  //     : onPickupDateChange(selectedTimeSlots?.first_pickup_date);
  // }, [selectedTimeSlots?.pickup_date, selectedTimeSlots?.first_pickup_date]);

  useEffect(() => {
    availableDeliverySchedules &&
      selectedTimeSlots?.dropoff_date &&
      onDeliveryDateChange(orderType === 'oneTime' ? selectedTimeSlots?.dropoff_date : selectedTimeSlots?.dropoff_date);
  }, [availableDeliverySchedules, selectedTimeSlots?.dropoff_date]);

  useEffect(() => {
    if (!isEmpty(selectedTimeSlots)) {
      dispatch(setOrderDataAction({ orderTimeSlots: selectedTimeSlots || {} }));
    }
  }, [selectedTimeSlots]);

  return (
    <div className='z-0 col-span-full flex flex-col rounded-lg bg-white p-6 shadow-lg xl:col-span-8'>
      {/* <header className='flex items-center pb-3'>
        <h5 className='go-heading-18'>Choose Your Delivery</h5>
      </header>
      <hr className='go-hr-dashed '></hr> */}
      {/* <div className='my-1 mt-5 flex items-center'>
        <img src={fastforward} alt='tick' className='mr-2 h-4 w-4' />
        Priority Delivery gives you a faster turnaround for an additional fee.
      </div> */}
      {/* <div className='my-1 flex items-center'>
        <img src={standard} alt='tick' className='mr-2 h-3 w-4' />
        Standard Delivery is always free.
      </div> */}
      {orderType === 'oneTime' ? (
        <div className='mt-4 mb-4'>
          <span className='go-label-16'>Pickup </span>
          <div className='mb-2 mt-2 flex items-center'>
            <span className='mr-4 rounded-sm bg-[#EEF4FE] p-1'>
              <img src={calendar} alt='calendar' className='h-5 w-5 ' />
            </span>
            <span className='font-semibold text-black'>{dateFn('type4', selectedTimeSlots?.pickup_date)}</span>
          </div>
          <div className='flex items-center'>
            <span className='mr-4 rounded-sm bg-[#EEF4FE] p-1'>
              <img src={clock} alt='clock' className='h-5 w-5' />
            </span>
            <span className='font-semibold text-black'>
              {`${utcTimeToLocalTime(
                `${selectedTimeSlots?.pickup_date}T${selectedTimeSlots?.pickup_start_time}`
              )} - ${utcTimeToLocalTime(`${selectedTimeSlots?.pickup_date}T${selectedTimeSlots?.pickup_end_time}`)}`}
            </span>
          </div>
        </div>
      ) : (
        <div className='mt-4 mb-4'>
          <span className='go-label-16'>Pickup </span>
          <div className='mb-2 mt-2 flex items-center'>
            <span className='mr-4 rounded-sm bg-[#EEF4FE] p-1'>
              <img src={calendar} alt='calendar' className='h-5 w-5 ' />
            </span>
            <span className='font-semibold text-black'>{dateFn('type4', selectedTimeSlots?.first_pickup_date)}</span>
          </div>
          <div className='flex items-center'>
            <span className='mr-4 rounded-sm bg-[#EEF4FE] p-1'>
              <img src={clock} alt='clock' className='h-5 w-5' />
            </span>
            <span className='font-semibold text-black'>
              {`${utcTimeToLocalTime(
                `${selectedTimeSlots?.first_pickup_date}T${selectedTimeSlots?.pickup_start_time}`
              )} - ${utcTimeToLocalTime(
                `${selectedTimeSlots?.first_pickup_date}T${selectedTimeSlots?.pickup_end_time}`
              )}`}
            </span>
          </div>
        </div>
      )}
      {/* <p className=' font-medium'>Standard Delivery is always free. </p> */}

      {/* {orderType === 'subscription' && (
        <div className='grid gap-0 sm:gap-4 xl:grid-cols-2'>
          <div className='py-2 sm:py-4'>
            <label className='go-label-16'>Repeat Every</label>
            <DropdownDateTime
              dropdownValues={repeatfrequencyData}
              onChange={(val) => {
                dispatch(setOrderDataAction({ orderRepeatFrequency: val }));
              }}
              placeholder='Pick Order Frequency'
              defaultValue={{ value: orderRepeatFrequency?.value || 'Select' }}
              type='text'
            />
          </div>
        </div>
      )} */}
      {datesLoading ? (
        <Loader text='fetching dates...' classes='h-52' />
      ) : (
        //   isEmpty(availableSchedules) ? (
        //   <NoData text='Choose a different provider.' classes='h-52' />
        // ) :
        <div>
          {/* <div className='h- grid gap-0 sm:gap-4 xl:grid-cols-2'>
            {orderType === 'oneTime' ? (
              !isEmpty(pickupDatesForOneTime) && (
                <div className='py-2 sm:py-4'>
                  <label className='go-label-16'>Choose Your Pickup Date</label>
                  <DropdownDateTime
                    dropdownValues={pickupDatesForOneTime || []}
                    icon={<CalendarDaysIcon />}
                    onChange={(date) => {
                      setSelectedTimeSlots((prevState) => ({
                        ...prevState,
                        pickup_date: date?.dateYYYYMMDD,
                        pickup_start_time: availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time,
                        pickup_end_time: availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.end_time,
                        selected_pickup_time_id: timeIdGenarate(
                          date?.dateYYYYMMDD,
                          availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time
                        ),
                      }));
                    }}
                    placeholder='Pick a Date'
                    defaultValue={pickupDatesForOneTime?.find(
                      (item) => item?.id === dateFn('YYYYMMDD', selectedTimeSlots?.pickup_date)
                    )}
                    type='date'
                    disabled={isEmpty(pickupDatesForOneTime)}
                  />
                </div>
              )
            ) : (
              <div className='py-2 sm:py-4'>
                <label className='go-label-16'>Choose Your First Pickup Date</label>
                <DropdownDateTime
                  dropdownValues={pickupDatesForSubscription || []}
                  icon={<CalendarDaysIcon />}
                  onChange={(date) => {
                    setSelectedTimeSlots((prevState) => ({
                      ...prevState,
                      first_pickup_date: date?.dateYYYYMMDD,
                      pickup_start_time: availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time,
                      pickup_end_time: availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.end_time,
                      selected_pickup_time_id: timeIdGenarate(
                        date?.dateYYYYMMDD,
                        availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time
                      ),
                    }));
                  }}
                  placeholder='Pick a Date'
                  defaultValue={pickupDatesForSubscription?.find(
                    (item) => item?.id === dateFn('YYYYMMDD', selectedTimeSlots?.first_pickup_date)
                  )}
                  type='date'
                  disabled={isEmpty(pickupDatesForSubscription)}
                />
              </div>
            )}
            {!isEmpty(pickupTimes) && (
              <div className='py-2 sm:py-4'>
                <label className='go-label-16'>Pickup Time</label>
                <DropdownDateTime
                  dropdownValues={pickupTimes || []}
                  icon={<ClockIcon />}
                  onChange={(time) => {
                    setSelectedTimeSlots((prevState) => ({
                      ...prevState,
                      pickup_start_time: time?.default?.start_time,
                      pickup_end_time: time?.default?.end_time,
                      selected_pickup_time_id: time?.id,
                    }));
                  }}
                  placeholder='Pick a Time'
                  defaultValue={pickupTimes?.find((item) => item?.id === selectedTimeSlots?.selected_pickup_time_id)}
                  type='time'
                />
              </div>
            )}
          </div>
          <hr className='go-hr-dashed mt-3 hidden sm:block'></hr> */}
          <div className='mt-2 grid gap-0 sm:gap-4 xl:grid-cols-2 '>
            <div className='pb-4'>
              <label className='go-label-16'>Delivery Date</label>
              {orderType === 'oneTime' ? (
                !isEmpty(deliveryDatesForOneTime) && (
                  <SelectableTiles
                    dropdownValues={deliveryDatesForOneTime || []}
                    icon={<CalendarDaysIcon />}
                    onChange={(date) => {
                      setSelectedTimeSlots((prevState) => ({
                        ...prevState,
                        dropoff_date: date?.dateYYYYMMDD,
                        dropoff_start_time: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time,
                        dropoff_end_time: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.end_time,
                        selected_dropoff_time_id: timeIdGenarate(
                          date?.dateYYYYMMDD,
                          availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time
                        ),
                      }));
                      onPriorityfeeChange(availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]);
                    }}
                    placeholder='Pick a Date'
                    defaultValue={deliveryDatesForOneTime?.find(
                      (item) => item?.id === dateFn('YYYYMMDD', selectedTimeSlots?.dropoff_date)
                    )}
                    type='date'
                  />
                )
              ) : (
                <SelectableTiles
                  dropdownValues={deliveryDatesForSubscription || []}
                  icon={<CalendarDaysIcon />}
                  onChange={(date) => {
                    setSelectedTimeSlots((prevState) => ({
                      ...prevState,
                      dropoff_date: date?.dateYYYYMMDD,
                      dropoff_start_time: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time,
                      dropoff_end_time: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.end_time,
                      selected_dropoff_time_id: timeIdGenarate(
                        date?.dateYYYYMMDD,
                        availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time
                      ),
                      daysAfter: date?.daysAfter,
                    }));
                    onPriorityfeeChange(availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]);
                  }}
                  placeholder='Pick a Date'
                  defaultValue={deliveryDatesForSubscription?.find(
                    (item) => item?.id === dateFn('YYYYMMDD', selectedTimeSlots?.dropoff_date)
                  )}
                  type='date'
                />
                // <DropdownDateTime
                //   dropdownValues={deliveryDatesForSubscription || []}
                //   icon={<CalendarDaysIcon />}
                //   onChange={(date) => {
                //     setSelectedTimeSlots((prevState) => ({
                //       ...prevState,
                //       dropoff_date: date?.dateYYYYMMDD,
                //       dropoff_start_time: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time,
                //       dropoff_end_time: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.end_time,
                //       selected_dropoff_time_id: timeIdGenarate(
                //         date?.dateYYYYMMDD,
                //         availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time
                //       ),
                //     }));
                //     onPriorityfeeChange(availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]);
                //   }}
                //   placeholder='Pick a Date'
                //   defaultValue={deliveryDatesForSubscription?.find(
                //     (item) => item?.id === dateFn('YYYYMMDD', selectedTimeSlots?.dropoff_date)
                //   )}
                //   type='date'
                // />
              )}
            </div>
            {!isEmpty(deliveryTimes) && (
              <div className='pb-4'>
                <label className='go-label-16'>Delivery Time</label>
                <SelectableTiles
                  dropdownValues={deliveryTimes || []}
                  icon={<ClockIcon />}
                  onChange={(time) => {
                    setSelectedTimeSlots((prevState) => ({
                      ...prevState,
                      selected_dropoff_time_id: time?.id,
                      dropoff_start_time: time?.default?.start_time,
                      dropoff_end_time: time?.default?.end_time,
                    }));
                    onPriorityfeeChange(time);
                  }}
                  placeholder='Pick a Time'
                  defaultValue={deliveryTimes?.find((item) => item?.id === selectedTimeSlots?.selected_dropoff_time_id)}
                  type='time'
                />
                <div className='my-1 mt-3 ml-6 flex items-center text-sm font-medium italic text-black'>
                  <img src={bolt} alt='tick' className='mr-1 h-[14px] w-[14px]' />
                  Fast delivery for an extra fee
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {orderType === 'subscription' &&
        orderTimeSlots?.first_pickup_date &&
        orderTimeSlots?.pickup_start_time &&
        orderTimeSlots?.dropoff_end_time && (
          <div className='bg-client-gobg2 mt-5 flex rounded-md p-2'>
            <div className='w-16'>
              <img className='' src={one_time} width='40' height='40' />
            </div>
            <div className='flex'>
              <p className='go-textblackmedium-16 pl-4'>
                You've selected {subTypeText(orderRepeatFrequency?.value)} pickups on{' '}
                {orderRepeatFrequency?.value == 'MONTH'
                  ? `the ${dateFn('day1', orderTimeSlots?.first_pickup_date)} of each month at `
                  : `${dateFn('day', orderTimeSlots?.first_pickup_date)}s at `}
                {utcTimeToLocalTime(`${orderTimeSlots?.first_pickup_date}T${orderTimeSlots?.pickup_start_time}`)} with
                delivery on{' '}
                {orderRepeatFrequency?.value == 'MONTH'
                  ? ` the ${dateFn('day1', orderTimeSlots?.dropoff_date)} of each month at `
                  : `${dateFn(
                      'day',
                      moment(orderTimeSlots?.first_pickup_date)
                        .add(orderTimeSlots?.daysAfter, 'days')
                        .format('YYYY-MM-DD')
                    )}s at `}
                {utcTimeToLocalTime(`${orderTimeSlots?.dropoff_date}T${orderTimeSlots?.dropoff_start_time}`)}
              </p>
            </div>
          </div>
        )}
    </div>
  );
}
