import React, { useEffect, useState } from 'react';
import ModalWithHeading from '../../../components/ModalWithHeading';
import ClientButton from '../../../components/ClientButton';
import { createNewOrder, createNewSubscriptions } from '../../../redux/Reducers/CreateOrderReducer';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import { toast } from 'react-hot-toast';
import NoData from '../../../components/NoData';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { getDifference } from '../../../utils/dateTimeFormatter';
import { set } from 'lodash';
export default function ProcessOrderModal({ orderProcessModal, setOrderProcessModal, setstep }) {
  const dispatch = useDispatch();
  const {
    orderType,
    selectedServices,
    orderTimeSlots,
    orderServiceAddress,
    orderPreferences,
    orderProvider,
    orderPromoCode,
    orderRepeatFrequency,
    final_tip,
  } = useSelector((state) => state.createNewOrder);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something went wrong');
  const [oneTimeOrderPayload, setOneTimeOrderPayload] = useState({
    service_address_id: orderServiceAddress?.id,
    items: selectedServices?.map((item) => {
      return {
        service_id: item?.service_id,
        quantity: item?.quantity,
      };
    }),
    preferred_worker_id: orderProvider?.id || undefined,
    pickup_date: orderTimeSlots?.pickup_date,
    dropoff_date: orderTimeSlots?.dropoff_date,
    pickup_start_time: orderTimeSlots?.pickup_start_time,
    pickup_end_time: orderTimeSlots?.pickup_end_time,
    dropoff_start_time: orderTimeSlots?.dropoff_start_time,
    dropoff_end_time: orderTimeSlots?.dropoff_end_time,
    preferences: orderPreferences,
    promo_code: orderPromoCode,
    create_order: true,
  });
  const [subscriptionOrderPayload, setSubscriptionOrderPayload] = useState({
    service_address_id: orderServiceAddress?.id,
    items: selectedServices?.map((item) => {
      return {
        service_id: item?.service_id,
        quantity: item?.quantity,
      };
    }),
    preferred_worker_id: orderProvider?.id || undefined,
    subscription_entries: {
      first_pickup_date: `${orderTimeSlots?.first_pickup_date}T00:00:00`,
      pickup_start_time: orderTimeSlots?.pickup_start_time,
      pickup_end_time: orderTimeSlots?.pickup_end_time,
      dropoff_start_time: orderTimeSlots?.dropoff_start_time,
      dropoff_end_time: orderTimeSlots?.dropoff_end_time,
      subscription_frequency: orderRepeatFrequency?.value,
      days_after: getDifference(orderTimeSlots?.first_pickup_date, orderTimeSlots?.dropoff_date),
    },
    preferences: orderPreferences,
    promo_code: orderPromoCode,
    create_subscription: true,
  });
  useEffect(() => {
    if (final_tip) {
      setOneTimeOrderPayload({
        ...oneTimeOrderPayload,
        final_tip: final_tip,
      });
      setSubscriptionOrderPayload({
        ...subscriptionOrderPayload,
        final_tip: final_tip,
      });
    }
  }, [final_tip]);

  const createOrderFn = () => {
    setLoading(true);
    if (orderType === 'oneTime') {
      const response = createNewOrder(oneTimeOrderPayload, orderServiceAddress?.time_zone);
      response.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setLoading(false);
          setstep();
          toast.success(res?.message || 'Order created successfully');
          dispatch(setOrderDataAction({ newOrderId: res?.order_id || null }));
        } else {
          setErrorMsg(res?.message || 'Something went wrong');
          setLoading(false);
          toast.error(res?.message || 'Something went wrong2');
        }
      });
    } else if (orderType === 'subscription') {
      const response = createNewSubscriptions(subscriptionOrderPayload, orderServiceAddress?.time_zone);
      response.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setLoading(false);
          setstep();
          toast.success(res?.message || 'Subscription created successfully');
          dispatch(setOrderDataAction({ newSubscriptionId: res?.subscription_id || null }));
        } else {
          setErrorMsg(res?.message || 'Something went wrong');
          setLoading(false);
          toast.error(res?.message || 'Something went wrong2');
        }
      });
    }
  };

  useEffect(() => {
    orderProcessModal && createOrderFn();
  }, [orderProcessModal]);

  return (
    <ModalWithHeading
      modalOpen={orderProcessModal}
      setModalOpen={setOrderProcessModal}
      title=''
      classes='max-w-3xl'
      closeBtn={false}
    >
      <div className='mx-8'>
        <div className='flex-row'>
          {loading ? (
            <Loader text='processing your order......' classes='h-96' />
          ) : (
            <NoData text={errorMsg} classes='h-96' />
          )}
        </div>
        {!loading && (
          <div className='flex justify-center py-5'>
            <ClientButton
              variant='primary'
              text='Close'
              classes=''
              onClick={() => {
                setOrderProcessModal(false);
              }}
            />
          </div>
        )}
      </div>
    </ModalWithHeading>
  );
}
