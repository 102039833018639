import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProviderProfile from '../../../components/ProviderProfile';
import ClientButton from '../../../components/ClientButton';
import ModalWithHeading from '../../../components/ModalWithHeading';
import { getAllProvidersApi } from '../../../redux/Reducers/ProvidersReducer';
import Loader from '../../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderPreferencesApi, updateOrderPreferencesApi } from '../../../redux/Reducers/MyAccountReducer';
import { toast } from 'react-hot-toast';

export default function SelectProviders({ providersModal, setProvidersModal }) {
  const dispatch = useDispatch();
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
  const { orderPreferences } = useSelector((state) => state.myaccount);
  const [allProvidersList, setAllProvidersList] = useState(null);
  const [avaiableProvidersLoading, setAvailableProvidersLoading] = useState(false);
  const [avaiableProviders, setAvailableProviders] = useState([]);
  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    variableWidth: false,
    slidesToScroll: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          initialSlide: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onSelectProvider = (provider) => {
    const payload = {
      preferred_worker_id: provider?.id || null,
    };
    const response = updateOrderPreferencesApi(selectedAddress?.id, payload);
    response?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        toast.success(res?.message || 'Preferred provider updated successfully');
        selectedAddress && dispatch(getOrderPreferencesApi(selectedAddress?.id));
        setProvidersModal(false);
      } else {
        toast.error(res?.message || 'Something went wrong');
        setProvidersModal(false);
      }
    });
  };
  useEffect(() => {
    setAvailableProvidersLoading(true);
    const temp = {
      service_ids: [],
    };
    const data = getAllProvidersApi(selectedAddress?.id, temp, selectedAddress?.time_zone);
    data?.then((res) => {
      setAvailableProvidersLoading(false);
      if (res?.status === 200 || res?.status === 201) {
        setAvailableProviders(res?.data?.data || []);
      } else {
        setAvailableProviders([]);
      }
    });
  }, [selectedAddress, providersModal]);
  useEffect(() => {
    setAllProvidersList(avaiableProviders);
  }, [avaiableProviders]);

  return (
    <ModalWithHeading
      modalOpen={providersModal}
      setModalOpen={setProvidersModal}
      title='Check out our team of skilled local professionals! Pick your preferred provider for a customized experience.'
      classes='max-w-5xl bg-client-statuschipbg px-16 py-8'
    >
      <div>
        <div className='max-w-9xl mx-auto w-full'>
          <div className='my-4'>
            {avaiableProvidersLoading ? (
              <Loader classes='h-96 w-full' text='searching for providers...' />
            ) : (
              <div className='w-full'>
                <Slider {...settings}>
                  {allProvidersList?.map((provider, i) => (
                    <div key={i}>
                      <div className='mr-6 rounded-lg bg-white py-10'>
                        <ProviderProfile provider={provider} type='multiple' readonly={true} />
                        {/* <div className=' border-client-border my-6 h-60 overflow-y-auto border-y p-4'>
                          <p className='go-textblack-16 mb-2'>Available for Pickup:</p>
                          {!isEmpty(provider?.schedules) ? (
                            provider?.schedules?.map((item, i) => (
                              <p className='go-textblack-14 py-1' key={i}>
                                {`${item?.weekday}, ${timeFormatterWithoutUTC(
                                  item?.start_time
                                )} - ${timeFormatterWithoutUTC(item?.end_time)}`}
                              </p>
                            ))
                          ) : (
                            <span className='go-textblack-14 py-1'>No Available Times</span>
                          )}
                        </div> */}
                        {provider?.id == orderPreferences?.preferred_worker?.id ? (
                          <div className='border-client-border border-t pt-5 text-center'>
                            <ClientButton
                              text='Selected Provider'
                              variant='selected-link'
                              onClick={() => onSelectProvider(provider)}
                            />
                          </div>
                        ) : (
                          <div className='border-client-border border-t pt-5 text-center'>
                            <ClientButton
                              text='Select Provider'
                              variant='link'
                              onClick={() => onSelectProvider(provider)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
                {/* {selectedProvider && (
                  <p className='go-blue-18 cursor-pointer pt-8 text-center' onClick={() => setProvidersModal(false)}>
                    No thanks, I’ll keep my current provider.
                  </p>
                )} */}
                <div className='flex justify-center'>
                  <ClientButton
                    text='I have no preference'
                    variant='link'
                    classes='w-96 mt-10'
                    onClick={() => onSelectProvider(null)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalWithHeading>
  );
}
