import React, { useState, useEffect } from 'react';
import subscriptionsList from '../../../../mockdata/subscriptions';
import SubscriptionsDefault from './SubscriptionsDefault';
import SubscriptionsCollapse from './SubscriptionsCollapse';
import DeactivateModal from '../DeactivateModal';
import { useSelector } from 'react-redux';
import { getActiveSubscriptionsApi } from '../../../../redux/Reducers/commonApis';
import Loader from '../../../../components/Loader';
import NoData from '../../../../components/NoData';
import { toast } from 'react-hot-toast';
import { deactivateSubscription } from '../../../../redux/Reducers/MyAccountReducer';
function Subscriptions() {
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [reloadSubscriptions, setReloadSubscriptions] = useState(false);
  const [data, setData] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const getActiveSubscriptionsFn = (filters) => {
    setLoading(true);
    const response = getActiveSubscriptionsApi(filters);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setData(res?.data?.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.error?.message || 'Something went wrong');
      }
    });
  };
  const deactivateModalFn = (val, id) => {
    if (id) {
      setDeactivateModal(val);
      setSelectedSubscription(id);
    }
  };
  useEffect(() => {
    const temp = {
      filter: [
        {
          columns: ['customer_address_id'],
          value: [`${selectedAddress?.id}`],
          operation: '=',
        },
      ],
    };
    getActiveSubscriptionsFn(temp);
  }, [selectedAddress, reloadSubscriptions]);
  return (
    <div className='relative rounded-lg bg-white'>
      {loading ? (
        <Loader text='loading active subscriptions...' classes='h-96' />
      ) : data?.length == 0 ? (
        <NoData text='No subscriptions found' classes='h-96' />
      ) : data && data?.length > 0 ? (
        <SubscriptionsCollapse list={data} deactivateModalFn={(val, id) => deactivateModalFn(val, id)} />
      ) : (
        <SubscriptionsDefault />
      )}
      <DeactivateModal
        deactivateModal={deactivateModal}
        setDeactivateModal={setDeactivateModal}
        selectedSubscription={selectedSubscription}
        setReloadSubscriptions={setReloadSubscriptions}
      />
    </div>
  );
}

export default Subscriptions;
