import React, { useCallback, useEffect, useState } from 'react';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import ClientButton from '../../../components/ClientButton';
import { deactivateSubscription } from '../../../redux/Reducers/MyAccountReducer';
import Loader from '../../../components/Loader';
import { isEmpty } from 'lodash';
import { dateFn } from '../../../utils/dateTimeFormatter';
import ArrowRight from '../../../components/Icons/ArrowRight';
import { toast } from 'react-hot-toast';

export default function DeactivateModal({
  deactivateModal,
  setDeactivateModal,
  selectedSubscription,
  setReloadSubscriptions,
}) {
  const [type, setType] = useState('without_orders');
  const [res, setRes] = useState({});
  const [filter, setFilter] = useState({
    warning_confirmation: true,
    order_ids: [],
  });
  const [loading, setLoading] = useState(false);
  const onModalOpenFn = (id) => {
    setLoading(true);
    const response = deactivateSubscription(id, {});
    response?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setLoading(false);
      } else {
        if (isEmpty(res?.data?.orders)) {
          setType('without_orders');
          setLoading(false);
          setRes(res || {});
        } else {
          setType('with_orders');
          setLoading(false);
          setRes(res || {});
          setFilter({
            warning_confirmation: true,
            order_ids: res?.data?.orders?.map((item) => item?.id),
          });
        }
      }
    });
  };
  const onDeactivateClickFn = (id) => {
    const response = deactivateSubscription(id, filter);
    response?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setDeactivateModal(false);
        setReloadSubscriptions(true);
        toast.success(res?.message || 'Subscription Deactivated Successfully');
      } else {
        toast.error(res?.message || 'Something went wrong');
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    selectedSubscription && onModalOpenFn(selectedSubscription);
  }, [selectedSubscription]);

  return (
    <ModalWithHeadingSmall
      modalOpen={deactivateModal}
      setModalOpen={setDeactivateModal}
      title={type == 'with_orders' ? res?.data?.message : 'Are you sure you want to deactivate your Subscription?'}
      classes='max-w-2xl py-8'
    >
      {loading ? (
        <Loader text='loading...' classes='h-40 w-full' />
      ) : (
        <div>
          {type === 'without_orders' ? (
            <div className='max-w-9xl mx-auto w-full px-24'>
              {/* <div className='my-10 flex flex-row justify-center'>
                <p className='go-textblackmedium-16 py-2 text-center'>{res?.data?.message}</p>
              </div> */}
              <div className='flex flex-row justify-center'>
                <ClientButton
                  variant='primary'
                  text='Deactivate my subscription'
                  size='small'
                  onClick={() => onDeactivateClickFn(selectedSubscription)}
                />
                <ClientButton
                  variant='secondary'
                  text='Keep active'
                  size='small'
                  onClick={() => setDeactivateModal(false)}
                />
              </div>
            </div>
          ) : (
            <div className='max-w-9xl mx-auto w-full px-24'>
              <div className='flex-row py-4'>
                {res?.data?.orders?.map((item, idx) => (
                  <div className='m-3' key={idx}>
                    <label className='flex items-start'>
                      <span>
                        <ArrowRight classes='mt-1' />
                      </span>
                      <div>
                        <span className='go-textblackmedium-16 ml-4 block'>
                          Pickup: {dateFn('toLocal', item?.pickup_date, item?.pickup_start_time, item?.pickup_end_time)}
                        </span>
                        <span className='go-label-16 ml-4 block'>Order {item?.id}</span>
                        {item?.is_cancellation_fee_applicable && (
                          <div className='ml-4 text-[#fb5905]'>
                            This order is being picked up. If you cancel now, you’ll be charged a cancellation fee of $
                            {res?.data?.cancellation_fee}.
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              <div className='flex flex-row justify-center'>
                <ClientButton
                  variant='primary'
                  text='Deactivate my subscription'
                  size='small'
                  onClick={() => onDeactivateClickFn(selectedSubscription)}
                />
                <ClientButton
                  variant='secondary'
                  text='Keep active'
                  size='small'
                  onClick={() => setDeactivateModal(false)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </ModalWithHeadingSmall>
  );
}
