import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { titleCaseFormatter } from '../utils/stringFormatters';
import fastforward from '../../src/icons/fastforward.png';
import bolt from '../../src/icons/power.svg';
import { isEmpty } from 'lodash';

export default function SelectableTiles({
  dropdownValues,
  icon,
  prefix = '',
  onChange,
  type = 'text',
  defaultValue,
  placeholder = '',
}) {
  const [options, setOptions] = useState(dropdownValues || []);
  const [selected, setSelected] = useState(null);
  const onDropdownChange = (option) => {
    if (option?.id !== selected?.id) {
      setSelected(option);
      onChange(option);
    }
  };
  useEffect(() => {
    setOptions(dropdownValues);
  }, [dropdownValues]);
  // useEffect(() => {
  //   if (defaultValue) {
  //     onDropdownChange(defaultValue);
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className=''>
      {options?.map((item, itemIdx) => (
        <div
          key={itemIdx}
          className={`${
            item?.disabled ? 'cursor-not-allowed opacity-50 ' : 'cursor-pointer '
          } mb-1.5 w-full rounded-lg border-2 bg-[#f5f5fb] p-2 px-5 text-sm font-medium ${
            item?.id === selected?.id ? '  border-client-300' : ' border-[#f5f5fb]'
          }`}
          value={item}
          onClick={() => onDropdownChange(item)}
          disabled={item?.disabled}
        >
          {item?.displayValue?.split('for').length > 1 ? (
            <>
              <div className='flex'>
                <span className={`block truncate ${item?.id === selected?.id ? 'font-medium' : 'font-medium'}`}>
                  {`${item?.displayValue.split('for')[0]}`}
                </span>
                <span>
                  <img src={bolt} alt='tick' className=' ml-1 mt-1 h-[14px] w-[14px]' />
                </span>
                <span className={`text-client-300 block truncate font-medium`}>
                  {`${item?.displayValue.split('for')[1]}`}
                </span>
              </div>
            </>
          ) : (
            <>
              <span className={`block truncate ${item?.id === selected?.id ? 'font-medium' : 'font-medium'}`}>
                {item?.displayValue || item?.value}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
