import { combineReducers } from 'redux';
import { CreateOrderReducer } from './Reducers/CreateOrderReducer';
import { ServiceAddressesReducer } from './Reducers/ServiceAddressesReducer';
import { LandingPageReducer } from './Reducers/LandingPageReducer';
import { ProvidersReducer } from './Reducers/ProvidersReducer';
import { ProfileReducer } from './Reducers/ProfileReducer';
import { MyOrdersReducer } from './Reducers/MyOrdersReducer';
import { MyAccountReducer } from './Reducers/MyAccountReducer';
export default combineReducers({
  createNewOrder: CreateOrderReducer,
  serviceAddresses: ServiceAddressesReducer,
  landingPage: LandingPageReducer,
  providers: ProvidersReducer,
  profile: ProfileReducer,
  myorders: MyOrdersReducer,
  myaccount: MyAccountReducer,
});
