import React, { useState, useEffect } from 'react';
import { getInvoiceFiltersApi } from '../../../redux/Reducers/PaymentsReducer';
import {} from '../../../utils/dateTimeFormatter';
import TableFilter from '../../../components/TableFilter';
import { toast } from 'react-hot-toast';
import { useCallback } from 'react';
import filterGenarator from '../../../utils/filterGenarator';
import { isEmpty } from 'lodash';
import MyInvoiceTableBody from './MyInvoiceTableBody';
import ClientButton from '../../../components/ClientButton';
import PayInvoice from '../../PayInvoice';
function MyInvoices({ billingDetails }) {
  const [filterValues, setFilterValues] = useState({
    due_on: [],
    status: [],
  });
  const [filters, setFilters] = useState({
    page: 1,
    sort: { column: 'due_date', order: 'asc' },
    filter: [],
  });
  const [openInovice, setOpenInovice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const getInvoiceFiltersFn = () => {
    const response = getInvoiceFiltersApi({});
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        let newValues = {};
        const allFilters = res?.data?.forEach((element) => {
          newValues[element?.name] = element?.filters;
        });
        setFilterValues(newValues);
      } else {
        setInvoiceLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };

  const onFilterChange = useCallback((type, values) => {
    const filterRes = filterGenarator(filters?.filter, type, values);
    if (!isEmpty(filterRes)) {
      setFilters({ ...filters, filter: filterRes });
    } else {
      setFilters({ ...filters, filter: [] });
    }
  }, []);

  const payInvoiceFn = (val, id) => {
    setOpenInovice(val);
    setSelectedInvoice(id);
  };
  useEffect(() => {
    getInvoiceFiltersFn(filters);
  }, []);

  return (
    <div className=' rounded-lg bg-white'>
      <div className='py-2'>
        <div className=' min-h-[300px] overflow-x-auto'>
          <table className='w-full table-auto divide-y divide-slate-200'>
            <thead className='border-b border-slate-200'>
              <tr>
                <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                  <TableFilter text='Invoice #' align='text-left' />
                </th>
                <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                  <TableFilter text='Due On' align='text-left' />
                </th>
                <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                  <TableFilter
                    text='Status'
                    align='text-left'
                    filters={filterValues?.status}
                    onchange={(filters) => onFilterChange('status', filters)}
                  />
                </th>
                <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                  <TableFilter text='Amount' align='text-left' />
                </th>
                <th className='go-textblacksemibold-12 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5'>
                  <TableFilter text='Remaining Balance' align='text-left' />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <MyInvoiceTableBody filters={filters} payInvoice={payInvoiceFn} billingDetails={billingDetails} />
            </tbody>
          </table>
        </div>
      </div>
      <PayInvoice
        open={openInovice}
        setOpen={() => {
          setOpenInovice(false);
        }}
        selectedInvoice={selectedInvoice}
        invoiceDetails={{}}
      />
    </div>
  );
}

export default MyInvoices;
