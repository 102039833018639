import React, { useEffect, useRef, useState } from 'react';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import ClientButton from '../../../components/ClientButton';
import { useNavigate } from 'react-router-dom';
import { cancelOrderApi } from '../../../redux/Reducers/MyOrdersReducer';
import { toast } from 'react-hot-toast';
export default function CancelModal({ orderId, cancelModal, setCancelModal }) {
  const reason = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState({
    warning_confirmation: false,
    cancellation_reason: null,
  });
  const [warningMsg, setWarningMsg] = useState('');
  const [shouldCancel, setShouldCancel] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [diffReason, setDiffReason] = useState(false);
  const modalCloseBtnTrigger = () => {
    setCancelModal(false);
    setShouldCancel(false);
    setIsCancelled(false);
  };
  const onRadioChange = (val, e) => {
    if (val) {
      setDiffReason(val);
      setData({ ...data, cancellation_reason: reason?.value });
    } else {
      setDiffReason(val);
      setData({ ...data, cancellation_reason: e.target.value });
    }
  };
  const cancelOrderFn = (flag) => {
    const response = cancelOrderApi(orderId, data);
    if (flag === 'reason') {
      response?.then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          window.location.reload();
          // setIsCancelled(true);
          // setShouldCancel(false);
          toast.success(res?.data?.message || 'Order Cancelled Successfully');
        } else {
          setWarningMsg(res?.data?.message);
          setShouldCancel(false);
          setData({ ...data, warning_confirmation: true });
        }
      });
    } else if (flag === 'cancel') {
      response?.then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          toast.success(res?.data?.message || 'Order Cancelled Successfully');
          // setIsCancelled(true);
          window.location.reload();
          setShouldCancel(false);
        } else {
          toast.error(res?.data?.message || 'Something went wrong');
        }
      });
    }
  };
  useEffect(() => {
    setShouldCancel(cancelModal);
  }, [cancelModal]);

  return (
    <ModalWithHeadingSmall
      modalOpen={cancelModal}
      setModalOpen={setCancelModal}
      showCloseBtn={isCancelled ? true : false}
      title={
        shouldCancel
          ? `Please tell us why you’d like
to cancel this order`
          : isCancelled
          ? `Your order has been cancelled!`
          : warningMsg
      }
      classes='max-w-2xl'
    >
      <div className='mx-2'>
        {shouldCancel && (
          <div className='flex-row py-4  sm:ml-24'>
            <div className='m-3'>
              <label className='flex items-start'>
                <input
                  type='radio'
                  name='radio-buttons'
                  className='form-radio my-1'
                  value='I changed my mind'
                  onClick={(e) => onRadioChange(false, e)}
                />
                <span className='go-textblackmedium-16 ml-4 block'>I changed my mind</span>
              </label>
            </div>
            <div className='m-3'>
              <label className='flex items-start'>
                <input
                  type='radio'
                  name='radio-buttons'
                  className='form-radio my-1'
                  value='I don’t like the price'
                  onClick={(e) => onRadioChange(false, e)}
                />
                <span className='go-textblackmedium-16 ml-4 block'>I don’t like the price</span>
              </label>
            </div>
            <div className='m-3'>
              <label className='flex items-start'>
                <input
                  type='radio'
                  name='radio-buttons'
                  className='form-radio my-1'
                  value='I no longer need service'
                  onClick={(e) => onRadioChange(false, e)}
                />
                <span className='go-textblackmedium-16 ml-4 block'>I no longer need service</span>
              </label>
            </div>
            <div className='m-3'>
              <label className='flex items-start'>
                <input
                  type='radio'
                  name='radio-buttons'
                  className='form-radio my-1'
                  value='I have an issue with this order'
                  onClick={(e) => onRadioChange(false, e)}
                />
                <span className='go-textblackmedium-16 ml-4 block'>I have an issue with this order</span>
              </label>
            </div>
            <div className='m-3'>
              <label className='flex items-start'>
                <input
                  type='radio'
                  name='radio-buttons'
                  className='form-radio my-1'
                  onClick={(e) => onRadioChange(true, e)}
                />
                <span className='go-textblackmedium-16 ml-4 block'>I have a different reason</span>
              </label>
            </div>
            {diffReason && (
              <div className=' m-3'>
                <label htmlFor='feedback' className='go-label-16'>
                  Enter your reason
                </label>
                <textarea
                  ref={reason}
                  id='reason'
                  className='form-textarea go-inputtext-16'
                  rows='4'
                  required
                  onChange={(e) => setData({ ...data, cancellation_reason: e.target.value })}
                ></textarea>
              </div>
            )}
          </div>
        )}
        {isCancelled && (
          <div className='flex justify-center py-5'>
            <ClientButton
              variant='primary'
              text='Place a new order'
              size='small'
              classes=''
              onClick={() => navigate('/create-new-order')}
            />
            <ClientButton
              variant='secondary'
              text='See available promotions'
              size='small'
              classes=''
              onClick={() => navigate('/promotions')}
            />
          </div>
        )}
        {!isCancelled && (
          <div className='flex justify-center py-5'>
            <ClientButton
              variant='secondary'
              text={shouldCancel ? 'Back' : 'No'}
              size='small'
              classes=''
              onClick={() => {
                setData({ ...data, warning_confirmation: false });
                modalCloseBtnTrigger();
              }}
            />
            {shouldCancel ? (
              <ClientButton
                variant='cancel'
                text='Cancel Order'
                classes={data?.cancellation_reason ? '' : 'opacity-50 cursor-not-allowed pointer-events-none'}
                size='small'
                onClick={() => {
                  cancelOrderFn('reason');
                }}
              />
            ) : (
              <ClientButton
                variant='cancel'
                text='Yes'
                classes=''
                size='small'
                onClick={() => {
                  cancelOrderFn('cancel');
                }}
              />
            )}
          </div>
        )}
      </div>
    </ModalWithHeadingSmall>
  );
}
