import axios from 'axios';
import { toast } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
const currentUrl = window.location.pathname;
const prefix = import.meta.env.VITE_API_PREFIX;
const tenant = import.meta.env.VITE_TENANT_NAME;
const axiosClient = axios.create();
axiosClient.defaults.baseURL = prefix;

axiosClient.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
};

axiosClient.interceptors.request.use(
  (config) => {
    document.body.style.pointerEvents = 'none';
    document.body.style.opacity = '0.9';

    const authUserToken = sessionStorage.getItem('token');
    if (authUserToken) {
      config.headers['authorization'] = authUserToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    document.body.style.pointerEvents = 'auto';
    document.body.style.opacity = '1';
    return response;
  },
  (error) => {
    document.body.style.pointerEvents = 'auto';
    document.body.style.opacity = '1';
    Sentry.captureException(error);
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      sessionStorage.removeItem('token');
      localStorage.setItem('lastUrl', currentUrl);
      if (error?.response?.data?.error?.type == 'Unauthorized') {
        return error?.response?.data?.error || error?.response?.data || 'Something went wrong';
      } else {
        window.location = '/signin';
      }
    } else if (error?.response?.status === 404 || error?.response?.status === 400) {
      return error?.response?.data?.error || error?.response?.data || 'Something went wrong';
    } else {
      return Promise.reject(error?.response?.data);
    }
  }
);
function loginApi(data, tenant) {
  return post(`/auth/client/tokens?tenant=${tenant}`, data, {
    transformResponse: [
      (result) => {
        const parsed = JSON.parse(result);
        sessionStorage.setItem('token', parsed?.data?.access_token);
        return parsed;
      },
    ],
  });
}

async function getRequest(URL) {
  const response = await axiosClient.get(`${URL}`);
  return response?.data || response;
}

async function postRequest(URL, payload) {
  const response = await axiosClient.post(`${URL}`, payload);
  if (response?.data) {
    return response?.data;
  } else {
    return response;
  }
}
async function getPostRequest(URL, payload = {}) {
  const response = await axiosClient.post(`${URL}`, payload);
  return response;
}
async function putRequest(URL, payload) {
  const response = await axiosClient.put(`${URL}`, payload);
  return response;
}

async function patchRequest(URL, payload) {
  const response = await axiosClient.patch(`${URL}`, payload);
  return response;
}

async function deleteRequest(URL) {
  const response = await axiosClient.delete(`${URL}`);
  return response;
}
const get = (url, params, config = {}) => axiosClient.get(url, { params, ...config });
const post = (url, data, config = {}) => axiosClient.post(url, data, config);

export { getRequest, postRequest, getPostRequest, putRequest, patchRequest, deleteRequest, loginApi };
