import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { loginApi } from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDataApi } from '../../redux/Reducers/ProfileReducer';
import { getAllServiceAddressesApi } from '../../redux/Reducers/ServiceAddressesReducer';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
function SigninPage({ setType }) {
  const tenant = import.meta.env.VITE_TENANT_NAME;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('processing...');
  // const { profileData } = useSelector((state) => state.profile);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data, e) => {
    setLoading(true);
    const result = loginApi(data, tenant);
    result?.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setLoadingMsg('fetching profile data...');
        const getProfileData = dispatch(getProfileDataApi(true));
        getProfileData?.then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            const data = dispatch(getAllServiceAddressesApi());
            data?.then((resVal) => {
              if (resVal?.status === 200 || resVal?.status === 201) {
                if (!isEmpty(res?.data?.past_due_invoices)) {
                  window.location.href = '/account';
                } else {
                  //   if (localStorage.getItem('lastUrl')) {
                  //   navigate(localStorage.getItem('lastUrl'));
                  //   localStorage.removeItem('lastUrl');
                  // } else {
                  navigate('/dashboard');
                }
              }
            });
          } else if (res?.status === 400 || res?.status === 401 || res?.status === 403) {
            setLoading(false);
            toast.error(res?.message || 'Something went wrong');
          } else {
            setLoading(false);
            toast.error(res?.message || 'Something went wrong');
          }
        });
      } else {
        setLoading(false);
        toast.error(res?.message || 'Invalid Credentials');
      }
    });
  };

  return (
    <div className='mx-auto w-[350px] px-4 py-8'>
      <h1 className='mb-6 text-center text-3xl font-bold text-slate-800'>Log in</h1>
      {loading ? (
        <Loader text={loadingMsg} classes='h-36 w-full rounded-lg mb-2 ' />
      ) : (
        <form className='mb-4 '>
          <div className='space-y-4'>
            <div>
              <label className='go-label-15' htmlFor='email'>
                Email
              </label>
              <input
                id='email'
                className={`go-inputtext-15 w-full ${errors?.email?.type === 'required' ? 'border-red-500' : ''}`}
                placeholder='Enter your email'
                type='email'
                name='email'
                required
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Please enter your email address',
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Invalid email address',
                  },
                })}
                autoComplete='off'
              />
              {errors.email?.message && <p className='go-textnormal-14 text-red-500'>{errors.email?.message}</p>}
            </div>

            <div>
              <label className=' go-label-15' htmlFor='password'>
                Password
              </label>

              <input
                id='password'
                className={`go-inputtext-15 w-full ${errors?.password?.type === 'required' ? 'border-red-500' : ''}`}
                placeholder='*********'
                type='password'
                name='password'
                required
                {...register('password', {
                  required: {
                    value: true,
                    message: 'Please enter your password',
                  },
                })}
                autoComplete='off'
              />
              {errors.password?.message && (
                <p className='go-textnormal-14 mb-4 text-red-500'>{errors.password?.message}</p>
              )}
            </div>
          </div>
          <div className='flex justify-end'>
            <div className='mr-1'>
              <Link className='text-xs font-semibold text-[#548ff7]' to='#' onClick={() => setType('forgotPassword')}>
                Forgot Password?
              </Link>
            </div>
          </div>
        </form>
      )}
      <div className='mb-2 border-slate-200 pb-2'>
        <div className='flex items-center justify-between'>
          <Link
            className='w-full rounded bg-[#548ff7] py-2 px-4 text-center text-xs font-semibold text-white'
            onClick={(e) => {
              handleSubmit(onSubmit)(e);
            }}
            to='#'
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}
export default SigninPage;
