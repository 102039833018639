import React, { useEffect } from 'react';
import PromoCard from '../../components/PromoCard';
import MostPopularServices from './MostPopularServices';
import Order from './OrderSteps';
import { getLandingPageDataApi } from '../../redux/Reducers/LandingPageReducer';
import { useDispatch, useSelector } from 'react-redux';
import InActiveAccount from './InActiveAccount';
import RecentOrders from './RecentOrders';
import { isEmpty } from 'lodash';

function Dashboard() {
  const dispatch = useDispatch();
  const { initialData, loading } = useSelector((state) => state.landingPage);
  const { profileData } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getLandingPageDataApi());
  }, [dispatch]);



  const renderContent = () => {
    if (profileData?.status === 'ACTIVE') {
      let obj = initialData?.featured_order;
      return <Order data={obj || {}} loading={loading} />;
    } else {
      return <InActiveAccount data={profileData} loading={loading} />;
    }
  };

  return (
    <div className='grid grid-cols-12 gap-6'>
      <div className='col-span-full flex xl:col-span-5'>{renderContent()}</div>
      <div className='col-span-full flex-row xl:col-span-7'>
        <div className='mb-5 flex w-full rounded-sm'>
          <PromoCard promo={initialData?.promo_code[0]} loading={loading} />
        </div>
        <div className='mb-5 flex w-full rounded-xl bg-white'>
          <RecentOrders data={initialData?.recent_orders} loading={loading} />
        </div>
        {/* <div className='mb-5 flex w-full rounded-xl bg-white'>
          <MostPopularServices data={initialData?.services} loading={loading} />
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;