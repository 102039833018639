import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import ClientButton from './ClientButton';
import { addPaymentMethodApi } from '../redux/Reducers/commonApis';
import { toast } from 'react-hot-toast';
import Loader from './Loader';

const STRIPE_KEY =
  import.meta.env.VITE_TENANT_NAME === 'laundrycare'
    ? import.meta.env.VITE_LAUNDRYCARE_STRIPE_KEY
    : import.meta.env.VITE_TENANT_NAME === 'pushlaundry'
    ? import.meta.env.VITE_PUSHLAUNDRY_STRIPE_KEY
    : '';


const CheckoutForm = ({ addressid, cardAdded }) => {
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true,
  };

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    if (elements == null) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });

    if (error) {
      setErrorMessage(error.message);
    }
    if (paymentMethod) {
      setLoading(true);
      const payload = {
        stripe_payment_method_id: paymentMethod?.id,
      };
      const response = addPaymentMethodApi(addressid, payload);
      response.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Payment method added successfully');
          cardAdded();
        } else {
          toast.error(res?.error?.message || 'Something went wrong');
        }
        setLoading(false);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading ? (
        <Loader text='processing...' />
      ) : (
        <div style={{ position: 'relative', minHeight: '56px', padding: '15px' }}>
          <CardElement options={CARD_ELEMENT_OPTIONS}></CardElement>
          <fieldset
            style={{
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderRadius: '4px',
              position: 'absolute',
              top: '-5px',
              left: '0',
              right: '0',
              bottom: '0',
              margin: '0',
              padding: '0 8px',
              overflow: 'hidden',
              pointerEvents: 'none',
            }}
          ></fieldset>
        </div>
      )}

      {errorMessage && <p className='text-sm text-red-500'>{errorMessage}</p>}
      <div className='grid gap-1 pt-3'>
        <ClientButton
          variant='primary'
          text='Save'
          size='small'
          classes=''
          type='submit'
          disabled={!stripe || !elements}
        />
      </div>
    </form>
  );
};
const stripePromise = loadStripe(STRIPE_KEY);

export default function Stripe({ addressid, cardAdded = () => {} }) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm addressid={addressid} cardAdded={cardAdded} />
    </Elements>
  );
}
