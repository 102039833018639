import React, { useCallback, useEffect, useState } from 'react';
import Cards from './Cards';
import EditBillingContact from './EditBillingContact';
import EditPaymentInfo from './EditPaymentInfo';
import GiftCard from './GiftCard';
import MyInvoices from './MyInvoices';
import { getBillingDetailsApi } from '../../../redux/Reducers/commonApis';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

export default function Payments() {
  const { selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [editPayment, setEditPayment] = useState(false);
  const [editBilling, setEditBilling] = useState(false);
  const [billingDetails, setBillingDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const billingType = billingDetails?.addresses[0]?.billing_details?.default_payment_method_type;
  const getBillingDetailsFn = (id) => {
    setLoading(true);
    const response = getBillingDetailsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setBillingDetails(res?.data || {});
        setLoading(false);
      } else {
        toast.error(res?.message || 'Something went wrong');
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getBillingDetailsFn(selectedAddress?.id);
  }, [editBilling, selectedAddress?.id, editPayment]);

  return (
    <>
      {!editPayment && !editBilling && (
        <>
          <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
            <Cards
              setEditPayment={setEditPayment}
              setEditBilling={setEditBilling}
              billingDetails={billingDetails}
              loading={loading}
            />
          </div>
          <div className='flex-row py-5'>
            <h3 className='go-textblackbold-24 py-5'>My Invoices</h3>
            <MyInvoices billingDetails={billingDetails} />
          </div>
        </>
      )}
      {editPayment && (
        <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
          {billingType == 'STRIPE' && <EditPaymentInfo editPayment={editPayment} setEditPayment={setEditPayment} />}
          <GiftCard
            setEditPayment={setEditPayment}
            billingDetails={billingDetails}
            reFetchBillingDetails={() => getBillingDetailsFn(selectedAddress?.id)}
          />
        </div>
      )}
      {editBilling && (
        <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
          <EditBillingContact billingDetails={billingDetails} setEditBilling={setEditBilling} />
        </div>
      )}
    </>
  );
}
