import React, { useCallback, useEffect, useState } from 'react';
import Cards from './Cards';
import SelectProviders from './SelectProviders';
import Subscriptions from './Subscriptions';
import OrderPreferences from './OrderPreferences';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderPreferencesApi } from '../../../redux/Reducers/MyAccountReducer';

export default function Orders() {
  const dispatch = useDispatch();
  const { allServiceAddresses, selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [providersModal, setProvidersModal] = useState(false);
  const [orderPreference, setOrderPreference] = useState(false);
  useEffect(() => {
    dispatch(getOrderPreferencesApi(selectedAddress?.id));
  }, [dispatch, selectedAddress]);
  return (
    <>
      {!orderPreference && (
        <>
          <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
            <Cards
              providersModal={providersModal}
              setProvidersModal={setProvidersModal}
              setOrderPreference={setOrderPreference}
            />
          </div>
          <div className='flex-row py-5'>
            <Subscriptions />
          </div>
        </>
      )}
      {orderPreference && <OrderPreferences setOrderPreference={setOrderPreference} />}
      <SelectProviders providersModal={providersModal} setProvidersModal={setProvidersModal} />
    </>
  );
}
