import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { titleCaseFormatter } from '../utils/stringFormatters';
import SmallButton from './SmallButton';

export default function FilterDropdown({ icon, filters, onapply }) {
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    setOptions(filters);
  }, [filters]);
  const onFilterItemChangeFn = (item) => {
    if (selected?.includes(item)) {
      setSelected(selected?.filter((x) => x !== item));
    } else {
      setSelected([...selected, item]);
    }
  };
  const onFilterApplyFn = () => {
    onapply(selected);
  };
  return (
    <div className='px-1'>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button>
              <img src={icon} alt='filters' className='mt-1 h-4 w-4' />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='left absolute z-10 mt-3 transform px-4 sm:px-0 '>
                <div className='overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className='relative'>
                    {options?.map(
                      (item, index) =>
                        item && (
                          <div className='m-4' key={index}>
                            <label className='flex items-center'>
                              <input
                                type='checkbox'
                                className='form-checkbox'
                                checked={selected?.includes(item)}
                                defaultValue={item}
                                onChange={(e) => onFilterItemChangeFn(e.target.value)}
                              />
                              <span className='go-textblacknormal-14 ml-2'>{titleCaseFormatter(item)}</span>
                            </label>
                          </div>
                        )
                    )}
                  </div>
                  <div className='bg-gray-50 p-2'>
                    <div className='flex justify-center'>
                      <SmallButton
                        variant='primary'
                        size='xs'
                        text='Apply'
                        classes=''
                        onClick={() => {
                          onFilterApplyFn();
                        }}
                      />
                      <Popover.Panel>
                        {({ close }) => (
                          <SmallButton
                            variant='secondary'
                            size='xs'
                            text='Cancel'
                            classes=''
                            onClick={() => {
                              close();
                            }}
                          />
                        )}
                      </Popover.Panel>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
