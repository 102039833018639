import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ClientButton from '../../components/ClientButton';
import LeftArrow from '../../components/Icons/LeftArrow';
import { toast } from 'react-hot-toast';
import OrderConfirmation from './OrderConfirmation';
import OrderSummary from './OrderSummary';
import PaymentMethod from './PaymentMethod';
import PickupAddress from './PickupAddress';
import PickupAndDelivery from './PickupAndDelivery';
import SelectProvider from './SelectProvider';
import SelectServices from './SelectServices';
import OrderType from './OrderType';
import { setOrderDataAction } from '../../redux/Actions/CreateOrderActions';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import RightArrow from '../../components/Icons/RightArrow';
import { resetCreateOrderFlow } from '../../redux/Reducers/CreateOrderReducer';

export default function CreateNewOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    orderType,
    orderServiceAddress,
    selectedServices,
    orderTimeSlots,
    orderRepeatFrequency,
    orderProvider,
    orderPaymentDetails,
  } = useSelector((state) => state.createNewOrder);
  const { orderPreferences } = useSelector((state) => state.myaccount);
  const { profileData } = useSelector((state) => state.profile);
  const [selected, setSelected] = useState(1);

  const changeStepTitle = (val) => {
    switch (val) {
      case 1:
        return 'Create New Order';
      case 2:
        return 'Select Pickup Address';
      case 3:
        return 'Select Services';
      case 4:
        return 'Select Provider & Pickup';
      case 5:
        return 'Select Delivery';
      case 6:
        return 'Confirm Payment';
      case 7:
        return orderType === 'oneTime' ? 'Review Order' : 'Subscription Summary';
      case 8:
        return orderType === 'oneTime' ? 'Review Order' : 'Subscription Summary';
      default:
        return '';
    }
  };

  const orderCreationValidation = (val) => {
    switch (val) {
      case 1:
        return !orderType;
      case 2:
        return !orderServiceAddress;
      case 3:
        return orderServicesValidationFn();
      case 4:
        return !orderTimeSlots && !orderProvider;
      case 5:
        return orderPickupDeliveryValidationFn(orderTimeSlots);
      case 6:
        return orderPaymentDetailsValidationFn();
      case 7:
        return orderType === 'oneTime' ? 'Review Order' : 'Subscription Summary';
      case 8:
        return orderType === 'oneTime' ? 'Review Order' : 'Subscription Summary';
      default:
        return '';
    }
  };

  const orderServicesValidationFn = () => {
    if (isEmpty(selectedServices)) {
      return true;
    } else {
      return false;
    }
  };
  const orderPickupDeliveryValidationFn = (slots) => {
    if (orderType === 'oneTime') {
      return !(
        slots?.pickup_date &&
        slots?.pickup_start_time &&
        slots?.pickup_end_time &&
        slots?.dropoff_date &&
        slots?.dropoff_start_time &&
        slots?.dropoff_end_time
      );
    } else if (orderType === 'subscription') {
      return !(
        slots?.first_pickup_date &&
        slots?.pickup_start_time &&
        slots?.pickup_end_time &&
        slots?.dropoff_date &&
        slots?.dropoff_start_time &&
        slots?.dropoff_end_time &&
        orderRepeatFrequency
      );
    }
  };

  const orderPaymentDetailsValidationFn = () => {
    let type = orderPaymentDetails?.billing_details?.default_payment_method_type;
    if (type == 'ACH' || type == 'CHECK' || type == 'STRIPE') {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (profileData?.status !== 'ACTIVE') {
      toast.error('Your account is inactive!');
      navigate('/dashboard');
    } else if (!profileData?.is_allowed_to_create_order) {
      toast.error('You are not allowed to create orders!');
      navigate('/dashboard');
    }
  }, [profileData?.status]);

  const backButton = (
    <ClientButton variant='secondary' text='Back' classes='mr-2' onClick={() => setSelected(selected - 1)} />
  );

  const nextButton = (
    <ClientButton
      variant='primary'
      text='Next'
      classes='mx-2'
      disabled={orderCreationValidation(selected)}
      onClick={() => setSelected(selected + 1)}
    />
  );
  useEffect(() => {
    selected == 1 && dispatch(resetCreateOrderFlow());
  }, [selected]);

  return (
    <div>
      <div className='mb-8 flex h-10 items-center justify-between'>
        {selected > 1 && selected !== 8 ? (
          <span className='mr-5'>
            <ArrowLongLeftIcon onClick={() => setSelected(selected - 1)} className='w-10 cursor-pointer' />
          </span>
        ) : (
          <span></span>
        )}

        <h3 className='go-textblackbold-18 sm:go-textblackbold-24'>{changeStepTitle(selected)}</h3>
        {selected > 0 && selected !== 7 ? (
          <span className=''>
            <ArrowLongRightIcon
              onClick={() => setSelected(selected + 1)}
              className={`w-10 cursor-pointer ${
                orderCreationValidation(selected) ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'
              }`}
            />
          </span>
        ) : (
          <span></span>
        )}
      </div>
      <div className='flex justify-center'>
        <div className='w-full'>
          {selected <= 6 && (
            <div className='relative m-2'>
              <div className='bg-client-bgstep absolute  left-0 h-4 w-full rounded-full ' aria-hidden='true'></div>
              <ul className='text-semibold relative flex w-full justify-between text-sm'>
                {[1, 2, 3, 4, 5, 6].map((step, i) => (
                  <li className={`  ${step === 1 || step === 6 ? ' w-3/12' : ' w-5/12'}`} key={i}>
                    <div
                      className={`relative flex h-6 ${
                        step === 1 ? ' justify-start' : step === 6 ? ' justify-end' : ' justify-center'
                      } `}
                    >
                      <span
                        className={
                          'mb-2 flex h-4 w-4 items-center justify-center rounded-full text-xs font-semibold  text-slate-500 ' +
                          (selected === step
                            ? 'bg-client-currentstep'
                            : selected < step
                            ? 'bg-client-incompletestep'
                            : 'bg-client-completedstep')
                        }
                      ></span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {selected === 1 && (
            <div className='relative mt-2'>
              <OrderType />
              {/* <div className='relative top-20'>
                <div className='mt-5 grid gap-1 md:grid-cols-4'>{nextButton}</div>
              </div> */}
            </div>
          )}

          {selected === 2 && (
            <div className='relative mt-2'>
              <PickupAddress />
              {/* <div className='relative top-20'>
                <div className='mt-5 grid gap-1 md:grid-cols-4'>
                  {backButton}
                  {nextButton}
                </div>
              </div> */}
            </div>
          )}
          {selected === 3 && (
            <div className='relative mt-2'>
              <SelectServices />
              {/* <div className='relative top-20'>
                <div className='mt-5 grid gap-1 md:grid-cols-4'>
                  {backButton}
                  {nextButton}
                </div>
              </div> */}
            </div>
          )}
          {selected === 4 && (
            <div className='relative mt-2'>
              <SelectProvider setstep={() => setSelected(5)} />
              {/* <div className='relative top-20'>
                <div className='mt-5 grid gap-1 md:grid-cols-4'>
                  {backButton}
                  <ClientButton
                    variant='secondary'
                    text='Skip, No Preference'
                    classes='mr-2'
                    onClick={() => {
                      setSelected(selected + 1);
                      dispatch(setOrderDataAction({ orderProvider: null }));
                    }}
                  />
                  {nextButton}
                </div>
              </div> */}
            </div>
          )}
          {selected === 5 && (
            <div className='relative mt-2'>
              <PickupAndDelivery />
              {/* <div className='relative top-40'>
                <div className='mt-5 grid gap-1 md:grid-cols-4'>
                  {backButton}
                  {nextButton}
                </div>
              </div> */}
            </div>
          )}
          {selected === 6 && (
            <div className='relative mt-2'>
              <PaymentMethod />
              {/* <div className='relative top-20'>
                <div className='mt-5 grid gap-1 md:grid-cols-4'>
                  {backButton}
                  {nextButton}
                  <ClientButton variant='primary' text='Next' classes='mx-2' onClick={() => setSelected(7)} /> 
                </div>
              </div> */}
            </div>
          )}
          {selected === 7 && (
            <div className='relative mt-2'>
              <OrderSummary setstep={() => setSelected(8)} />
            </div>
          )}
          {selected === 8 && (
            <div className='relative mt-2'>
              <OrderConfirmation />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
