import React, { useEffect, useState } from 'react';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import moment from 'moment';
import ClientButton from '../../../components/ClientButton';
import { updateServiceAddressesApi } from '../../../redux/Reducers/ServiceAddressesReducer';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { dateFn, utcTimeToLocalTime } from '../../../utils/dateTimeFormatter';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { capitalizeFirstLetter, ordinal_suffix_of } from '../../../components/Utils';
import { warningMsgTextRemoveFn } from '../../../utils/commonUtil';

export default function ModalForOrders({
  modal,
  updateModal,
  existingOrders,
  newAddress,
  oldAddress,
  setShowOrderModal,
}) {
  const dispatch = useDispatch();
  const [tempAddress, setTempAddress] = useState(null);
  const [view, setView] = useState('type1');
  const [selectedOrderNo, setSelectedOrderNo] = useState({
    cancel_orders: [],
    warning_confirmation: true,
  });

  const textChangeFn = (unit, obj, type) => {
    if (unit === 'WEEK') {
      if (type === 'pickup') {
        return `${capitalizeFirstLetter(obj?.pickup_week_day)}s, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time
        )}`;
      } else {
        return `${capitalizeFirstLetter(obj?.dropoff_week_day)}s, ${genarateTime(
          moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
          obj?.dropoff_start_time,
          obj?.dropoff_end_time
        )}`;
      }
    }
    if (unit === 'BIWEEKLY') {
      if (type === 'pickup') {
        return `Every other ${capitalizeFirstLetter(obj?.pickup_week_day)}, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time
        )}`;
      } else {
        return `Every other ${capitalizeFirstLetter(obj?.dropoff_week_day)}, ${genarateTime(
          moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
          obj?.dropoff_start_time,
          obj?.dropoff_end_time
        )}`;
      }
    }
    if (unit === 'MONTH') {
      if (type === 'pickup') {
        return `${ordinal_suffix_of(moment(obj?.first_pickup_date).date())} of each month, ${genarateTime(
          obj?.first_pickup_date,
          obj?.pickup_start_time,
          obj?.pickup_end_time
        )}`;
      } else {
        return `${obj?.days_after} days after pickup, ${genarateTime(
          moment(obj?.first_pickup_date).add(obj?.days_after, 'days').format('YYYY-MM-DD'),
          obj?.dropoff_start_time,
          obj?.dropoff_end_time
        )}`;
      }
    }
  };
  const genarateTime = (date, time1, time2) => {
    date = date ? date : moment().format('YYYY-MM-DD');
    return `${utcTimeToLocalTime(`${date.split('T')[0]}T${time1}`)} - ${utcTimeToLocalTime(
      `${date.split('T')[0]}T${time2}`
    )}`;
  };
  const onChangeOrderNo = (e, orderNo, warningMsg) => {
    if (e.target.checked) {
      setSelectedOrderNo({
        ...selectedOrderNo,
        cancel_orders: [...selectedOrderNo?.cancel_orders, orderNo],
        warning_confirmation: true,
      });
    } else {
      setSelectedOrderNo({
        ...selectedOrderNo,
        cancel_orders: selectedOrderNo?.cancel_orders?.filter((item) => item != orderNo),
      });
    }
  };
  const updateNewAddress = (e) => {
    const create = dispatch(updateServiceAddressesApi(tempAddress, oldAddress?.id));
    create.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        updateModal(e, true, oldAddress?.id, 'update');
      } else {
        toast.error(res?.error?.message || 'Something went wrong');
      }
    });
  };
  const subscriptionType = (type) => {
    if (type === 'WEEK') {
      return 'Weekly';
    } else if (type === 'MONTH') {
      return 'Monthly';
    } else if (type === 'BIWEEKLY') {
      return 'Bi-Weekly';
    } else {
      return '-';
    }
  };
  const onTypeChange = (e) => {
    if (existingOrders?.subscriptions?.length > 0 || selectedOrderNo?.cancel_orders?.length > 0) {
      setView('type2');
    } else {
      updateNewAddress(e);
    }
  };
  useEffect(() => {
    setTempAddress({ ...tempAddress, ...selectedOrderNo });
  }, [selectedOrderNo]);
  useEffect(() => {
    setTempAddress({ ...newAddress, ...selectedOrderNo });
  }, [newAddress]);
  useEffect(() => {
    if (existingOrders?.orders?.length > 0) {
      setView('type1');
    } else if (existingOrders?.subscriptions?.length > 0) {
      setView('type2');
    }
  }, [existingOrders]);
  return (
    <div>
      <ModalWithHeadingSmall
        modalOpen={modal}
        setModalOpen={setShowOrderModal}
        showCloseBtn={true}
        title={view === 'type1' ? existingOrders?.message : 'The following orders / subscriptions will be cancelled:'}
        classes='max-w-2xl py-10'
      >
        <div className='mx-8 py-5'>
          <div className='flex-row space-y-5'>
            <div>
              {view === 'type1' ? (
                <div className='max-w-9xl mx-auto w-full'>
                  <div className='mb-4 max-h-[400px] min-h-[200px] flex-row overflow-y-scroll border border-gray-200 px-10 py-2'>
                    {existingOrders?.orders?.map((order, index) => (
                      <div className='m-3' key={'order' + index}>
                        <div className='flex items-start'>
                          <input
                            type='checkbox'
                            className='form-checkbox my-1'
                            onChange={(e) => onChangeOrderNo(e, order?.id, order?.warning_message)}
                            defaultChecked={selectedOrderNo?.cancel_orders?.includes(order?.id)}
                          />
                          <div>
                            <span className='go-textblack-15 ml-4 block'>Order #{order?.id}</span>
                            <span className='go-textblack-14 my-1 ml-4 block'>
                              Pickup:{' '}
                              {dateFn('toLocal', order?.pickup_date, order?.pickup_start_time, order?.pickup_end_time)}
                            </span>
                            <span className='go-textblack-14 my-1 ml-4 block'>
                              Delivery:{' '}
                              {dateFn(
                                'toLocal',
                                order?.dropoff_date,
                                order?.dropoff_start_time,
                                order?.dropoff_end_time
                              )}
                            </span>
                            {order?.warning_message && (
                              <p className='ml-4 border border-red-100 bg-red-50 p-1 text-sm text-red-600'>
                                {warningMsgTextRemoveFn(order?.warning_message)}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className='max-w-9xl mx-auto w-full'>
                  <div className='mb-4 max-h-[400px] min-h-[200px] flex-row overflow-y-scroll border border-gray-200 py-2'>
                    {existingOrders?.subscriptions?.map((subscription, index) => (
                      <div className='m-3 mx-6' key={'sub' + index}>
                        <div className='flex w-full justify-between text-left text-sm font-medium '>
                          <div className='go-textblack-15 flex w-full justify-between'>
                            <span>Subscription #{subscription?.id}</span>
                          </div>
                        </div>
                        <div className='px-2text-sm text-gray-500'>
                          {subscription?.subscription_entries?.map((entry, index) => (
                            <div key={'entry' + index}>
                              <span className='go-textblacknormal-14 my-1 ml-4 block'>
                                Frequency: {subscriptionType(subscription?.subscription_repeat_frequency_unit)}
                              </span>
                              <span className='go-textblacknormal-14 my-1 ml-4 block'>
                                Pickup:{' '}
                                {textChangeFn(subscription?.subscription_repeat_frequency_unit, entry, 'pickup')}
                              </span>
                              <span className='go-textblacknormal-14 my-1 ml-4 block'>
                                Delivery: {entry?.days_after} days after pickup
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    {existingOrders?.orders?.map((order, index) =>
                      selectedOrderNo?.cancel_orders?.includes(order?.id) ? (
                        <div className='m-3 mx-6' key={'or' + index}>
                          <div className='flex w-full justify-between  text-left text-sm font-medium '>
                            <div className='go-textblack-15 flex w-full justify-between'>
                              <span>Order #{order?.id}</span>
                            </div>
                          </div>
                          <div className=' px-2 text-sm text-gray-500'>
                            <div>
                              <span className='go-textblacknormal-14 my-1 ml-1 block'>
                                Pickup:{' '}
                                {dateFn(
                                  'toLocal',
                                  order?.pickup_date,
                                  order?.pickup_start_time,
                                  order?.pickup_end_time
                                )}
                              </span>
                              <span className='go-textblacknormal-14 my-1 ml-1 block'>
                                Delivery:{' '}
                                {dateFn(
                                  'toLocal',
                                  order?.dropoff_date,
                                  order?.dropoff_start_time,
                                  order?.dropoff_end_time
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              )}

              <div className='flex flex-row justify-center px-24'>
                {view === 'type1' ? (
                  <ClientButton
                    variant='secondary'
                    text='Close'
                    size='small'
                    onClick={(e) => {
                      setView('type1');
                      updateModal(e);
                    }}
                  />
                ) : (
                  <ClientButton
                    variant='secondary'
                    text='Back'
                    size='small'
                    onClick={(e) => {
                      setView('type1');
                    }}
                  />
                )}
                {view === 'type1' ? (
                  <ClientButton variant='primary' text='Next' size='small' onClick={(e) => onTypeChange(e)} />
                ) : (
                  <ClientButton variant='primary' text='Save' size='small' onClick={(e) => updateNewAddress(e, true)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalWithHeadingSmall>
    </div>
  );
}
