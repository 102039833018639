import React from 'react';

export default function OrderStatusBar({ orderDetails }) {
  return (
    <div className='mb-4 sm:flex'>
      <div className='mr-4 flex items-center'>
        <h1 className='go-textblackbold-24'>Order #{orderDetails?.id}</h1>
      </div>
      {orderDetails?.status === 'CANCELLED' && (
        <div className='flex'>
          <ul className='m-1 flex flex-wrap rounded-3xl bg-white'>
            <li className='m-0.5 flex'>
              <div className=' bg-client-statuschipbg inline-flex items-center justify-center rounded-full px-2 py-1'>
                <div className='bg-client-cancelled h-[22px] w-[22px] rounded-full'></div>
                <span className='go-text-medium-14 text-client-cancelled ml-2'>Cancelled</span>
              </div>
            </li>
          </ul>
        </div>
      )}
      {orderDetails?.status !== 'CANCELLED' && (
        <div className='flex'>
          <ul className='m-1 flex flex-wrap rounded-3xl bg-white '>
            <li className='m-0.5 mr-3 flex'>
              <div className=' bg-client-statuschipbg inline-flex items-center justify-center rounded-full px-2 py-1'>
                <div className='bg-client-waiting h-[22px] w-[22px] rounded-full'></div>
                <span className='go-text-medium-14 text-client-waiting ml-2'>Waiting</span>
              </div>
            </li>
            <li className='m-0.5 mr-3 flex'>
              <div className=' bg-client-statuschipbg inline-flex items-center justify-center rounded-full px-2 py-1'>
                <div
                  className={`+ h-[22px] w-[22px]  rounded-full ${
                    orderDetails?.status === 'PICKUP_COMPLETE' || orderDetails?.status === 'DELIVERED'
                      ? ' bg-client-pickupcomplete'
                      : ' bg-slate-300 '
                  }`}
                ></div>
                <span
                  className={`go-text-medium-14  ml-2 ${
                    orderDetails?.status === 'PICKUP_COMPLETE' || orderDetails?.status === 'DELIVERED'
                      ? ' text-client-pickupcomplete'
                      : ' text-slate-300'
                  }`}
                >
                  Pickup Complete
                </span>
              </div>
            </li>
            <li className='m-0.5 flex'>
              <div className=' bg-client-statuschipbg inline-flex items-center justify-center rounded-full px-2 py-1'>
                <div
                  className={`+ h-[22px] w-[22px]  rounded-full ${
                    orderDetails?.status === 'DELIVERED' ? ' bg-client-delivered' : ' bg-slate-300 '
                  }`}
                ></div>
                <span
                  className={`go-text-medium-14  ml-2 ${
                    orderDetails?.status === 'DELIVERED' ? ' text-client-delivered' : ' text-slate-300'
                  }`}
                >
                  Delivered
                </span>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
