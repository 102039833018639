import moment from 'moment';

const dateRangeGenerator = (date, type) => {
  switch (type) {
    case 'THIS_WEEK':
      return {
        startDate: moment(date).startOf('week').toDate(),
        endDate: moment(date).endOf('week').toDate(),
      };
    case 'LAST_WEEK':
      return {
        startDate: moment(date).subtract(1, 'week').startOf('week').toDate(),
        endDate: moment(date).subtract(1, 'week').endOf('week').toDate(),
      };
    case 'THIS_MONTH':
      return {
        startDate: moment(date).startOf('month').toDate(),
        endDate: moment(date).endOf('month').toDate(),
      };
    case 'LAST_MONTH':
      return {
        startDate: moment(date).subtract(1, 'month').startOf('month').toDate(),
        endDate: moment(date).subtract(1, 'month').endOf('month').toDate(),
      };
    case 'THIS_YEAR':
      return {
        startDate: moment(date).startOf('year').toDate(),
        endDate: moment(date).endOf('year').toDate(),
      };
    case 'LAST_YEAR':
      return {
        startDate: moment(date).subtract(1, 'year').startOf('year').toDate(),
        endDate: moment(date).subtract(1, 'year').endOf('year').toDate(),
      };
    default:
      return {
        startDate: moment(date).startOf('week').toDate(),
        endDate: moment(date).endOf('week').toDate(),
      };
  }
};

export default dateRangeGenerator;
