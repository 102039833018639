import axios from 'axios';
import { GET_LANDING_PAGE_DATA, GET_LANDING_PAGE_DATA_LOADING } from '../ActionsTypes';
import { getRequest } from '../../utils/axios';
import toast from 'react-hot-toast';
import { getLandingPageDataAction, setLandingPageLoadingDataAction } from '../Actions/LandingPageActions';
const initialState = {
  initialData: null,
  loading: false,
};
export const LandingPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_LANDING_PAGE_DATA:
      return { ...state, initialData: payload };
    case GET_LANDING_PAGE_DATA_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};

export const getLandingPageDataApi = () => async (dispatch) => {
  try {
    dispatch(setLandingPageLoadingDataAction(true));
    const response_data = await getRequest('/orders/client/dashboard');
    dispatch(getLandingPageDataAction(response_data?.data));
    dispatch(setLandingPageLoadingDataAction(false));
  } catch (error) {
    dispatch(setLandingPageLoadingDataAction(false));
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
