import React from 'react';

export default function GreenCheckMarkIcon({ onClick, classes }) {
  return (
    <div className={classes} onClick={onClick}>
      <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <g fill='#28C2B3' fillRule='nonzero'>
          <path d='M12.947 15.053a1.339 1.339 0 0 0-1.894 1.894l4 4c.252.25.593.388.947.386.369-.011.717-.176.96-.453l9.333-10.667a1.333 1.333 0 0 0-2-1.76L16 18.053l-3.053-3z' />
          <path d='M28 14.667c-.736 0-1.333.597-1.333 1.333 0 5.891-4.776 10.667-10.667 10.667A10.667 10.667 0 0 1 8.44 8.48 10.573 10.573 0 0 1 16 5.333a11.72 11.72 0 0 1 2.533.294 1.333 1.333 0 1 0 .627-2.587A14.053 14.053 0 0 0 16 2.667a13.333 13.333 0 0 0-9.333 22.786A13.24 13.24 0 0 0 16 29.333c7.364 0 13.333-5.97 13.333-13.333 0-.736-.597-1.333-1.333-1.333z' />
        </g>
      </svg>
    </div>
  );
}
