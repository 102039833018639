import React, { useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import PhoneNumbersList from '../../../components/PhoneNumbersList';
import { isEmpty } from 'lodash';

export default function Members({ data, edit, setEdit }) {
  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>Members</h5>

          <div className='flex'>
            <button className='go-btn-primary mr-2'>
              <EditIcon
                onClick={() => {
                  setEdit(!edit);
                }}
              />
            </button>
          </div>
        </header>
        <div>
          {data?.members?.map((member, index) => (
            <div className=' border-b border-gray-100 p-6' key={index}>
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Name</div>
                <div className='go-textblacknormal-16 font-medium'>
                  {member?.first_name ? `${member?.first_name} ${member?.last_name}` : '-'}
                </div>
              </div>
              <div className='mb-4 flex-row space-y-1.5'>
                <div className='go-label-16'>Email</div>
                <div className='go-textblacknormal-16 font-medium'>{member?.email ? `${member?.email}` : '-'}</div>
              </div>
              {!isEmpty(member?.phone_numbers) && (
                <div className='mb-4 flex-row space-y-1.5'>
                  {/* <div className='go-label-16'>Phone Numbers</div> */}
                  <PhoneNumbersList phoneNumbers={member?.phone_numbers && member?.phone_numbers} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
