import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownNotifications from '../components/DropdownNotifications';
import { useSelector } from 'react-redux';

function Header({ sidebarOpen, setSidebarOpen }) {
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state.profile);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const checkTitleName = (path) => {
    switch (path) {
      case '/':
        return 'Dashboard';
      case '/promotions':
        return 'Promotions';
      case '/dashboard':
        return 'Dashboard';
      case '/my-orders':
        return 'My Orders';
      case '/account':
        return 'My Account';
      // case '/messages':
      //   return 'Messages';
      case '/services-and-pricing':
        return 'Services and Pricing';
      case '/help':
        return 'Help';
      case '/privacy':
        return 'Privacy';
      case '/tos':
        return 'Terms and Conditions';
      case '/create-new-order':
        return '';
      default:
        return 'Dashboard';
    }
  };
  return (
    <header className=' top-0 z-30 border-b border-slate-200 bg-white md:border-none md:bg-transparent'>
      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='flex h-16 items-center justify-between'>
          <div className='md:hidden'>
            <button
              className='text-slate-500 hover:text-slate-600 lg:hidden'
              aria-controls='sidebar'
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className='sr-only'>Open sidebar</span>
              <svg className='h-6 w-6 fill-current' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                <rect x='4' y='5' width='16' height='2' />
                <rect x='4' y='11' width='16' height='2' />
                <rect x='4' y='17' width='16' height='2' />
              </svg>
            </button>
          </div>
          <div className='flex'>
            <h3 className='go-textblackbold-24 sm:pb-0'>{checkTitleName(pathname)}</h3>
          </div>
          <div className='flex items-center space-x-3'>
            {/* <DropdownNotifications align='right' /> */}
            <div className='relative inline-flex'>
              <button
                className='group inline-flex items-center justify-center'
                aria-haspopup='true'
                onClick={() => navigate('/account')}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='h-7 w-7  rounded-full bg-white p-1.5'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
                  />
                </svg>
                <div className='flex items-center truncate'>
                  <span className='ml-2 truncate text-sm font-medium text-black'>{profileData?.full_name}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
