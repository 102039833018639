import React, { useEffect, useState } from 'react';
import ModalWithHeading from './ModalWithHeading';
import ClientButton from './ClientButton';
import { updateOrderApi } from '../redux/Reducers/MyAccountReducer';
import { toast } from 'react-hot-toast';
import Loader from './Loader';
import { amountCheckFn } from '../utils/commonUtil';
export default function TipModal({ tipModal, setTipModal, orderSelected, triggerRefresh }) {
  const [loading, setLoading] = useState(false);
  const [tipData, setTipData] = useState(null);
  const [tipSelected, setTipSelected] = useState(2);
  const [tipAmount, setTipAmount] = useState(null);
  const [customTipError, setCustomTipError] = useState(false);
  const selectedTipFn = (id, val) => {
    setTipSelected(id);
    setTipAmount(val);
  };

  const onCustomTipChangeFn = (val) => {
    if (val < 1 || val > 500) {
      setCustomTipError(true);
      setTipAmount(null);
      return;
    }
    setCustomTipError(false);
    setTipAmount(val);
  };

  const onSaveTipClickFn = () => {
    setLoading(true);
    if (customTipError) return;
    const payload = {
      final_tip: +tipAmount,
    };
    const response = updateOrderApi(orderSelected?.id, payload);
    response?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setTipModal(false);
        setLoading(false);
        triggerRefresh();
        toast.success(res?.message || 'Tip added successfully');
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };

  useEffect(() => {
    const tipData = new Array(5).fill(0).map((item, idx) => {
      return {
        id: idx + 1,
        percent: `${idx * 5}%`,
        value: idx === 4 ? null : (orderSelected?.estimated_total * ((idx * 5) / 100)).toFixed(2),
      };
    });
    setTipData(tipData);
    setTipAmount(tipData[1]?.value);
  }, [orderSelected]);

  return (
    <ModalWithHeading
      modalOpen={tipModal}
      setModalOpen={setTipModal}
      title='Leave a tip for your service provider'
      classes='max-w-2xl'
    >
      <div className='mx-8'>
        {loading ? (
          <Loader text='processing...' classes='h-40' />
        ) : (
          <div>
            <div className='my-4 flex  justify-center'>
              <div className='bg-client-gobg2 flex w-96 rounded-xl'>
                {tipData?.map((item, idx) => {
                  if (idx === 0) return null;
                  return (
                    <div
                      key={idx}
                      className={
                        ' flex w-1/4 cursor-pointer flex-col items-center justify-center rounded-3xl border py-2 text-center' +
                        (tipSelected === item?.id ? '  border-black ' : ' border-client-gobg2')
                      }
                      onClick={() => selectedTipFn(item?.id, item?.value)}
                    >
                      {item?.value === null ? (
                        <span className='go-text-16'>Other</span>
                      ) : (
                        <React.Fragment>
                          <span className='go-text-16'>{item?.percent}</span>
                          <span className='go-label-14'>{amountCheckFn(item?.value)}</span>
                        </React.Fragment>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {tipSelected === 5 && (
              <div className='my-4 flex justify-center'>
                <div className=' w-96 flex-row '>
                  <label className='go-label-16 ' htmlFor='apartment'>
                    Enter tip amount:
                  </label>
                  <input
                    id='apartment'
                    className={`form-input go-inputtext-16 w-full ${customTipError ? 'border-red-500' : ''}`}
                    type='number'
                    defaultValue={tipAmount}
                    onChange={(e) => onCustomTipChangeFn(e.target.value)}
                  />
                  {customTipError && (
                    <p className='go-textnormal-14 text-red-500'>Minimum tip amount should be between 1$ - 500$. </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div className='flex justify-center py-5 '>
          <ClientButton
            variant='secondary'
            text='Cancel'
            classes=''
            onClick={() => {
              setTipSelected(2);
              setTipModal(false);
            }}
          />
          <ClientButton
            variant='primary'
            text='Save'
            classes=''
            disabled={tipAmount === null}
            onClick={() => {
              onSaveTipClickFn();
            }}
          />
        </div>
      </div>
    </ModalWithHeading>
  );
}
