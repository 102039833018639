import React, { useCallback, useState } from 'react';
import SingleOrder from './SingleOrder';
import { recentOrdersData } from '../../../mockdata/dashboard';
import Loader from '../../../components/Loader';
import { Link } from 'react-router-dom';
import NoData from '../../../components/NoData';
import TipModal from '../../../components/TipModal';
import { useDispatch } from 'react-redux';
import { getLandingPageDataApi } from '../../../redux/Reducers/LandingPageReducer';

export default function RecentOrders({ data, loading }) {
  const dispatch = useDispatch();
  const [tipModal, setTipModal] = useState(false);
  const [orderSelected, setOrderSelected] = useState(null);
  const tipModalFn = useCallback((e, val, order) => {
    e.stopPropagation();
    setTipModal(val);
    setOrderSelected(order);
  }, []);

  const fetchDashboardData = () => {
    getLandingPageDataApi()(dispatch);
  };
  return (
    <div className='grow p-2 px-5 md:px-8'>
      <header className='flex w-full justify-between border-b py-4'>
        <span className='go-text-semibold-18'>Recent Orders</span>
        <Link to='/my-orders' className='go-link-16 '>
          View All My Orders
        </Link>
      </header>
      {loading ? (
        <Loader classes='h-60' text='loading recent orders...' bg='true' />
      ) : data?.length == 0 ? (
        <NoData text='No recent orders' classes='h-60' />
      ) : (
        <div className='flex w-full'>
          <div className='grow py-2'>
            <div className='divide-y divide-slate-200 '>
              {data?.map((order) => {
                return <SingleOrder order={order} key={order?.id} tipModalOpen={tipModalFn} />;
              })}
            </div>
          </div>
        </div>
      )}
      <TipModal
        tipModal={tipModal}
        setTipModal={setTipModal}
        orderSelected={orderSelected}
        triggerRefresh={() => fetchDashboardData()}
      />
    </div>
  );
}
