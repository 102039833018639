import React from 'react';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import GreenCheckMarkIcon from '../../../components/Icons/GreenCheckMarkIcon';
import ClientButton from '../../../components/ClientButton';
import Loader from '../../../components/Loader';

export default function ModalToUpdateAddress({ modal, updateModal, newAddressID, addressess, cancelModal }) {
  const findServiceId = (id) => {
    const serviceAddress = addressess.find((service) => service.id === id);
    return serviceAddress?.full_address;
  };
  return (
    <div>
      {/* <ModalWithHeadingSmall
        modalOpen={modal}
        setModalOpen={updateModal}
        icon={<GreenCheckMarkIcon />}
        title={newAddressID?.type === 'update' ? 'Your Address has been updated!' : 'New Address has been added'}
        classes='max-w-2xl py-10'
      > */}
      <div className='mx-20 py-5'>
        <div className='flex-row space-y-5'>
          <div>
            {newAddressID?.id ? (
              <div>
                <span className='go-label-16'>New Address:</span>
                <p>{findServiceId(newAddressID?.id || 0)}</p>
              </div>
            ) : (
              <Loader />
            )}
          </div>
          <div className='flex-col justify-center pt-5 text-center '>
            <ClientButton
              variant='primary'
              text='Close'
              classes=''
              onClick={() => {
                cancelModal(false);
              }}
            />
          </div>
        </div>
      </div>
      {/* </ModalWithHeadingSmall> */}
    </div>
  );
}
