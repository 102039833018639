import {
  ORDER_FILTERS,
  ORDERS_LOADING,
  ORDERS_PAGINATION,
  GET_ORDER_PREFERENCES,
  PREFERENCES_LOADING,
} from '../ActionsTypes';

const setOrderFiltersAction = (data) => ({
  type: ORDER_FILTERS,
  payload: data,
});
const setMyOrdersLoadingAction = (data) => ({
  type: ORDERS_LOADING,
  payload: data,
});
const setMyOrdersPaginationAction = (data) => ({
  type: ORDERS_PAGINATION,
  payload: data,
});
const setGetOrderPreferencesAction = (data) => ({
  type: GET_ORDER_PREFERENCES,
  payload: data,
});
const setPreferencesLoadingAction = (data) => ({
  type: PREFERENCES_LOADING,
  payload: data,
});

export {
  setOrderFiltersAction,
  setMyOrdersLoadingAction,
  setMyOrdersPaginationAction,
  setGetOrderPreferencesAction,
  setPreferencesLoadingAction,
};
