import React from 'react';
import ModalWithHeading from '../../../components/ModalWithHeading';
import ClientButton from '../../../components/ClientButton';

export default function PaymentModal({ paymentModal, setPaymentModal, agreementModalOpen }) {
  return (
    <ModalWithHeading modalOpen={paymentModal} setModalOpen={setPaymentModal} title='Payment Error' classes='max-w-2xl'>
      <div className='mx-8'>
        <div className='flex px-12 py-5'>
          <p className='go-textblackmedium-18 text-center'>
            Your credit card ending 9567 has been declined by our payment processorr.
          </p>
        </div>
        <div className='flex'>
          <div className='m-2 rounded-lg border border-[#f166ad] py-5 px-10'>
            <div className='space-y-5 py-5'>
              <div className='flex space-x-4'>
                <div className='flex-1'>
                  <label className='go-label-16' htmlFor='card-nr'>
                    Card Number <span className='text-rose-500'>*</span>
                  </label>
                  <input
                    id='card-nr'
                    className='go-inputtext-16'
                    type='text'
                    placeholder='1234 1234 1234 1234'
                    defaultValue='4930 0934 1235 5678'
                  />
                </div>
                <div className='flex-1'>
                  <label className='go-label-16' htmlFor='card-name'>
                    Name on the Card <span className='text-rose-500'>*</span>
                  </label>
                  <input
                    id='card-name'
                    className='go-inputtext-16'
                    type='text'
                    placeholder=''
                    defaultValue='John Hughes'
                  />
                </div>
              </div>
              <div className='flex space-x-4'>
                <div className='flex-1'>
                  <label className='go-label-16' htmlFor='card-expiry'>
                    Expiration <span className='text-rose-500'>*</span>
                  </label>
                  <input
                    id='card-expiry'
                    className='go-inputtext-16'
                    type='text'
                    placeholder='MM/YY'
                    defaultValue='09/24'
                  />
                </div>
                <div className='flex-1'>
                  <label className='go-label-16' htmlFor='card-cvc'>
                    CVV <span className='text-rose-500'>*</span>
                  </label>
                  <input id='card-cvc' className='go-inputtext-16' type='text' placeholder='' defaultValue='458' />
                </div>
                <div className='flex-1'>
                  <label className='go-label-16' htmlFor='card-cvc'>
                    Zip Code <span className='text-rose-500'>*</span>
                  </label>
                  <input id='card-cvc' className='go-inputtext-16' type='text' placeholder='' defaultValue='85940' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-center py-5'>
          <ClientButton
            variant='primary'
            text='Save'
            classes=''
            onClick={(e) => {
              agreementModalOpen(e, true);
            }}
          />
        </div>
      </div>
    </ModalWithHeading>
  );
}
